<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->

      <button
        class="btn btn-small btn-primary"
        :disabled="showOptions.disableButton"
        @click="saveTopic"
      >
        Save Changes
      </button>
    </div>
    <!--END: Header Wrapper-->

    <!--START: Page Loader-->
    <div v-if="showOptions.preLoader" class="pre-loader-wrapper">
      <PreLoader :show="true"></PreLoader>
    </div>
    <!--START: Page Loader-->

    <div v-else ref="settings" class="settings-main" @scroll="settingsScroll">
      <div class="settings-column">
        <div class="card">
          <FormBuilder
            :fields="topicFields.intentFields"
            @fieldChanged="fieldChanged"
            class="intent-fields"
          ></FormBuilder>
          <FormBuilder
            :fields="fields"
            @fieldChanged="fieldChanged"
            class="intent-fields"
          ></FormBuilder>
        </div>

        <div class="card channels-wrapper">
          <!--START:  Fields Options-->
          <SettingsForm
            :fields="topicFields.channelFields.fields"
            :content="topicFields.channelFields.content"
            additionalClass="two-column-form"
            @fieldChanged="fieldChanged"
          ></SettingsForm>
          <!--END:  Fields Options-->
        </div>
      </div>
      <div class="answer-column">
        <div class="card">
          <FormBuilder
            :fields="topicFields.answerFields"
            @fieldChanged="fieldChanged"
            class="answer-fields"
          ></FormBuilder>
        </div>

        <div class="card">
          <FormBuilder
            :fields="topicFields.preventAnswerFields"
            @fieldChanged="fieldChanged"
            class="prevent-answer-fields"
          ></FormBuilder>
        </div>

        <!--START: Sources-->
        <div class="sources-wrapper card">
          <div class="field-title field-title-large">
            <label>Sources for answer creation</label>
          </div>
          <div class="sources-list">
            <div
              v-for="s in answerSources"
              :key="s.sourceId"
              class="source-item"
            >
              <FormBuilder
                :fields="s.field"
                @fieldChanged="fieldChanged"
              ></FormBuilder>
              <h3 class="name">{{ s.name }}</h3>
              <span class="source-type">
                {{ getSourceType(s.sourceType) }}
                <img :src="getLogoImage(s.sourceType)" alt="Logo"
              /></span>
            </div>
          </div>
        </div>
        <!--END: Sources-->
      </div>
    </div>

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
      @closeModal="closeModal"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader
      :show="showOptions.lineLoader"
      :class="{ bottom: !showOptions.modalActions }"
    ></LineLoader>
    <!--END: Loader-->
  </div>
</template>
                  
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import LineLoader from "@/components/loaders/LineLoader";
import NotificationMessage from "@/components/modals/NotificationMessage";
import FormBuilder from "@/components/form/FormBuilder";
import SettingsForm from "@/components/form/SettingsForm";
import PreLoader from "@/components/loaders/PreLoader";

// Importing Services
import { AiAgentServices } from "@/services";
const { TopicService } = AiAgentServices;

export default {
  name: "AddTopic",
  computed: {
    company() {
      return this.$store.getters.company;
    },

    sources() {
      return this.company.sources;
    },
  },
  data() {
    return {
      showOptions: {
        lineLoader: false,
        showShadow: false,
        preLoader: true,
        disableButton: true,
        headerShadow: false,
      },
      regularHeader: {
        title: "Add Topic",
        description: "Control the topics and intents the AI responds to",
      },
      fields: {
        isActive: {
          type: "toggle",
          title: "Activate this topic for tickets",
          additionalClass: "large-toggle",
          value: true,
        },
      },
      topicFields: {
        intentFields: {
          fields: {
            type: "group",
            nestFields: false,
            title: "Topic categorisation",
            fields: {
              title: {
                type: "text",
                title: "Name*",
                required: true,
                hasError: false,
                placeholder: "Add a topic name",
                additionalClass: "small-input",
                value: "",
              },
              description: {
                type: "textarea",
                placeholder:
                  "Short description for the AI to understand the topic",
                required: true,
                hasError: false,
                maxlength: 250,
                additionalClass: "small-input",
                title: "Description*",
                value: "",
                alertMessage: {
                  color: "yellow",
                  message:
                    "The AI will only answer if it detects that the ticket is categorized as this topic",
                },
              },
            },
          },
        },

        answerFields: {
          fields: {
            type: "group",
            nestFields: false,
            title: "Instructions on how to answer",
            fields: {
              answerInstructions: {
                type: "text-editor",
                placeholder: "How do you want the AI to answer",
                required: true,
                hasError: false,
                title: "Answer instructions*",
                value: "",
                alertMessage: {
                  color: "yellow",
                  message:
                    "The AI will create an answer based on these instructions along with any knowledge sources specified",
                },
              },
              responseType: {
                type: "dropdown",
                placeholder: "AI response type",
                title: "AI agent response*",
                required: true,
                hasError: false,
                fields: [
                  { name: "Auto reply", value: "auto-reply" },
                  { name: "Create draft", value: "draft" },
                ],
                value: [],
              },
            },
          },
        },
        preventAnswerFields: {
          fields: {
            type: "group",
            nestFields: false,
            title: "Rules when the AI should not reply",
            fields: {
              preventAnswerInstructions: {
                type: "text-editor",
                placeholder:
                  "Specify the situations when you don't want the AI to send a reply to a ticket",
                required: false,
                hasError: false,
                title: "Prevent answer instructions*",
                value: "",
                alertMessage: {
                  color: "yellow",
                  message:
                    "The AI will refrain from sending out a reply based on these rules",
                },
              },
            },
          },
        },
        channelFields: {
          content: {
            title: "Active channels",
            description: "Select the active channels for this topic",
          },
          fields: {},
        },
      },
      channels: [
        { channelType: "email", title: "Email" },
        // { channelType: "instagram_dm", title: "Instagram DM" },
        { channelType: "mobile_sdk", title: "Mobile SDK" },
      ],
      activeChannel: {
        type: "group",
        nestFields: true,
        title: "",
        fields: {
          isActive: {
            type: "toggle",
            title: "Activate channel",
            additionalClass: "large-toggle",
            hasError: false,
            value: false,
          },
          responseDelay: {
            type: "number",
            title: "Delay in minutes",
            required: true,
            hasError: false,
            show: false,
            value: 5,
          },
        },
      },
      answerSources: [],
      sourceToggleField: {
        type: "toggle",
        value: false,
      },
      status: {
        show: false,
        status: "success",
        title: "Topic saved",
      },
      topic: {},
      initData: {},
      dataChanged: false,
      saveInProgress: false,
    };
  },
  components: {
    RegularHeader,
    LineLoader,
    FormBuilder,
    SettingsForm,
    NotificationMessage,
    PreLoader,
  },
  async created() {
    this.initAnswerSources();
    await this.getTopic();
  },

  methods: {
    // Get the document
    async getTopic() {
      this.topicId = this.$route.params.topicId;
      if (this.topicId !== "") {
        const data = { topicId: this.topicId };
        const response = await TopicService.GetTopic(data);

        // Check for errors
        if (!response.hasError) {
          this.topic = response.data;
        } else this.showErrorMessage(this.status, response.message);
      }

      this.populateFields();
      this.showOptions.preLoader = false;
    },

    // Initialise the answer sources
    initAnswerSources() {
      this.sources.forEach((s) => {
        this.answerSources.push({
          sourceId: s._id,
          name: s.name,
          sourceType: s.sourceType,
          isActive: s.isActive,
          field: { isActive: _.cloneDeep(this.sourceToggleField) },
        });
      });

      this.answerSources.push({
        sourceId: this.company._id,
        name: "Articles",
        sourceType: "macha-articles",
        selected: false,
        isActive: false,
        field: { isActive: _.cloneDeep(this.sourceToggleField) },
      });
    },

    // Save topic
    async saveTopic() {
      if (!this.saveInProgress) {
        this.saveInProgress = true;
        this.showOptions.lineLoader = true;

        // Check if the form has valid input
        const data = {
          ...this.fields,
          intentFields: this.topicFields.intentFields.fields,
          answerFields: this.topicFields.answerFields.fields,
          preventAnswerFields: this.topicFields.preventAnswerFields.fields,
          ...this.topicFields.channelFields.fields,
        };
        var isFormValid = this.validateForm(data);
        if (isFormValid) {
          const payload = this.constructPayload(this.parseData(data));

          let service = "SaveTopic";
          if (payload.topicId) service = "UpdateTopic";
          const response = await TopicService[service](payload);

          // Check for errors
          if (!response.hasError) {
            this.topic = response.data;

            this.populateFields();
            this.showStatusMessage(this.status, 2500);
            this.$emit("topicAdded", response.data);
          } else this.showErrorMessage(this.status, response.message);
        }

        this.showOptions.lineLoader = false;
        this.saveInProgress = false;
      }
    },

    // Populate the fields
    populateFields() {
      if (!_.isEmpty(this.topic)) {
        this.fields.isActive.value = this.topic?.isActive;
        const { title, description } =
          this.topicFields.intentFields.fields.fields;
        const { answerInstructions, responseType } =
          this.topicFields.answerFields.fields.fields;
        const { preventAnswerInstructions } =
          this.topicFields.preventAnswerFields.fields.fields;

        this.$parent.topicId = this.topic._id;
        title.value = this.topic.title;
        this.regularHeader.title = `<em>Topics</em> <i>&#8250;</i> ${this.topic.title}`;
        description.value = this.topic.description;
        answerInstructions.value = this.topic.answerInstructions;
        preventAnswerInstructions.value = this.topic.preventAnswerInstructions;

        // Set the response type
        const selectedResponseType = responseType.fields.find(
          (f) => f.value === this.topic.responseType
        );
        if (selectedResponseType) {
          responseType.value = [
            selectedResponseType.name,
            this.topic.responseType,
          ];
        }

        // Set the active channels
        this.channels.forEach((c) => {
          const topicChannel = this.topic.activeChannels.find(
            (ch) => ch.channelType === c.channelType
          );

          const channel = _.cloneDeep(this.activeChannel);
          channel.title = c.title;

          if (topicChannel) {
            channel.fields.isActive.value = true;
            channel.fields.isActive.show = true;
            channel.fields.responseDelay.value = topicChannel.responseDelay;
          }

          this.topicFields.channelFields.fields = {
            ...this.topicFields.channelFields.fields,
            [c.channelType]: channel,
          };
        });

        // Set the answer sources
        this.topic.answerSources.forEach((source) => {
          const selectedSource = this.answerSources.find(
            (s) => s.sourceId === source
          );
          if (selectedSource) selectedSource.field.isActive.value = true;
        });
      }

      this.initFormData();
    },

    // Get source name
    getSourceType(sourceType) {
      let source = null;
      switch (sourceType) {
        case "shopify-products":
          source = "Shopify Products";
          break;
        case "zendesk-hc":
        case "zendesk-hc-api":
          source = "Zendesk Helpcenter";
          break;
        case "zendesk-macro":
          source = "Zendesk Macros";
          break;
        case "zendesk-tickets":
          source = "Zendesk Tickets";
          break;
        case "external-website":
          source = "External Website";
          break;
        case "notion":
          source = "Notion";
          break;
        case "macha-articles":
          source = "Macha Articles";
          break;
      }

      return source;
    },

    // Get logo of source type
    getLogoImage(sourceType) {
      let icon = null;
      switch (sourceType) {
        case "shopify-products":
          icon = "shopify.jpg";
          break;
        case "zendesk-hc":
        case "zendesk-macro":
        case "zendesk-hc-api":
        case "zendesk-tickets":
          icon = "zendesk.jpg";
          break;
        case "external-website":
          icon = "external-website.jpg";
          break;
        case "notion":
          icon = "notion.jpg";
          break;
        case "macha-articles":
          icon = "macha.jpg";
          break;
      }

      let iconURL = (this.imageSrc = `/assets/images/logos/${icon}`);
      return iconURL;
    },

    // Initialise the form data for comparisons
    initFormData() {
      const data = {
        ...this.fields,
        ...this.topicFields,
        ...this.answerSources,
      };

      this.disableSaveButton(true);
      this.initData = _.cloneDeep(data);
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Check if channels are active
    checkChannels() {
      for (const c in this.topicFields.channelFields.fields) {
        const channel = this.topicFields.channelFields.fields[c];
        if (channel.fields)
          channel.fields.responseDelay.show = _.cloneDeep(
            channel.fields.isActive.value
          );
      }
    },

    // Update persona when fields change
    fieldChanged() {
      this.checkChannels();
      const data = {
        ...this.fields,
        ...this.topicFields,
        ...this.answerSources,
      };
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Construct the payload for the controller
    constructPayload(data) {
      const { answerInstructions } =
        this.topicFields.answerFields.fields.fields;
      const { preventAnswerInstructions } =
        this.topicFields.preventAnswerFields.fields.fields;

      // Get the active channels
      const activeChannels = [];
      for (const c in this.topicFields.channelFields.fields) {
        const channel = this.topicFields.channelFields.fields[c];

        if (channel.fields.isActive.value) {
          activeChannels.push({
            channelType: c,
            responseDelay: parseInt(channel.fields.responseDelay.value),
          });
        }
      }

      const payload = {
        topicId: this.topicId ? this.topicId : this.topic?._id,
        ...data,
        answerInstructions: answerInstructions.value,
        preventAnswerInstructions: preventAnswerInstructions.value,
        activeChannels,
        answerSources: [],
      };

      this.answerSources.forEach((s) => {
        if (s.field.isActive.value) payload.answerSources.push(s.sourceId);
      });

      return payload;
    },

    // Event on chat scroll
    contentScroll() {
      if (this.$refs.documentContent.scrollTop > 0)
        this.showOptions.showShadow = true;
      else this.showOptions.showShadow = false;
    },

    // Reset booking modal
    resetModal() {
      this.showOptions.preLoader = true;
      this.showOptions.lineLoader = false;
      this.showOptions.showShadow = false;
      this.topic = {};
    },

    // Event on settings scroll
    settingsScroll() {
      const scrollTop = this.$refs.settings.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Hide modal
    closeModal() {
      this.showOptions.lineLoader = false;
      this.$emit("closeModal");
    },
  },
};
</script>
                  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.header-wrapper {
  padding-bottom: 1.5rem;
  margin-bottom: 0 !important;
  border-bottom: 1px solid darken($rowBorderColor, 2.5%);
  position: relative;
  z-index: 2;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;

    /deep/ {
      em {
        font-size: 1rem;
        font-weight: 500;
        opacity: 0.75;
      }

      i {
        font-style: normal;
        opacity: 0.5;
        margin: 0 0.25rem;
      }
    }
  }
}

/deep/ .status-message {
  position: relative;
  bottom: auto;
  right: 0;
  left: 0;
  border-radius: 0;
  text-align: center;
}

/deep/ .line-loader {
  position: relative;
  bottom: auto;
  right: 0;
  left: 0;
}

.title-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 2rem;
  z-index: 5;

  &.show-shadow {
    box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
  }

  .title-info {
    flex: 1;
    .title {
      font-size: $mediumFontSize;
      text-transform: capitalize;
      margin-bottom: 0.15rem;
    }

    p {
      color: $paragraphColor;
      font-size: $smallFontSize;
    }
  }
}

.settings-main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2rem 1rem 5rem;
  overflow-y: scroll;

  &.show-shadow {
    box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
  }

  .answer-column {
    width: 75%;
    margin-left: 1.5rem;
    margin-top: 0;
    flex: 1;

    .card:first-child {
      margin-top: 0;
    }

    /deep/ {
      .field-group-wrapper {
        display: block;

        &:not(.field-group-expand) .field-main-item {
          margin-bottom: 1.5rem;
        }
      }

      .text-editor .ql-container.ql-snow {
        height: 25rem;
      }

      .prevent-answer-fields {
        .text-editor .ql-container.ql-snow {
          height: 15rem;
        }
      }

      .prevent-answer-fields,
      .sources-wrapper,
      .answer-fields {
        .field-title-large {
          margin-top: 0;
        }
      }
    }
  }

  .settings-column {
    width: calc(35% - 1.5rem);

    .card:first-child {
      margin-top: 0;
    }

    /deep/ {
      .field-group-wrapper {
        display: block;

        &:not(.field-group-expand) .field-main-item {
          margin-bottom: 1.5rem;
        }
      }

      .intent-fields {
        .field-title-large {
          margin-top: 0;
        }
      }
    }
  }
}

.attributes-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  /deep/ {
    .field-main-item {
      margin-bottom: 0;
    }
  }

  .btn {
    margin-left: 1.5rem;
  }
}

.pre-loader-wrapper {
  margin: 2rem 3rem;
}

.source-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;

  .source-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: capitalize;
    font-size: $smallerFontSize;
    color: $greyColor;

    img {
      width: 1.25rem;
      border-radius: 0.25rem;
      margin-left: 0.5rem;
    }
  }

  .name {
    font-size: $normalFontSize;
    font-weight: $mediumFontWeight;
    margin-left: 1rem;
    margin-bottom: 0;
    flex: 1;
  }

  /deep/ {
    .field-main-item {
      margin-bottom: 0;
    }
  }
}

.channels-wrapper {
  /deep/ .settings-form {
    border: none;
    padding: 0;
    margin-bottom: 0;

    .field-main-item {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .field-wrapper.type-number {
      width: 5rem;
      min-width: auto;

      input {
        text-align: left;
      }
    }
  }
}
</style>