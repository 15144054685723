<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button
        class="btn btn-small btn-primary"
        :disabled="showOptions.disableButton || showOptions.saveInProgress"
        @click="updateAiAssistant"
      >
        Save
      </button>
    </div>
    <!--END: Header Wrapper-->

    <!--START: Loader-->
    <PageLoader
      v-if="lodash.isEmpty(aiAssistant)"
      :showLoader="true"
    ></PageLoader>
    <!--END: Loader-->

    <!--START: Wrapper-->
    <div ref="content" v-else class="content-block" @scroll="contentScroll">
      <FormBuilder
        class="settings-form card"
        :fields="fields"
        @fieldChanged="fieldChanged"
      ></FormBuilder>
    </div>
    <!--END: Wrapper-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
                
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";
import FormBuilder from "@/components/form/FormBuilder";

// Importing Services
import "external-svg-loader";
import { AiAssistantServices } from "@/services";
const { AiAssistantService } = AiAssistantServices;

export default {
  name: "AiAssistantZendeskChannels",
  data() {
    return {
      lodash: _,
      showOptions: {
        headerShadow: false,
        lineLoader: false,
        disableButton: true,
      },
      regularHeader: {
        title: "Zendesk Channels",
        description:
          "Determine the channels and response format that the AI should work on",
      },
      fields: {
        mail: {
          type: "group",
          title: "E-mail",
          nestFields: true,
          fields: {
            isActive: {
              type: "toggle",
              title: "Activate channel",
              additionalClass: "large-toggle",
              value: false,
            },
            responseFormat: {
              type: "dropdown",
              placeholder: "Select response type",
              title: "Response format for answers",
              required: true,
              hasError: false,
              show: false,
              fields: [
                { name: "Email", value: "email" },
                { name: "Chat", value: "chat" },
              ],
              value: [],
            },
          },
        },
        web: {
          type: "group",
          title: "Web",
          nestFields: true,
          fields: {
            isActive: {
              type: "toggle",
              title: "Activate channel",
              additionalClass: "large-toggle",
              value: false,
            },
            responseFormat: {
              type: "dropdown",
              placeholder: "Select response type",
              title: "Response format for answers",
              required: true,
              hasError: false,
              show: false,
              fields: [
                { name: "Email", value: "email" },
                { name: "Chat", value: "chat" },
              ],
              value: [],
            },
          },
        },
        chat: {
          type: "group",
          title: "Chat",
          nestFields: true,
          fields: {
            isActive: {
              type: "toggle",
              title: "Activate channel",
              additionalClass: "large-toggle",
              value: false,
            },
            responseFormat: {
              type: "dropdown",
              placeholder: "Select response type",
              title: "Response format for answers",
              required: true,
              hasError: false,
              show: false,
              fields: [
                { name: "Email", value: "email" },
                { name: "Chat", value: "chat" },
              ],
              value: [],
            },
          },
        },
        api: {
          type: "group",
          title: "API",
          nestFields: true,
          fields: {
            isActive: {
              type: "toggle",
              title: "Activate channel",
              additionalClass: "large-toggle",
              value: false,
            },
            responseFormat: {
              type: "dropdown",
              placeholder: "Select response type",
              title: "Response format for answers",
              required: true,
              hasError: false,
              show: false,
              fields: [
                { name: "Email", value: "email" },
                { name: "Chat", value: "chat" },
              ],
              value: [],
            },
          },
        },
        mobile_sdk: {
          type: "group",
          title: "Mobile SDK",
          nestFields: true,
          fields: {
            isActive: {
              type: "toggle",
              title: "Activate channel",
              additionalClass: "large-toggle",
              value: false,
            },
            responseFormat: {
              type: "dropdown",
              placeholder: "Select response type",
              title: "Response format for answers",
              required: true,
              hasError: false,
              show: false,
              fields: [
                { name: "Email", value: "email" },
                { name: "Chat", value: "chat" },
              ],
              value: [],
            },
          },
        },
        instagram_dm: {
          type: "group",
          title: "Instagram DM",
          nestFields: true,
          fields: {
            isActive: {
              type: "toggle",
              title: "Activate channel",
              additionalClass: "large-toggle",
              value: false,
            },
            responseFormat: {
              type: "dropdown",
              placeholder: "Select response type",
              title: "Response format for answers",
              required: true,
              hasError: false,
              show: false,
              fields: [
                { name: "Email", value: "email" },
                { name: "Chat", value: "chat" },
              ],
              value: [],
            },
          },
        },
      },
      aiAssistant: {},
      initData: {},
      dataChanged: false,
      status: {
        show: false,
        status: "success",
        title: "Zendesk channels updated",
      },
    };
  },
  props: {},
  watch: {},
  components: {
    RegularHeader,
    NotificationMessage,
    LineLoader,
    PageLoader,
    FormBuilder,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  async created() {
    await this.getAiAssistant();
  },
  methods: {
    // Get AI assistant
    async getAiAssistant() {
      const response = await AiAssistantService.GetAiAssistant();
      if (!response.error) {
        this.aiAssistant = response.data;
        this.populateFields();
      }
    },

    // Update the AI assistant signature
    async updateAiAssistant() {
      this.showOptions.lineLoader = true;
      const data = { ...this.fields };
      var isFormValid = this.validateForm(data);

      if (isFormValid) {
        const payload = this.constructPayload();
        const response = await AiAssistantService.UpdateAiAssistant(payload);
        this.aiAssistant = response.data;
        this.populateFields();

        if (!response.hasError) {
          this.showStatusMessage(this.status, 2500, response.message);
        }
      }

      this.showOptions.lineLoader = false;
    },

    // Populate the fields
    populateFields() {
      if (!_.isEmpty(this.aiAssistant)) {
        this.aiAssistant.zendeskSettings?.channels?.forEach((c) => {
          this.fields[c.name].fields.isActive.value = c.isActive;
          this.fields[c.name].fields.responseFormat.show = c.isActive;
          this.fields[c.name].fields.responseFormat.value = [
            c.responseFormat,
            c.responseFormat,
          ];
        });
      }

      this.initFormData();
    },

    // Construct the payload
    constructPayload() {
      const data = this.parseData(this.fields);
      const channels = [];
      for (const [key, channel] of Object.entries(data)) {
        channels.push({
          name: key,
          ...channel,
        });
      }
      const payload = { zendeskSettings: { channels } };
      return payload;
    },

    // Initialise the form data for comparisons
    initFormData() {
      const data = {
        ...this.fields,
      };

      this.disableSaveButton(true);
      this.initData = _.cloneDeep(data);
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Check visibility for channels
    checkChannelVisibility() {
      for (const [key, channel] of Object.entries(this.fields)) {
        console.log({ key });
        channel.fields.responseFormat.show = channel.fields.isActive.value;
      }
    },

    // Update rule when fields change
    fieldChanged() {
      this.checkChannelVisibility();
      const data = {
        ...this.fields,
      };
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Event on sourcesScroll scroll
    contentScroll() {
      const scrollTop = this.$refs.content.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },
  },
};
</script>
                
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 1.5rem;
  margin-bottom: 0 !important;
  border-bottom: 1px solid darken($rowBorderColor, 2.5%);
  position: relative;
  z-index: 2;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.zero-state {
  margin-top: 2.5rem;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}

.content-block {
  overflow-y: scroll;
  padding: 1.5rem 1rem;
}
</style>