<template>
  <div
    v-if="chargebeeSubscription.subscription_items?.length > 0"
    class="plan-wrapper"
  >
    <div class="card-wrapper">
      <!--START: Info wrapper-->
      <div class="info-wrapper">
        <h3 class="section-title">Current Plan</h3>
        <p>Here are the details and usage of your current plan</p>
        <button class="btn btn-small btn-border" @click="manageSubscription">
          Manage subscription
        </button>
      </div>
      <!--END: Info wrapper-->

      <!--START: Active plan-->
      <div class="active-plan">
        <div class="plan-details">
          <div class="plan-info">
            <em class="plan-name"
              >Starter Plan<span :class="subscription.status">{{
                subscription.status === "active" ? "Active" : "Cancelled"
              }}</span></em
            >
            <p>{{ answerLimit }} answers for all your agents on Zendesk</p>
          </div>
          <div class="plan-pricing">
            <div class="plan-price">
              <span class="plan-currency">{{ planCurrency }}</span>
              <span class="plan-amount">{{ planTotalAmount }}</span>
              <span class="plan-duration">{{ planDuration }}</span>
            </div>
            <span class="answer-limit">{{ answerLimit }} answers monthly</span>
          </div>
        </div>

        <div class="usage-wrapper">
          <div class="usage-details">
            <div class="term-details">
              <span>{{
                moment
                  .unix(chargebeeSubscription.current_term_start)
                  .format("D MMMM YYYY")
              }}</span>
              to
              <span>{{
                moment
                  .unix(chargebeeSubscription.current_term_end)
                  .format("D MMMM YYYY")
              }}</span>
            </div>
            <div class="answers-count">
              <span>{{ subscription.usage.generatedAnswers }}</span> of
              {{ answerLimit }} answers generated
            </div>
          </div>
          <div class="usage-bar">
            <span
              class="current-usage"
              :style="currentUsageStyle"
              :class="usageClass"
            ></span>
          </div>
        </div>
      </div>
      <!--END: Active plan-->
    </div>

    <!--START: Available Plans-->
    <Plans @subscribePlan="subscribePlan" :enableUpgrade="true"></Plans>
    <!--START: Available PLans-->
  </div>
  <!--START: Loader-->
  <PageLoader v-else :showLoader="true"></PageLoader>
  <!--END: Loader-->
</template>
    
<script>
// Import components
import Plans from "./Plans.vue";
import PageLoader from "@/components/loaders/PageLoader";

export default {
  name: "ActivePlan",
  props: {
    subscription: Object,
    chargebeeSubscription: Object,
  },
  data() {
    return {};
  },
  components: { Plans, PageLoader },
  computed: {
    // Answer limit
    answerLimit() {
      const planLimit = this.subscription.answerLimit;
      return planLimit === -1 ? 250 : planLimit;
    },

    // Get the plan currency
    planCurrency() {
      return this.getCurrency(this.chargebeeSubscription.currency_code);
    },

    // Total billing amount
    planTotalAmount() {
      const subscription = this.chargebeeSubscription.subscription_items[0];
      return subscription.amount / 100;
    },

    // Get the plan frequency
    planDuration() {
      const frequency = this.chargebeeSubscription.billing_period_unit;
      return `/ ${frequency}`;
    },

    // Send the current usage style
    currentUsageStyle() {
      return `width: ${
        (this.subscription.usage.generatedAnswers / this.answerLimit) * 100
      }%`;
    },

    // Determine the class based on the usage
    usageClass() {
      const currentUsage =
        (this.subscription.usage.generatedAnswers / this.answerLimit) * 100;

      if (currentUsage < 50) return "low";
      else if (currentUsage > 60 && currentUsage < 85) return "medium";
      else if (currentUsage < 100) return "high";

      return "exceeded";
    },
  },
  created() {},
  methods: {
    // Subscribe to a plan
    subscribePlan(planId) {
      this.$emit("subscribePlan", planId, "upgrade");
    },

    // Manage subscription
    manageSubscription() {
      this.$emit("manageSubscription");
    },
  },
};
</script>
    
<style scoped lang="scss">
.card-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 3rem;

  .info-wrapper {
    flex: 1;
  }
}

.info-wrapper {
  margin-bottom: 2rem;
  width: 35%;
  margin-right: 5rem;

  h3 {
    font-family: $titleFont;
    font-size: $largerFontSize;
    font-weight: $boldFontWeight;
  }

  p {
    font-size: $smallFontSize;
    opacity: $lightOpacity;
  }

  .btn {
    margin-top: 1rem;
  }
}

.active-plan {
  border: 1px solid $inputBorderColor;
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  width: 35rem;

  .details-title {
    display: block;
    color: $greyColor;
    font-size: $smallFontSize;
    margin-bottom: 0.5rem;
  }

  .plan-details {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .plan-info {
      p {
        font-size: $smallFontSize;
        margin-top: 0.5rem;
      }
    }

    .plan-name {
      font-size: $largerFontSize;
      font-weight: 600;

      span {
        display: inline-block;
        font-size: $smallestFontSize;
        background-color: $emeraldColor;
        color: lighten($emeraldColor, 50%);
        border: 1px solid lighten($emeraldColor, 20%);
        border-radius: 1.5rem;
        font-weight: $normalFontWeight;
        padding: 0.05rem 0.5rem;
        margin-left: 0.5rem;
        transform: translateY(-3px);

        &.cancelled {
          background-color: $redColor;
          border: 1px solid lighten($redColor, 20%);
          color: $whiteColor;
        }
      }
    }

    .answer-limit {
      margin-left: 1rem;
      font-size: $normalFontSize;
      font-weight: 600;
      color: $darkBlackColor;
      opacity: 0.65;
    }

    &.cancelled-plan {
      .plan-name {
        display: block;
        color: $redColor;
        margin: 1rem 0 0.5rem;
      }

      .answer-limit {
        margin-left: 0;
      }
    }
  }

  .usage-wrapper {
    margin-top: 1.5rem;

    .usage-details {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: $smallerFontSize;
      color: lighten($greyColor, 10%);
      margin-bottom: 0.5rem;

      .term-details {
        flex: 1;
      }

      .answers-count {
        text-align: right;
      }

      span {
        color: $greyColor;
        font-weight: $boldFontWeight;
        font-size: $normalFontSize;
      }

      .term-details {
        flex: 1;

        span {
          font-size: $smallerFontSize;
          font-weight: 600;
        }
      }
    }

    .usage-bar {
      position: relative;
      overflow: hidden;
      background: #f2f0f9;
      height: 1rem;
      width: 100%;
      border-radius: 0.5rem;

      .current-usage {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;

        &.low {
          background-color: $purpleColor;
        }

        &.medium {
          background-color: $emeraldColor;
        }

        &.high {
          background-color: $orangeColor;
        }

        &.exceeded {
          background-color: $redColor;
        }
      }
    }
  }
}

.plan-pricing {
  text-align: right;
  margin-left: 3rem;
  margin-top: -0.5rem;
  width: 50%;
}

.plan-currency {
  font-weight: 600;
  color: $purpleColor;
  font-size: $largerFontSize;
}

.plan-amount {
  font-weight: 600;
  color: $purpleColor;
  font-size: $extraLargeFontSize;
}

.plan-duration {
  font-weight: $mediumFontWeight;
  color: $darkBlackColor;
  margin-left: 0.25rem;
}
</style>