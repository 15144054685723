<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button class="btn btn-small btn-grey" @click="addTopic()">
        Add Topic
      </button>
    </div>
    <!--END: Header Wrapper-->

    <!--START: Zero State -->
    <ZeroState
      v-if="topics.length == 0 && showOptions.initTopics"
      class="zero-state"
      :imageURL="zeroStateData.imageURL"
      :title="zeroStateData.title"
      :message="zeroStateData.message"
      :buttonTitle="zeroStateData.buttonTitle"
      @eventHandler="addTopic()"
    ></ZeroState>
    <!--END: Zero State -->

    <!--START: Topics Table-->
    <TopicsTable
      v-else
      :topics="topics"
      @showTopicIntents="showTopicIntents"
      @deleteTopic="deleteTopic"
    ></TopicsTable>
    <!--END: Topics Table-->

    <!--START: Loader-->
    <PageLoader v-if="!showOptions.initTopics" :showLoader="true"></PageLoader>
    <!--END: Loader-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
        
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import TopicsTable from "@/components/dashboard/tables/TopicsTable";
import ZeroState from "@/components/misc/ZeroState";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";

// Importing Services
import { AiAgentServices } from "@/services";
const { TopicService } = AiAgentServices;

export default {
  name: "KnowledgeBaseSources",
  data() {
    return {
      showOptions: {
        initTopics: false,
        lineLoader: false,
      },
      regularHeader: {
        title: "Topics",
        description: "Topics allow you to set rules for the AI to answer",
      },
      zeroStateData: {
        imageURL: "services.jpg",
        title: "Add Topics",
        message: "Add topics to create rules that will help the AI answer",
        buttonTitle: "Add Topic",
      },
      status: {
        show: false,
        status: "success",
        title: "Topic deleted",
      },
      topics: [],
      selectedTopicId: "",
    };
  },
  props: {},
  watch: {},
  components: {
    RegularHeader,
    TopicsTable,
    ZeroState,
    NotificationMessage,
    LineLoader,
    PageLoader,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },

    hasCompanyLoaded() {
      return !_.isEmpty(this.company);
    },
  },
  async created() {
    await this.getTopics();
    this.showOptions.initTopics = true;
  },
  methods: {
    async getTopics() {
      const response = await TopicService.GetTopics();
      if (!response.hasError) this.topics = response.data;
    },

    addTopic() {
      this.$router.push({ path: "/ai-agent/topics/view" });
    },

    topicAdded(topic) {
      let tempTopics = _.cloneDeep(this.topics);
      const index = tempTopics.findIndex((i) => i._id === topic._id);

      if (index !== -1) tempTopics[index] = topic;
      else tempTopics.push(topic);

      this.topics.splice(0);
      this.topics = _.cloneDeep(tempTopics);
    },

    showTopicIntents(topicId) {
      this.$router.push({
        path: `/ai-agent/topics/intents/${topicId}`,
      });
    },

    async deleteTopic(topicId) {
      this.showOptions.lineLoader = true;

      const data = { topicId: topicId };
      await TopicService.DeleteTopic(data);

      // Remove from the table
      const index = this.topics.findIndex((s) => s._id == topicId);
      this.topics.splice(index, 1);

      this.status.title = "Topic deleted";
      this.showStatusMessage(this.status, 2500);
      this.showOptions.lineLoader = false;
    },
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
</style>