<template>
  <div class="all-documents-table">
    <!--START: Documents List-->
    <ul class="grid-table">
      <li
        class="grid-row grid-header"
        :class="{ 'grid-header-shadow': showOptions.headerShadow }"
      >
        <span class="grid-column">Title</span>
        <span class="grid-column"></span>
        <span class="grid-column">Status</span>
        <span class="grid-column">Last Updated</span>
      </li>
    </ul>
    <ul
      class="grid-table grid-item-clickable grid-item-narrow"
      ref="table"
      @scroll="tableScroll"
    >
      <li
        v-for="d in documents"
        :key="d.id"
        class="grid-row"
        @click="gotoItem(d.reference_id, d.has_content)"
      >
        <div class="grid-column">
          <img
            v-if="d.image"
            :src="d.image"
            :alt="d.title"
            class="thumbnail"
            @error="brokenImage"
          />
          <unicon
            v-else
            name="tag-alt"
            class="thumbnail collection-thumbnail"
          ></unicon>
        </div>
        <div class="grid-column">
          <h3 class="title" :class="{ 'no-title': d.title == null }">
            {{ d.title ? d.title : "No title" }}
          </h3>
        </div>
        <div class="grid-column">
          <span
            class="status"
            :class="[
              { inactive: !d.is_active },
              { 'in-queue': !d.has_content },
            ]"
            >{{
              !d.has_content
                ? "Waiting for upload"
                : d.is_active
                ? "Active"
                : "Inactive"
            }}</span
          >
        </div>
        <div class="grid-column">
          <span class="last-sync">{{ getUpdatedDate(d.updated_at) }}</span>
        </div>
        <div v-if="d.has_content" class="grid-column grid-actions">
          <button
            v-if="d.source_type == 'macha-article'"
            class="btn btn-icon btn-delete"
            @click.stop="showDeleteModal(d.reference_id, d.title)"
          >
            <unicon name="trash-alt"></unicon>
          </button>
          <button class="btn btn-icon">
            <unicon name="angle-right-b"></unicon>
          </button>
        </div>
      </li>
    </ul>
    <!--END: Documents List-->

    <!--START: Line Loader-->
    <LineLoader :show="showInfinteSroll"></LineLoader>
    <!--END: Line Loader-->

    <!--START: Documents Count-->
    <span class="grid-count"
      >Showing {{ documents.length }}
      {{ documents.length == 1 ? "document" : "documents" }}</span
    >
    <!--END: Documents Count-->

    <!--START: Confirmation Modal-->
    <ConfirmationModal
      :fields="confirmationFields"
      @primaryEvent="deleteItem"
      @secondaryEvent="closeModal"
    ></ConfirmationModal>

    <div
      class="bg-overlay light"
      :class="{ show: confirmationFields.show }"
      @click="closeModal"
    ></div>
    <!--END: Confirmation Modal-->
  </div>
</template>
  
<script>
// Imoprt libraries
import _ from "lodash";

// Importing Components
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import LineLoader from "@/components/loaders/LineLoader";

// Import functions
import { getUpdatedDate } from "@/utils/ticket.utils";

export default {
  name: "DocumentsTable",
  props: {
    documents: Array,
    showInfinteSroll: { type: Boolean, default: false },
    totalCount: { type: Number, required: false },
  },
  data() {
    return {
      lodash: _,
      showOptions: {
        lineLoader: false,
        headerShadow: false,
      },
      deleteId: null,
      noImageURL: "@/assets/images/no-image.png",
      confirmationFields: {
        show: false,
        title: "Delete changes?",
        type: "delete",
        modalType: "fixed",
        message:
          "This action cannot be undone. The documents will be deleted from the knowledge base.",
        iconType: "exclamation",
        confirmAction: true,
        confirmValue: null,
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
    };
  },
  computed: {},
  components: {
    ConfirmationModal,
    LineLoader,
  },
  methods: {
    getUpdatedDate,

    // Event on table scroll
    tableScroll() {
      const scrollTop = this.$refs.table.scrollTop;
      const scrollHeight = this.$refs.table.scrollHeight;
      const clientHeight = this.$refs.table.clientHeight;
      const bufferHeight = scrollHeight - clientHeight;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;

      // Check for inifinite scroll
      if (bufferHeight - scrollTop < 30) this.$emit("getDocuments");
    },

    // Goto view a particular document
    gotoItem(itemId, hasContent) {
      if (hasContent) this.$emit("documentSelected", itemId);
    },

    // Show the delete modal
    showDeleteModal(itemId, itemTitle) {
      this.deleteId = itemId;
      this.confirmationFields.confirmValue = itemTitle;
      this.confirmationFields.show = true;
      this.confirmationFields.title = `Delete ${itemTitle}?`;
    },

    // Delete the document
    async deleteItem() {
      this.showOptions.lineLoader = true;

      const data = { referenceId: this.deleteId };
      this.$emit("deleteDocument", data);

      this.closeModal();
    },

    // Broken image handler
    brokenImage(e) {
      e.target.src = require(`@/assets/images/no-image.png`);
    },

    // Close all the modals
    closeModal() {
      this.confirmationFields.show = false;
    },
  },
};
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.all-documents-table {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  margin-bottom: 0.5rem;
}

.grid-table.grid-item-clickable {
  flex: 1;
  overflow-y: scroll;
}
.grid-column {
  &:nth-child(1) {
    width: 2rem;
    margin-right: 1rem;
    text-align: center;
  }
  &:nth-child(2) {
    width: calc(100% - 7rem - 2 * 9rem - 3rem - 1rem);
    margin-right: 1rem;
  }
  &:nth-child(3),
  &:nth-child(4) {
    width: 9rem;
    text-align: center;
  }
}

.toggle-table {
  .grid-column {
    &:nth-child(1) {
      width: 2rem;
      margin-right: 1rem;
      text-align: center;
    }
    &:nth-child(2) {
      width: calc(100% - 7rem - 7rem - 1rem - 1rem);
      margin-right: 1rem;
    }
    &:nth-child(3) {
      width: 7rem;
      text-align: center;
      margin-right: 1rem;
    }
  }
}

.title {
  font-size: $smallFontSize;
  font-weight: $mediumFontWeight;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &.no-title {
    opacity: 0.75;
  }
}

.thumbnail {
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;

  &.doc-thumbnail {
    border-radius: 0;
    width: 1rem;
    height: auto;
    opacity: $lightOpacity;
  }

  &.collection-thumbnail {
    height: auto;

    /deep/ svg {
      fill: $greyColor;
      height: auto;
      width: 1.15rem;
      margin: auto;
    }
  }
}

.status {
  position: relative;
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  color: $greenMessageTextColor;
  background-color: lighten($greenMessageColor, 0%);
  border: 1px solid $greenMessageBorderColor;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  text-transform: capitalize;

  &.inactive {
    color: $redMessageTextColor;
    background-color: lighten($redMessageColor, 5%);
    border: 1px solid $redMessageBorderColor;
  }

  &.in-queue {
    color: $warningYellowColor;
    background-color: lighten($warningYellowBackgroundColor, 0%);
    border: 1px solid $warningYellowBorderColor;
  }
}

.last-sync,
.source,
.type {
  font-size: $smallerFontSize;
  text-transform: capitalize;
  color: $greyColor;
}

.last-sync {
  font-size: $smallestFontSize;
  opacity: $lightOpacity;
}

.btn-view {
  padding: 0;

  /deep/ svg {
    width: 1.5rem;
    height: auto;
  }

  &:hover {
    background: transparent;
    /deep/ svg {
      fill: $greyColor;
    }
  }
}

/deep/ .line-loader {
  position: relative !important;
  border-radius: 0.5rem;
}
</style>