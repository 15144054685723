<template>
  <!--START: Booking Drawer-->
  <div v-if="show" class="modal modal-large" :class="{ show: show }">
    <!--START: Header-->
    <ModalHeader :title="title" @closeModal="closeModal"></ModalHeader>
    <!--END: Header-->

    <!--START: Page Loader-->
    <div v-if="showOptions.preLoader" class="pre-loader-wrapper">
      <PreLoader :show="true"></PreLoader>
    </div>
    <!--START: Page Loader-->

    <!--START: Modal Body-->
    <div v-else class="modal-body">
      <!--START: Title-->
      <div class="title-wrapper">
        <img
          v-if="document.imageURL"
          :src="document.imageURL"
          :alt="document.title"
        />
        <div class="title-info">
          <h3 class="title">{{ document.title }}</h3>
          <a v-if="document.url" :href="document.url" target="_blank" class="link"
            ><unicon name="link"></unicon>{{ document.url }}</a
          >
        </div>
      </div>
      <!--END: Title-->

      <!--START: Attributes-->
      <div
        class="attributes-wrapper"
        :class="{ 'show-shadow': showOptions.showShadow }"
      >
        <FormBuilder
          :fields="fields"
          @fieldChanged="changeStatus"
        ></FormBuilder>
        <div class="sync-wrapper">
          <div class="sync-date">
            <label>Last synced at</label>
            <span>{{ getUpdatedDate(document.syncDate) }}</span>
          </div>
          <button class="btn btn-small btn-icon" @click="syncDocument">
            <unicon name="sync"></unicon>
            <span>Resync</span>
          </button>
        </div>
      </div>
      <!--END: Attributes-->

      <div
        class="content-wrapper"
        ref="documentContent"
        @scroll="contentScroll"
      >
        <p
          v-if="document.content"
          v-html="document.content"
          class="document-content"
        ></p>

        <!--START: Page Loader-->
        <div v-else class="pre-loader-wrapper update-loader">
          <span>Updating...</span>
          <PreLoader :show="true"></PreLoader>
        </div>
        <!--START: Page Loader-->
      </div>
    </div>
    <!--END: Modal Body-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
      @closeModal="closeModal"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader
      :show="showOptions.lineLoader"
      :class="{ bottom: !showOptions.modalActions }"
    ></LineLoader>
    <!--END: Loader-->
  </div>
  <!--END: Booking Drawer-->
</template>
      
<script>
// Importing components
import ModalHeader from "@/components/modals/ModalHeader";
import LineLoader from "@/components/loaders/LineLoader";
import NotificationMessage from "@/components/modals/NotificationMessage";
import FormBuilder from "@/components/form/FormBuilder";
import PreLoader from "@/components/loaders/PreLoader";

// Import functions
import { getUpdatedDate } from "@/utils/ticket.utils";

export default {
  name: "ViewDocument",
  props: {
    selectedDocument: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    documentUpdated: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    documentUpdated: {
      handler(data) {
        if (data) {
          this.showOptions.lineLoader = false;
          this.showStatusMessage(this.status, 2500);
        }
      },
    },
    selectedDocument: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data.length > 0) this.initDocument();
      },
    },
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  data() {
    return {
      title: "Document Details",
      document: {},
      fields: {
        isActive: {
          type: "toggle",
          title: "Use Document",
          additionalClass: "large-toggle toggle-left",
          value: true,
        },
      },
      showOptions: {
        lineLoader: false,
        showShadow: false,
        preLoader: true,
      },

      status: {
        show: false,
        status: "success",
        title: "Document updated",
      },
    };
  },
  components: {
    ModalHeader,
    LineLoader,
    FormBuilder,
    PreLoader,
    NotificationMessage,
  },
  created() {},

  methods: {
    getUpdatedDate,

    // Init the event timings
    async initDocument() {
      this.resetModal();
      this.constructDocument();
    },

    // Change the active status of the document
    async changeStatus() {
      this.$emit(
        "changeStatus",
        this.document.referenceId,
        this.fields.isActive.value
      );
      this.showStatusMessage(this.status, 2500);
    },

    // Function to select update type
    syncDocument() {
      if (this.document.type == "product") this.updateProduct();
      else this.updateDocument();
    },

    // Update the product and resync it
    async updateProduct() {
      this.document.content = "";
      this.$emit("updateProduct", this.document.referenceId);
    },

    // Update the product and resync it
    async updateDocument() {
      this.document.content = "";
      this.$emit("updateDocument", this.document);
    },

    // Construct the document object
    constructDocument() {
      // Extract all the content from the the chunks
      let content = "";
      const chunks = this.selectedDocument;

      chunks.forEach((c, index) => {
        content += c.content + (index + 1 == chunks.length ? "" : "\n\n");
      });
      content = content.replaceAll(
        `Product Name: ${chunks[0].title}. Product Description:`,
        ""
      );
      content = content.replaceAll(
        `DOCUMENT TITLE: ${chunks[0].title}.\nDOCUMENT DATA:`,
        ""
      );
      content = content.replaceAll(
        `Collection Name: ${chunks[0].title}.\nCollection Description:`,
        ""
      );

      // Construct the document object
      this.document = {
        referenceId: chunks[0].reference_id,
        title: chunks[0].title,
        imageURL: chunks[0].image,
        isActive: chunks[0].is_active,
        url: chunks[0].url,
        syncDate: chunks[0].updated_at,
        content,
      };

      // Set the active value
      this.fields.isActive.value = this.document.isActive;
      this.fields.isActive.title = `Use document`;

      // Hide the pre loader
      this.showOptions.preLoader = false;
    },

    // Reset modal
    resetModal() {
      this.showOptions.preLoader = true;
      this.showOptions.lineLoader = false;
      this.showOptions.showShadow = false;
      this.document = {};
    },

    // Event on chat scroll
    contentScroll() {
      if (this.$refs.documentContent.scrollTop > 0)
        this.showOptions.showShadow = true;
      else this.showOptions.showShadow = false;
    },

    // Hide modal
    closeModal() {
      this.showOptions.preLoader = true;
      this.showOptions.lineLoader = false;
      this.$emit("closeModal");
    },
  },
};
</script>
      
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

/deep/ .modal-header {
  padding: 0.5rem 1rem;

  /deep/ .unicon svg {
    background-color: lighten($blackColor, 15%);
    fill: $whiteColor;
  }
}

.modal-body {
  position: relative;
  padding: 1.5rem;
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

/deep/ .status-message {
  position: relative;
  bottom: auto;
  right: 0;
  left: 0;
  border-radius: 0;
  text-align: center;
}

/deep/ .line-loader {
  position: relative;
  bottom: auto;
  right: 0;
  left: 0;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 5rem;
    border-radius: 0.75rem;
    margin-right: 1rem;
    border: 1px solid $inputBorderColor;
  }
  .title-info {
    display: block;
    overflow: hidden;
    .title {
      font-size: $mediumFontSize;
    }
    .link {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $blueColor;
      font-size: $smallerFontSize;
      margin-right: 5rem;

      .unicon /deep/ svg {
        display: inline-block;
        vertical-align: middle;
        fill: $blueColor;
        width: 0.75rem;
        height: auto;
        margin-right: 0.25rem;
      }
    }
  }
}

.attributes-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0 0;
  padding: 0 0.5rem 2rem;
  border-bottom: 1px solid $inputBorderColor;
  z-index: 5;

  &.show-shadow {
    box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
  }

  /deep/ {
    .field-main-item {
      margin-bottom: 0;
    }
    .field-title label {
      text-transform: capitalize;
    }
  }

  .attribute {
    margin-left: 4rem;

    span {
      display: block;
      color: $paragraphColor;
      font-size: $smallFontSize;
      text-transform: capitalize;
      font-weight: $mediumFontWeight;
      line-height: 1;
    }

    label {
      color: $paragraphColor;
      font-size: $smallestFontSize;
      text-transform: capitalize;
      opacity: $lightOpacity;
    }
  }
}

.content-wrapper {
  flex: 1;
  overflow-y: scroll;
  width: auto;
  margin: 0;

  .document-content {
    font-size: $smallFontSize;
    white-space: pre-line;
    margin: 1rem;
  }
}

.pre-loader-wrapper {
  margin: 2rem 3rem;
}

.sync-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2.5rem;

  .sync-date {
    display: block;
    font-size: $smallestFontSize;
    color: $greyColor;
    margin-right: 1rem;

    label {
      margin-right: 0.25rem;
    }
  }

  .btn {
    font-size: $smallerFontSize;

    /deep/ svg {
      width: $smallerFontSize;
    }
  }
}

.update-loader {
  text-align: center;
  margin: 2rem 1.5rem;

  span {
    display: block;
    font-size: $smallerFontSize;
    color: $greyColor;
    margin: -0.5rem 0 1.5rem;
  }

  /deep/ {
    .buffer-title,
    .buffer-line {
      width: 100%;
    }

    .buffer-category {
      display: none;
    }
  }
}

.collection-actions {
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    .btn {
      margin-right: 1rem;
    }
  }
}
</style>