<template>
  <div class="main-wrapper">
    <div class="video-wrapper">
      <video muted autoplay loop class="intro-video">
        <source
          src="/assets/videos/widget/widget-installation.mp4"
          type="video/mp4"
        />
      </video>
      <img src="/assets/images/gradient-light.jpg" />
    </div>
    <div class="gradient-border">
      <span>New Macha AI Widget</span>
    </div>
    <h3>Start Using AI to Respond</h3>
    <p>
      Connect your Zendesk with Macha and use AI to create replies with simple
      instructions, help center articles, translations and more
    </p>
    <span class="trial">Start 7 Day Free Trial</span>
  </div>
</template>
    
<script>
export default {
  name: "ZendeskInstallation",
  data() {
    return {};
  },
  components: {},
  methods: {},
};
</script>
    
<style scoped lang="scss">
.main-wrapper {
  text-align: center;

  span {
    display: block;
    font-size: $smallFontSize;
    font-weight: $mediumFontWeight;
    color: $purpleColor;
    margin-top: 1rem;
  }

  h3 {
    font-size: 1.35rem;
  }

  p {
    font-size: $smallFontSize;
    width: 80%;
    margin: 1rem auto 0;
  }
}

.video-wrapper {
  position: relative;
  width: 57.5%;
  padding: 1.05rem 2rem 0;
  margin: auto;
  border: 1px solid #ececec;
  border-radius: 0.75rem;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 0;
  }
}

.intro-video {
  position: relative;
  z-index: 1;
  width: 100%;
  border-radius: 1rem 1rem 0 0;
  border: 2px solid #f3edf6;
  box-shadow: 0 0.5rem 0.7rem -0.15rem #e7e2f4;
}

span.trial {
  font-size: $smallerFontSize;
  color: $darkBlackColor;
  font-weight: $mediumFontWeight;
}

.gradient-border {
  display: inline-block !important;
  --borderWidth: 2px;
  padding: 3px 14px;
  background: #1e1e1e;
  position: relative;
  border-radius: 5px;
  margin: 1rem auto 0.5rem;
  background: linear-gradient(
    90deg,
    #7028d1 5%,
    #4809da 40%,
    #6922ff 60%,
    #7028d1 80%
  );
  background-size: 200% auto;
  border: 1px solid #ececec;
  animation: gradientAnimation 10s infinite forwards;

  @keyframes gradientAnimation {
    from {
      background-position: 0%;
    }

    to {
      background-position: 200%;
    }
  }

  span {
    margin: 0;
    color: #fff;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: radial-gradient(circle at bottom left, #fff, #ffffffcf);
    font-size: $smallerFontSize !important;
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>