// Dashboard services
import AiAgentService from "./aiAgent.service";
import TicketService from "./ticket.service";
import FilterRuleService from "./filterRule.service";
import TopicService from "./topic.service";

export default {
  AiAgentService,
  TicketService,
  FilterRuleService,
  TopicService,
};
