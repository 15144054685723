<template>
  <div v-if="show">
    <!--START: Notion App-->
    <div
      class="modal modal-large"
      :class="[
        { show: show },
        { 'show-success': showOptions.state == 'success' },
      ]"
    >
      <!--START: Header-->
      <ModalHeader :title="title" @closeModal="closeModal"></ModalHeader>
      <!--END: Header-->

      <!--START: Modal Body-->
      <div class="modal-body">
        <AddForm v-if="showOptions.state == 'form'" @addApp="addApp"></AddForm>

        <!--START: Status Message-->
        <SuccessForm
          v-else-if="showOptions.state == 'success'"
          :successForm="successForm"
          @primaryEvent="closeModal"
        ></SuccessForm>
        <!--END: Status Message-->
      </div>
      <!--END: Modal Body-->

      <!--START: Notification Message-->
      <NotificationMessage
        :show="status.show"
        :status="status.status"
        :title="status.title"
        @closeModal="closeModal"
      ></NotificationMessage>
      <!--END: Notification Message-->

      <!--START: Loader-->
      <LineLoader
        :show="showOptions.lineLoader"
        :class="{ bottom: !showOptions.modalActions }"
      ></LineLoader>
      <!--END: Loader-->
    </div>
    <!--END: Notion App-->

    <div class="bg-overlay light show" @click="closeModal"></div>
  </div>
</template>
        
<script>
// Importing components
import ModalHeader from "@/components/modals/ModalHeader";
import LineLoader from "@/components/loaders/LineLoader";
import NotificationMessage from "@/components/modals/NotificationMessage";
import SuccessForm from "@/components/modals/SuccessForm";

import AddForm from "./AddForm";

// Importing Services
import { CompanyService } from "@/services";

export default {
  name: "AddNotionApp",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    show: {
      deep: true,
      immediate: true,
      handler() {
        this.initModal();
      },
    },
  },
  computed: {
    // Get the app
    app() {
      let app = {};
      if (this.company?.integrations?.notion)
        app = this.company?.integrations?.notion;
      return app;
    },

    company() {
      return this.$store.getters.company;
    },
  },
  data() {
    return {
      title: "Add Source",
      waitingForResponse: false,
      showOptions: {
        lineLoader: false,
        showShadow: false,
        preLoader: true,
        state: "form",
      },
      status: {
        show: false,
        status: "success",
        title: "Notion connected",
      },
      successForm: {
        show: true,
        status: "success",
        title: "Your Notion account is now connected",
        message: "You can now connect your knowledge base",
        primaryBtn: { title: "Okay, done" },
      },
    };
  },
  components: {
    ModalHeader,
    LineLoader,
    AddForm,
    NotificationMessage,
    SuccessForm,
  },
  created() {
    this.initModal();
  },

  methods: {
    // Initialise the modal
    initModal() {
      const { isConnected } = this.app;

      if (isConnected) this.showOptions.state = "success";
      else this.showOptions.state = "form";
    },

    // Add the links and get the documents
    async addApp(data) {
      if (!this.waitingForResponse) {
        this.waitingForResponse = true;
        this.showOptions.lineLoader = true;

        const response = await CompanyService.AddIntegration(data);

        // Check for errors
        if (!response.hasError) {
          this.showOptions.state = "success";
          this.$store.commit("updateCompany", response.data);
          this.$emit("appAdded");
        } else this.showErrorMessage(this.status, response.message);

        this.waitingForResponse = false;
        this.showOptions.lineLoader = false;
      }
    },

    // Hide modal
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

/deep/ .modal-header {
  padding: 0.5rem 1rem;

  /deep/ .unicon svg {
    background-color: lighten($blackColor, 15%);
    fill: $whiteColor;
  }
}

/deep/ .status-message {
  position: relative;
  bottom: auto;
  right: 0;
  left: 0;
  border-radius: 0;
  text-align: center;
}

/deep/ .line-loader {
  position: relative;
  bottom: auto;
  right: 0;
  left: 0;
}

.pre-loader-wrapper {
  margin: 2rem 3rem;
}

.modal {
  &.show-success {
    width: auto;
  }

  .modal-body /deep/ {
    display: flex;
    padding: 0 !important;
    overflow-y: hidden;

    .app-body {
      position: relative;
      flex: 1;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    .settings-form .field-main-item .field-wrapper {
      min-width: 50%;
    }
    .title-wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1.5rem 0.5rem;
      margin: 0 1.5rem;
      border-bottom: 1px solid $inputBorderColor;
      z-index: 5;

      .title-info {
        flex: 1;
        margin-right: 3rem;
        .title {
          font-size: $mediumFontSize;
        }
        p {
          font-size: $smallFontSize;
          color: $paragraphColor;
        }
      }
    }

    .scroll-body {
      flex: 1;
      overflow-y: scroll;
      padding: 2rem 2rem 1rem;
    }

    .show-shadow {
      box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
    }
  }
}

.success-form {
  margin: 2rem 3rem;
}
</style>