// Dashboard services
import AiAssistantService from "./aiAssistant.service";
import TicketService from "./ticket.service";
import AnswerRuleService from "./answerRule.service";
import PersonaService from "./persona.service";

export default {
  AiAssistantService,
  TicketService,
  AnswerRuleService,
  PersonaService,
};
