<template>
  <div>
    <!--START: App Router View-->
    <keep-alive>
      <router-view :key="$route.params.id" />
    </keep-alive>
    <!--END: App Router View-->
  </div>
</template>

<script>
// Importing Components

export default {
  name: "MachaApp",
  data() {
    return {};
  },
  components: {},
  async created() {
    this.checkRedirectRoute();
  },
  methods: {
    checkRedirectRoute() {
      let redirectRoute = "";
      if (this.$route.query?.redirect_to) {
        redirectRoute = this.$route.query?.redirect_to;
      }

      this.$store.commit("updateRedirectRoute", redirectRoute);
    },
  },
};
</script>

<style>
/* Global Fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");
</style>