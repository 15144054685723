<template>
  <div>
    <div class="info-wrapper">
      <h3 class="section-title">Available Plans</h3>
      <p>Unlock the magic of AI in your Zendesk with Macha</p>
    </div>

    <!--START: Pricing Tables-->
    <div class="pricing-tables">
      <div
        v-for="plan in plans"
        :key="plan._id"
        class="pricing-block"
        :class="[
          { recommended: plan.highlightPlan },
          { 'current-plan': enableUpgrade && plan.planType === 'fixed' },
        ]"
      >
        <span v-if="plan.highlightPlan" class="recommended-badge">{{
          enableUpgrade ? "Upgrade Plan" : "Most Popular"
        }}</span>

        <!--START: Main Details-->
        <div class="plan-details">
          <span class="title">{{ plan.title }}</span>
          <p class="description">{{ plan.description }}</p>

          <!--START: Pricing-->
          <div class="price-details">
            <div class="large-price">
              ${{ plan.price }} <em>{{ plan.unit }}</em>
            </div>
            <div v-if="plan.pricingTooltip" class="tooltip-wrapper">
              <span>i</span>
              <div class="tooltip">{{ plan.pricingTooltip }}</div>
            </div>
          </div>
          <!--END: Pricing-->

          <!--START: Inclusitions-->
          <div class="inclusions">
            <span
              v-for="inclusion in plan.inclusions"
              :key="inclusion.id"
              v-html="inclusion"
            >
            </span>
          </div>
          <!--END: Inclusions-->

          <button
            :disabled="enableUpgrade && plan.planType == 'fixed'"
            class="btn btn-border btn-subscribe"
            @click="subscribe(plan.planId)"
          >
            {{
              enableUpgrade && plan.planType == "fixed"
                ? "Current Plan"
                : "Upgrade Plan"
            }}
          </button>
        </div>
        <!--END: Main Details-->
      </div>
    </div>
    <!--END: Pricing Tables-->

    <!--START: Upgrade Modal-->
    <div
      class="modal upgrade-modal"
      :class="{ show: showOptions.upgradeModal }"
    >
      <div class="modal-body">
        <div class="plan-wrapper">
          <div class="plan-info">
            <span class="subtitle">Upgrade Plan</span>
            <h3 class="title">{{ meteredPlan.title }}</h3>
            <div class="inclusions-list">
              <span
                v-for="i in meteredPlan.inclusions"
                :key="i.id"
                class="inclustion-item"
                v-html="i"
              ></span>
            </div>
          </div>
          <div class="plan-pricing">
            <div class="total-price">
              <em
                >${{
                  meteredPlan.price * (agents.length >= 3 ? agents.length : 3)
                }}</em
              >
              <span>per month</span>
            </div>
            <div class="breakup-wrapper">
              <span class="unit-price">${{ meteredPlan.price }}</span>
              <b>x</b>
              <span class="agent-count"
                >{{ agents.length >= 3 ? agents.length : 3 }} agents </span
              >per month
            </div>
          </div>
        </div>
        <div class="disclaimer">
          Your current plan amount will be adjusted against the new plan
        </div>

        <button
          class="btn btn-primary"
          @click="upgradePlan(meteredPlan.planId)"
        >
          Confirm Upgrade
        </button>
      </div>
    </div>
    <div
      class="bg-overlay"
      :class="{ show: showOptions.upgradeModal }"
      @click="hideModals"
    ></div>
    <!--END: Upgrade Modal-->
  </div>
</template>
    
<script>
// Importing Services
import { SubscriptionService } from "@/services";

export default {
  name: "AvailablePlans",
  props: {
    enableUpgrade: { type: Boolean, default: false },
  },
  data() {
    return {
      showOptions: {
        upgradeModal: false,
      },
      plans: [
        {
          planId: "business-plan-ai-assistant-for-zendesk-monthly",
          title: "Business Plan",
          description: "For larger teams",
          planType: "metered",
          price: 15,
          pricingTooltip:
            "Only agents and admins are included. Light agents and Contributors are excluded",
          highlightPlan: true,
          unit: "/ agent / month",
          inclusions: [
            "+ <b>Unlimited</b> AI answers",
            "+ Minimum of 3 agents",
          ],
        },
      ],
      agents: [],
    };
  },
  computed: {
    meteredPlan() {
      return this.plans.find((p) => p.planType === "metered");
    },
  },
  created() {
    this.getBillableAgents();
  },
  methods: {
    // Get the billable agents
    async getBillableAgents() {
      const response = await SubscriptionService.GetBillableAgents();
      if (!response.hasError) {
        this.agents = response.data;
      }
    },

    // Show the upgrade modal
    showUpgradeModal() {
      this.showOptions.upgradeModal = true;
    },

    // Upgrade the plan
    upgradePlan(planId) {
      this.$emit("subscribePlan", planId);
    },

    // Subscribe to the plan
    subscribe(planId) {
      if (this.enableUpgrade) {
        this.showUpgradeModal();
      } else {
        this.$emit("subscribePlan", planId);
      }
    },

    // Hide all the modals
    hideModals() {
      this.showOptions.upgradeModal = false;
    },
  },
};
</script>
    
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.page-header {
  margin-top: -0.5rem;
  margin-bottom: 2rem;
}

.info-wrapper {
  margin: 3rem 0 2rem;

  h3 {
    font-family: $titleFont;
    font-size: $largerFontSize;
    font-weight: $boldFontWeight;
  }

  p {
    font-size: $smallFontSize;
    opacity: $lightOpacity;
  }
}

.pricing-wrapper {
  position: relative;
}

.pricing-tables {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 0 3rem;
  gap: 1rem;
}

.pricing-block {
  position: relative;
  width: 40%;
  padding: 2rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid #ececec;
  box-shadow: 0 0rem 1rem -0.15rem rgba(111, 110, 110, 0.15);
  backdrop-filter: blur(15px);
  transform: translate3d(0, 0, 0);

  .title {
    color: $darkBlackColor;
    font-weight: $boldFontWeight;
    font-size: $mediumFontSize;
  }

  .description {
    font-size: $smallerFontSize;
    opacity: 0.75;
    margin: 0.25rem 0 1rem;
    line-height: 1.4;
  }

  .price-details {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 1rem;

    .tooltip-wrapper {
      margin-left: 0.5rem;
      transform: translateY(-2px);

      span {
        display: block;
        font-size: 10px;
        background-color: $darkBlackColor;
        color: $whiteColor;
        width: 14px;
        text-align: center;
        border-radius: 50%;

        &:hover + .tooltip {
          opacity: 1;
          z-index: 100;
        }
      }

      .tooltip {
        transition: none;
        background-color: $darkBlackColor;
        bottom: calc(100% + 1.5rem);
        text-align: center;
        width: 16rem;
        line-height: 1.5;
      }
    }
  }

  .large-price {
    display: block;
    font-family: $titleFont;
    color: $darkBlackColor;
    font-weight: $boldFontWeight;
    font-size: $mediumFontSize;
    font-size: 1.5rem;

    em {
      color: lighten($darkBlackColor, 15%);
      font-weight: $mediumFontWeight;
      font-size: $normalFontSize;
      margin-left: 0;
    }
  }

  .btn-subscribe {
    font-size: $smallFontSize;
    display: block;
    padding: 0.75rem 0.5rem;
    width: 100%;
    margin: 1rem auto 0;

    &.btn-upgrade {
      background: $darkBlackColor;
      border-color: lighten($darkBlackColor, 15%);
      color: $whiteColor;
    }

    &:hover {
      background: $purpleColor;
      border-color: lighten($purpleColor, 15%);
      color: $whiteColor;
    }

    &.btn-current-plan {
      width: calc(100% - 1rem);
      background: #f2fae9 !important;
      color: #5a876f !important;
      border-color: #adcebd !important;
    }
  }

  &.recommended {
    border-color: $purpleColor;

    .btn-subscribe {
      background-color: $darkBlackColor;
      color: $whiteColor;

      &:hover {
        background-color: $purpleColor;
      }
    }
  }

  &.current-plan {
    .title,
    .large-price,
    .inclusions {
      opacity: 0.65;
    }

    .btn {
      background-color: $greyButtonColor;
      color: $greyButtonTextColor;
      border: 1px solid $greyButtonBorderColor;
    }
  }

  .volume-details {
    margin-top: 0;
    margin-bottom: 0.5rem;

    span {
      display: block;
      color: lighten($darkBlackColor, 35%);

      &.ticket-volume {
        font-size: 0.9rem;
        color: $purpleColor;
      }

      &.billing-cycle {
        margin-top: 1.5rem;
      }
    }
  }

  &.current {
    border: 2px solid $emeraldColor;
    background: rgba(250, 255, 241, 0.55);
  }
}

.inclusions-list {
  .title {
    display: block;
    color: $darkBlackColor;
    font-weight: $boldFontWeight;
    font-size: $normalFontSize;
    margin-top: 3.5rem;
  }
  .feature {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0;

    :deep(svg) {
      fill: $emeraldColor;
      width: auto;
      height: 1.15rem;
      margin-right: 1rem;
    }

    span {
      color: lighten($darkBlackColor, 35%);
    }
  }
}

.recommended-badge {
  position: absolute;
  color: lighten($purpleColor, 75%);
  background-color: $purpleColor;
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  font-size: $smallerFontSize;
  font-weight: $mediumFontWeight;
  top: -1rem;
  left: 1.5rem;
}

.inclusions {
  span {
    color: $paragraphColor;
    display: block;
    font-size: $smallFontSize;
  }
}

.modal {
  display: none;

  &.show {
    display: block;
  }
}

.upgrade-modal {
  background-color: $whiteColor;
}

.modal-body {
  .plan-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .btn {
    display: block;
    width: 100%;
    margin-top: 0.5rem;
  }

  .plan-info {
    flex: 1;

    .subtitle {
      display: block;
      font-size: $smallestFontSize;
      color: $greyColor;
      font-weight: $mediumFontWeight;
    }
  }

  .title {
    font-size: $largestFontSize;
    color: $purpleColor;
  }

  .inclustion-item {
    display: block;
    font-size: $smallFontSize;
    opacity: 0.75;
  }

  .plan-pricing {
    text-align: right;

    .total-price {
      em {
        font-size: $largestFontSize;
        color: $darkBlackColor;
        font-weight: 700;
      }

      span {
        font-weight: $mediumFontWeight;
        color: lighten($darkBlackColor, 20%);
        margin-left: 0.35rem;
      }
    }

    .breakup-wrapper {
      color: lighten($darkBlackColor, 20%);
      font-weight: $mediumFontWeight;

      .agent-count,
      .unit-price {
        font-size: $mediumFontSize;
        color: $darkBlackColor;
        font-weight: 600;
      }

      b {
        font-weight: $mediumFontWeight;
        font-weight: 400;
        margin: 0 0.25rem;
      }
    }
  }

  .disclaimer {
    margin-top: 1.5rem;
    text-align: center;
    background: lighten($greenColor, 37.5%);
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid darken($greenColor, 5%);
    color: darken($greenColor, 30%);
    font-weight: $mediumFontWeight;
    font-size: $smallerFontSize;
  }
}
</style>