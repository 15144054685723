<template>
  <div v-if="showComponent" class="authentication-component">
    <!--START: Vue Headful-->
    <vue-headful :title="meta.title" />
    <!--END: Vue Headful-->

    <!--START: Logo-->
    <img src="@/assets/images/logo.png" class="logo" alt="Macha Logo" />
    <!--END: Logo-->

    <div class="authentication-wrapper">
      <!--START: Illustration-->
      <img
        src="@/assets/images/doodles/otp-mail.png"
        class="card-illustration"
        alt="Welcome to Macha"
      />
      <!--END: Illustration-->

      <div class="card">
        <!--START: Intro-->
        <div class="intro-wrapper">
          <h1>Check your email</h1>
          <p>
            Enter the 6-character code we sent to
            <b>{{ this.$store.getters.user?.user?.email }}</b> to continue. The
            code expires soon.
          </p>
        </div>
        <!--END: Intro-->

        <!--START: Form Wrapper-->
        <form v-on:submit.prevent="validateOTP()">
          <FormBuilder
            :fields="fields"
            @fieldChanged="fieldChanged()"
          ></FormBuilder>
        </form>
        <!--END: Form Wrapper-->

        <!--START: Status Message-->
        <StatusMessage
          :show="status.show"
          :status="status.status"
          :message="status.message"
        ></StatusMessage>
        <!--END: Status Message-->

        <!--START: Loader-->
        <span v-if="showLoader && status.status != 'success'" class="otp-status"
          >Validating your OTP</span
        >
        <LineLoader :show="showLoader"></LineLoader>
        <!--END: Loader-->
      </div>

      <!--START: Alternate Option-->
      <div class="alternate-option">
        <!--START: OTP Options-->
        <div class="mail-clients-wrapper">
          <div>
            <img src="@/assets/images/apps/gmail.png" alt="Gmail Logo" />
            <a href="https://gmail.com/" target="_blank">Open Gmail</a>
          </div>
          <div>
            <img src="@/assets/images/apps/outlook.png" alt="Outlook Logo" />
            <a href="https://outlook.com/" target="_blank">Open Outlook</a>
          </div>
        </div>
        <span>Can't find your code? Check your spam folder</span>
      </div>
      <!--END: OTP Options-->
      <!--END: Alternate Option-->
    </div>
  </div>
</template>
  
<script>
// Importing services
import { AuthService } from "@/services";

// Importing components
import LineLoader from "@/components/loaders/LineLoader";
import FormBuilder from "@/components/form/FormBuilder";
import StatusMessage from "@/components/modals/StatusMessage";

export default {
  name: "AuthenticateLogin",
  data() {
    return {
      email: "",
      showComponent: false,
      showLoader: false,
      otpValidated: false,
      meta: {
        title: "Authenticate Login",
      },
      status: {
        show: false,
        status: null,
        message: null,
      },
      fields: {
        otp: {
          type: "otp",
          codeLength: 6,
          readonly: false,
          required: true,
          hasError: false,
        },
      },
    };
  },
  watch: {
    $route(to) {
      if (to.name === "AuthenticateLogin") {
        this.showComponent = false;
        this.status.show = false;
        this.showLoader = false;
        this.otpValidated = false;

        this.initRoute();
      }
    },
  },
  components: {
    LineLoader,
    FormBuilder,
    StatusMessage,
  },
  async created() {
    await this.initRoute();
  },
  methods: {
    async initRoute() {
      if (!(await this.authenticateUser())) {
        this.showComponent = true;
        this.initView();
      } else {
        this.redirectDashboard();
      }
    },

    // Check if user has added email
    initView() {
      if (!this.$store.state.user.user.email)
        this.$router.push({ path: "/sign-in" });
    },

    async validateOTP() {
      if (!this.otpValidated && !this.validatingOtp) {
        this.fields.otp.readonly = true;
        this.validatingOtp = true;

        // Hide status message and show line loader
        this.status.show = false;

        // Check if the form has valid input
        var isFormValid = this.validateForm(this.fields);
        if (isFormValid) {
          this.showLoader = true;

          // Use the service to authenticate the user
          const response = await AuthService.ValidateEmailOTP({
            ...this.parseData(this.fields),
            email: this.$store.getters.user.user.email,
          });

          if (response.hasError) {
            this.validatingOtp = false;
            this.fields.otp.readonly = false;
            this.status.status = "error";
            this.status.message = response.message;
            this.showLoader = false;
          } else if (!response.hasError) {
            this.otpValidated = true;
            this.status.status = "success";
            this.status.message = response.message;
            this.redirectUser(response.data);
            this.resetFields(this.fields);
          }

          this.status.show = true;
        } else {
          this.validatingOtp = false;
          this.fields.otp.readonly = false;
        }
      }
    },

    async redirectUser(data) {
      if (data.hasMultipleCompanies) {
        this.$store.commit("updateUser", {
          user: { ...data },
          loggedIn: false,
        });
        this.$router.push({ path: `/select-company` });
      } else {
        // Get the logged in user and init the Vues store variables
        await this.getUser();
        this.redirectDashboard();
      }

      this.validatingOtp = false;
      this.fields.otp.readonly = false;
    },

    fieldChanged() {
      const { otp } = this.fields;
      if (otp.value.length === otp.codeLength) this.validateOTP();
    },
  },
};
</script>
  
  <style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
@import "@/assets/styles/shared/authentication.scss";

.authentication-wrapper {
  position: relative;
  width: 40%;
  margin-top: 4rem;
  transform: none;
  left: auto;
  top: auto;

  .card {
    overflow: hidden;
  }
}

.logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.card-illustration {
  position: relative;
  left: auto;
  bottom: auto;
  height: auto;
  width: 40%;
  margin-bottom: -3rem;
}

/deep/ .line-loader {
  position: absolute;
  bottom: 0;
  left: 0;
}
.otp-status {
  display: block;
  color: $greyColor;
  font-size: $smallerFontSize;
  margin-top: 2rem;
}

.alternate-option {
  margin: 2rem auto 0;

  span {
    font-size: $smallFontSize;
    color: $greyColor;
  }

  .mail-clients-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 2rem;

      img {
        display: block;
        width: 1rem;
        margin-right: 0.5rem;
      }

      a {
        font-size: $smallFontSize;
        color: $greyColor;
      }
    }
  }
}
</style>