<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div class="header-wrapper">
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button class="btn btn-small btn-grey" @click="gotoArticle(false)">
        Add Article
      </button>
    </div>
    <!--END: Header Wrapper-->

    <!--START: Zero State -->
    <ZeroState
      v-if="showOptions.zeroState"
      class="zero-state"
      :imageURL="zeroStateData.imageURL"
      :title="zeroStateData.title"
      :message="zeroStateData.message"
      :buttonTitle="zeroStateData.buttonTitle"
      @eventHandler="gotoArticle(false)"
    ></ZeroState>
    <!--END: Zero State -->

    <!--START: Articles Table-->
    <DocumentsTable
      v-else-if="documents"
      :documents="documents"
      :totalCount="totalCount"
      :showInfinteSroll="gettingDocuments"
      :showFilter="false"
      @documentSelected="gotoArticle"
      @deleteDocument="deleteDocument"
      @getDocuments="getDocuments"
    ></DocumentsTable>
    <!--END: Articles Table-->

    <!--START: Loader-->
    <PageLoader
      v-if="!showOptions.initDocuments"
      :showLoader="true"
    ></PageLoader>
    <!--END: Loader-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
        
<script>
// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import ZeroState from "@/components/misc/ZeroState";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";

import DocumentsTable from "@/components/dashboard/tables/DocumentsTable";

// Importing Services
import { DocumentService } from "@/services";
import "external-svg-loader";

export default {
  name: "ViewArticles",
  data() {
    return {
      showOptions: {
        addLocation: false,
        lineLoader: false,
        initDocuments: false,
        zeroState: false,
        viewDocument: false,
      },
      regularHeader: {
        title: "Articles",
        description:
          "A view of all your articles used to generate AI responses",
      },
      zeroStateData: {
        imageURL: "services.jpg",
        title: "Add Articles",
        message:
          "Use the articles to add knowledge to the AI, beyond what's available from your products, help centers etc.",
        buttonTitle: "Add Article",
      },
      status: {
        show: false,
        status: "success",
        title: "Document deleted",
      },
      selectedReferenceId: "",
      totalCount: 0,
      documents: [],
      documentType: null,
      gettingDocuments: false,
      documentLimitReached: false,
      pageNumber: 0,
      pageSize: 20,
    };
  },
  props: {},
  watch: {},
  components: {
    RegularHeader,
    ZeroState,
    NotificationMessage,
    LineLoader,
    PageLoader,
    DocumentsTable,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  async created() {
    await this.getDocuments();
  },

  methods: {
    // Delete document from Supabase
    async deleteDocument(data) {
      this.showOptions.lineLoader = true;

      data.sourceId = this.company._id;
      await DocumentService.DeleteDocument(data);

      // Remove from the table
      const index = this.documents.findIndex(
        (d) => d.reference_id == data.referenceId
      );
      this.documents.splice(index, 1);
      if (this.documents.length === 0) this.showOptions.zeroState = true;

      this.showStatusMessage(this.status, 2500);
      this.showOptions.lineLoader = false;
    },

    // Route to article
    gotoArticle(referenceId) {
      let path = `/knowledge-base/articles/add`;
      if (referenceId) path += `/${referenceId}`;

      this.$router.push({ path });
    },

    // Function to get knowledge base
    async getDocuments() {
      if (!this.documentLimitReached && !this.gettingDocuments) {
        this.gettingDocuments = true;
        this.showOptions.zeroState = false;

        this.pageNumber = this.pageNumber + 1;
        const data = {
          sourceId: this.company._id,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        };

        const response = await DocumentService.GetDocuments(data);

        // Check for errors
        if (!response.hasError) {
          const { documents, totalCount } = response.data;
          this.totalCount = totalCount;
          this.documents.push(...documents);

          if (this.documents.length == 0) this.showOptions.zeroState = true;
          if (documents.length == 0) this.documentLimitReached = true;

          this.gettingDocuments = false;
          this.showOptions.initDocuments = true;
        } else this.showErrorMessage(this.status, response.message);
      }
    },

    // Change the active status
    async changeDocumentStatus(referenceId, isActive) {
      const data = { referenceId, isActive };
      await DocumentService.ToggleDocumentStatus(data);

      var document = this.documents.find((d) => d.reference_id == referenceId);
      document.is_active = isActive;
    },

    // Clear the documents
    clearDocuments() {
      this.pageNumber = 0;
      this.documents.splice(0);
      this.documentLimitReached = false;
    },

    // Close all the modals
    closeModal() {
      this.status.show = false;
      this.showOptions.addLocation = false;
      this.showOptions.viewDocument = false;
    },
  },
};
</script>
        
<style scoped lang="scss">
.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1.5rem;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.zero-state {
  margin-top: 2.5rem;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ {
  .line-loader {
    position: fixed;
    z-index: 101;
    bottom: 0;
    left: 0;
  }
  .notification-message {
    position: fixed;
  }
}
</style>