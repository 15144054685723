<template>
  <div v-if="showComponent" class="authentication-component">
    <!--START: Vue Headful-->
    <vue-headful :title="meta.title" />
    <!--END: Vue Headful-->

    <!--START: Logo-->
    <img src="@/assets/images/logo.png" class="logo" alt="Macha Logo" />
    <!--END: Logo-->

    <div class="authentication-wrapper">
      <!--START: Illustration-->
      <img
        src="@/assets/images/doodles/workspace-selection.png"
        class="card-illustration"
        alt="Welcome to Macha"
      />
      <!--END: Illustration-->

      <div class="card">
        <!--START: Intro-->
        <div
          class="intro-wrapper"
          :class="{ 'show-shadow': showOptions.headerShadow }"
        >
          <h1>Choose your workspace</h1>
          <p>
            <b>{{ email }}</b> has access to the following workspaces
          </p>
        </div>
        <!--END: Intro-->

        <!--START: Workspace selection-->
        <div
          ref="workspaces"
          class="workspaces-wrapper"
          @scroll="workspaceScroll"
        >
          <div
            v-for="company in companies"
            :key="company.id"
            class="workspace-item"
            @click="selectCompany(company._id)"
          >
            <!--START: Logo-->
            <div class="workspace-logo">
              <img
                v-if="company.logoURL != undefined"
                :src="company.logoURL"
                :alt="company.name"
              />
              <ProfileAvatar
                v-else
                :fullName="company.name"
                size="extra-large"
              ></ProfileAvatar>
            </div>
            <!--END: Logo-->

            <!--START: Workspace Info-->
            <div class="workspace-info">
              <h3>{{ company.name }}</h3>
              <span :class="{ 'no-store': !zendeskDomain(company) }">{{
                zendeskDomain(company)
                  ? zendeskDomain(company)
                  : "No Zendesk Instance"
              }}</span>
            </div>
            <!--END: Workspace Info-->

            <!--START: Action-->
            <div class="workspace-action">
              <button class="btn btn-icon">
                <unicon name="angle-right-b"></unicon>
              </button>
            </div>
            <!--END: Action-->
          </div>
        </div>
        <!--END: Workspace selection-->

        <!--START: Loader-->
        <LineLoader :show="showLoader"></LineLoader>
        <!--END: Loader-->
      </div>

      <!--START: Alternate Option-->
      <div class="alternate-option">
        <p>Can't find your workspace?</p>
        <router-link to="/sign-in">Try a different e-mail address</router-link>
      </div>
      <!--END: Alternate Option-->
    </div>
  </div>
</template>
    
<script>
// Importing components
import ProfileAvatar from "@/components/misc/ProfileAvatar";
import LineLoader from "@/components/loaders/LineLoader";

// Importing services
import { AuthService } from "@/services";

export default {
  name: "SelectCompany",
  data() {
    return {
      email: null,
      stores: [],
      showComponent: false,
      showLoader: false,
      showOptions: {
        headerShadow: false,
      },
      meta: {
        title: "Select Your Company",
      },
      status: {
        show: false,
        status: null,
        message: null,
      },
    };
  },
  watch: {
    $route(to) {
      if (to.name === "SelectCompany") {
        this.companies = [];
        this.showLoader = false;
        this.email = null;

        this.initRoute();
      }
    },
  },
  components: {
    ProfileAvatar,
    LineLoader,
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  async created() {
    await this.initRoute();
  },
  methods: {
    async initRoute() {
      if (!(await this.authenticateUser())) {
        this.showComponent = true;
        this.initView();
      } else {
        this.redirectDashboard();
      }
    },

    // Check if user has added email
    initView() {
      if (!this.user.email) this.$router.push({ path: "/sign-in" });

      this.email = this.user.email;
      this.companies = this.user.companies;
    },

    // Check if Zendesk domain exists
    zendeskDomain(company) {
      if (company.integrations.zendesk.credentials?.domainName) {
        return company.integrations.zendesk.credentials?.domainName;
      }
      return false;
    },

    // Event on workspace scroll
    workspaceScroll() {
      const scrollTop = this.$refs.workspaces.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Select the store to login
    async selectCompany(companyId) {
      // Check if the form has valid input
      this.showLoader = true;

      // Use the service to authenticate the user
      const data = {
        companyId,
        userId: this.user.userId,
        email: this.user.email,
      };
      const response = await AuthService.SelectCompany(data);

      if (response.hasError) {
        this.showLoader = false;
      } else if (!response.hasError) {
        this.redirectUser();
      }
    },

    async redirectUser() {
      // Get the logged in user and init the Vues store variables
      await this.getUser();
      setTimeout(() => {
        this.redirectDashboard();
      }, 1500);
    },
  },
};
</script>
    
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
@import "@/assets/styles/shared/authentication.scss";

.authentication-wrapper {
  position: relative;
  width: 40%;
  margin-top: 4rem;
  transform: none;
  left: auto;
  top: auto;

  .card {
    overflow: hidden;
  }
}

.logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.card-illustration {
  position: relative;
  left: auto;
  bottom: auto;
  height: auto;
  width: 45%;
  margin-top: -1.5rem;
  margin-bottom: -1rem;
}

.line-loader {
  position: absolute;
  bottom: 0;
  left: 0;
}

.workspace-item {
  @include transition;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  background-color: $lightWhiteColor;
  border: 1px solid darken($lightWhiteColor, 5%);
  margin-bottom: 1rem;

  .workspace-logo {
    margin-right: 1.5rem;

    img {
      display: block;
      width: 3rem;
      border-radius: 0.5rem;
      border: 1px solid darken($whiteColor, 10%);
    }
  }

  .workspace-info {
    flex: 1;
    text-align: left;

    h3 {
      font-size: $normalFontSize;
      margin-bottom: 0;
    }

    span {
      color: $greyColor;
      font-size: $smallFontSize;

      &.no-store {
        opacity: $lightOpacity;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: lighten($purpleColor, 40%);
    border: 1px solid lighten($purpleColor, 15%);
  }
}

/deep/ {
  .avatar-wrapper {
    background-color: $purpleColor;
    border: 1px solid lighten($purpleColor, 15%);
    border-radius: 0.5rem;

    span {
      color: lighten($purpleColor, 45%);
    }
  }

  .line-loader {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.workspaces-wrapper {
  max-height: 35vh;
  overflow: scroll;
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}

.intro-wrapper {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 2rem;
}
</style>