<template>
  <div class="key-metrics">
    <!--START: Instructions-->
    <div class="stat-item">
      <b class="stat">{{ getTotalCount("generateAgentAnswer") }}</b>
      <div class="stat-label">
        <span>Agent Answers</span>
        <div class="stat-info">
          <span>i</span>
          <p>Instructions given to the AI to create an answer</p>
        </div>
      </div>
    </div>
    <!--END: Instructions-->

    <!--START: AI Answers-->
    <div class="stat-item">
      <b class="stat">{{ getTotalCount("aiAnswers", true) }}</b>
      <div class="stat-label">
        <span>AI Answers</span>
        <div class="stat-info">
          <span>i</span>
          <p>Answers generated by the AI or using past tickets</p>
        </div>
      </div>
    </div>
    <!--END: AI Answers-->

    <!--START: Refined Answers-->
    <div class="stat-item">
      <b class="stat">{{ getTotalCount("answersRefined", true) }}</b>
      <div class="stat-label">
        <span>Answers Refined</span>
        <div class="stat-info">
          <span>i</span>
          <p>The number of answers that we refined</p>
        </div>
      </div>
    </div>
    <!--END: Refined Answers-->

    <!--START: Answers Used-->
    <div class="stat-item">
      <b class="stat">{{ getTotalCount("answersUsed", true) }}</b>
      <div class="stat-label">
        <span>Answers used</span>
        <div class="stat-info">
          <span>i</span>
          <p>The number of answers inserted or copied</p>
        </div>
      </div>
    </div>
    <!--END: Answers Used-->
  </div>
</template>

<script>
export default {
  name: "KeyMetrics",
  props: {
    events: { type: Array, required: true },
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  async created() {},
  methods: {
    // Get total count by event name
    getTotalCount(eventName, isCategory = false) {
      if (this.events) {
        let count = 0;

        if (!isCategory) {
          const event = this.events.find((i) => i.name === eventName);
          event?.agents.forEach((a) => (count += a.count));
        } else {
          let events = [];
          if (eventName === "answersUsed") {
            events = ["answerCopied", "answerInserted"];
          } else if (eventName === "aiAnswers") {
            events = ["generateAiAnswer", "generateTicketAnswer"];
          } else if (eventName === "answersRefined") {
            this.events.forEach((e) => {
              if (e.name.includes("refineAnswer")) {
                const index = events.findIndex((e) => e === e.name);
                if (index === -1) events.push(e.name);
              }
            });
          }

          events.forEach((eventName) => {
            const event = this.events.find((event) => event.name === eventName);
            event?.agents.forEach((a) => (count += a.count));
          });
        }

        return count;
      }

      return 0;
    },
  },
};
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.key-metrics {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.stat {
  margin-left: -0.5rem;
}
.stat-item {
  margin-left: 2.5rem;
  padding-left: 2.5rem;
  border-left: 1px solid $greyBorderColor;

  &:first-child {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }

  &:last-child {
    .stat-info p {
      left: auto;
      right: 0;
      transform: none;
    }
  }
}
</style>