require("dotenv").config();
import axios from "axios";

//Importing Variable mixins
const apiURL = `${process.env.VUE_APP_API_URL}/ai-assistant/ticket`;

class TicketService {
  // Get all tickets
  static GetTickets(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/all`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Get single tickets
  static GetTicket(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/get`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Get Zendesk tickets
  static GetZendeskTicket(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/zendesk-ticket/get`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }
}

export default TicketService;
