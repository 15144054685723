import Vue from "vue";
import VueRouter from "vue-router";

// START: Authentication Views
import Register from "@/views/Authentication/Register";
import Login from "@/views/Authentication/Login";
import Logout from "@/views/Authentication/Logout";
import StoreSelection from "@/views/Authentication/StoreSelection";
import AuthenticateLogin from "@/views/Authentication/AuthenticateLogin";
import SelectCompany from "@/views/Authentication/SelectCompany";
import Onboarding from "@/views/Authentication/Onboarding";
// END: Authentication Views

// START: Dashboard Views
import Dashboard from "@/views/Dashboard";

import AiAssistant from "@/views/AiAssistant/Index";
import AiAssistantAnswerRules from "@/views/AiAssistant/AnswerRules";
import AiAssistantPersonas from "@/views/AiAssistant/Personas";
import AiAssistantTickets from "@/views/AiAssistant/Tickets";
import AiAssistantSignature from "@/views/AiAssistant/Signature";
import AiAssistantConfiguration from "@/views/AiAssistant/Configuration";
import AiAssistantZendeskChannels from "@/views/AiAssistant/ZendeskChannels";

import AiAgent from "@/views/AiAgent/Index";
import Persona from "@/views/AiAgent/Persona";
import TicketFilterRules from "@/views/AiAgent/TicketFilterRules";
import AiAgentSettings from "@/views/AiAgent/Settings";
import AiAgentTickets from "@/views/AiAgent/Tickets";
// import AiAgentAnswerRules from "@/views/AiAgent/AnswerRules";

import KnowledgeBase from "@/views/KnowledgeBase/Index";
import ViewArticles from "@/views/KnowledgeBase/Articles/ViewArticles";
import AddArticle from "@/views/KnowledgeBase/Articles/AddArticle";
import Sources from "@/views/KnowledgeBase/Sources/Sources";
import Documents from "@/views/KnowledgeBase/Sources/Documents";

import Topics from "@/views/AiAgent/Topics/Topics";
import Intents from "@/views/AiAgent/Topics/Intents";
import ViewTopic from "@/views/AiAgent/Topics/ViewTopic";

import Analytics from "@/views/Analytics/Index";
import Insights from "@/views/Analytics/Insights";

import Settings from "@/views/Settings/Index";
import Integrations from "@/views/Settings/Integrations";
import UserAccess from "@/views/Settings/UserAccess";
import Subscriptions from "@/views/Settings/Subscriptions";

import ZendeskViews from "@/views/Misc/Zendesk/Index";
import ZendeskSuccess from "@/views/Misc/Zendesk/Success";
import ZendeskError from "@/views/Misc/Zendesk/Error";
import ZendeskInstallation from "@/views/Misc/Zendesk/Installation";
import ZendeskWidgetTutorial from "@/views/Misc/Zendesk/WidgetTutorial";

// END: Dashboard Views

Vue.use(VueRouter);

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/sign-in",
      meta: { authRoute: true },
    },
    {
      path: "/sign-in",
      name: "Login",
      component: Login,
      meta: { authRoute: true },
    },
    {
      path: "/logout",
      name: "Logout",
      component: Logout,
      meta: { authRoute: true },
    },
    {
      path: "/store-selection",
      name: "StoreSelection",
      component: StoreSelection,
      meta: {
        hideHeader: true,
      },
    },
    {
      path: "/authenticate-login",
      name: "AuthenticateLogin",
      component: AuthenticateLogin,
      meta: { authRoute: true },
    },
    {
      path: "/select-company",
      name: "SelectCompany",
      component: SelectCompany,
      meta: { authRoute: true },
    },
    {
      path: "/sign-up",
      name: "Register",
      component: Register,
      meta: { authRoute: true },
    },

    {
      path: "/onboarding",
      name: "Onboarding",
      component: Onboarding,
      meta: { authRoute: true },
    },

    {
      path: "/",
      name: "Dashboard",
      component: Dashboard,
      redirect: "/ai-assistant/tickets",
      children: [
        {
          path: "ai-assistant",
          name: "AI Assistant",
          component: AiAssistant,
          redirect: "ai-assistant/tickets",
          children: [
            {
              path: "tickets",
              name: "AI Assistant Tickets",
              component: AiAssistantTickets,
            },
            {
              path: "personas",
              name: "AI Personas",
              component: AiAssistantPersonas,
            },
            {
              path: "answer-rules",
              name: "Answer Rules",
              component: AiAssistantAnswerRules,
            },
            {
              path: "signature",
              name: "AI Assistant Signature",
              component: AiAssistantSignature,
            },
            {
              path: "configuration",
              name: "AI Assistant Configuration",
              component: AiAssistantConfiguration,
            },
            {
              path: "zendesk-channels",
              name: "AI Assistant Zendesk Channels",
              component: AiAssistantZendeskChannels,
            },
          ],
        },
        {
          path: "ai-agent",
          name: "AI Agent",
          component: AiAgent,
          redirect: "ai-agent/tickets",
          children: [
            {
              path: "tickets",
              name: "AI Agent Tickets",
              component: AiAgentTickets,
            },
            {
              path: "persona",
              name: "AI Personas",
              component: Persona,
            },
            {
              path: "answer-rules",
              name: "Answer Rules",
              component: AiAssistantAnswerRules,
            },
            {
              path: "topics",
              name: "Topics",
              component: Topics,
            },
            {
              path: "topics/view/:topicId?",
              name: "Topic Intents",
              component: ViewTopic,
            },
            {
              path: "topics/intents/:topicId",
              name: "Topic Intents",
              component: Intents,
            },

            {
              path: "filter-rules",
              name: "Ticket Filter Rules",
              component: TicketFilterRules,
            },

            {
              path: "settings",
              name: "AI Agent Settings",
              component: AiAgentSettings,
            },
          ],
        },

        {
          path: "knowledge-base",
          name: "Knowledge Base",
          component: KnowledgeBase,
          redirect: "knowledge-base/sources",
          children: [
            {
              path: "articles",
              name: "View Articles",
              component: ViewArticles,
            },
            {
              path: "articles/add/:referenceId?",
              name: "Add Article",
              component: AddArticle,
            },
            {
              path: "sources",
              name: "Sources",
              component: Sources,
            },
            {
              path: "sources/:sourceId",
              name: "Source Documents",
              component: Documents,
            },
          ],
        },
        {
          path: "analytics",
          name: "Analytics",
          component: Analytics,
          redirect: "analytics/insights",
          children: [
            {
              path: "insights",
              name: "Insights",
              component: Insights,
            },
          ],
        },
        {
          path: "settings",
          name: "Settings",
          component: Settings,
          redirect: "settings/user-access",
          children: [
            {
              path: "integrations",
              name: "Integrations",
              component: Integrations,
            },
            {
              path: "user-access",
              name: "UserAccess",
              component: UserAccess,
            },
            {
              path: "subscriptions",
              name: "Subscriptions",
              component: Subscriptions,
            },
          ],
        },
      ],
    },

    {
      path: "/settings/zendesk",
      name: "ZendeskViews",
      component: ZendeskViews,
      meta: { hideHeader: true },
      children: [
        {
          path: "oauth/success",
          name: "ZendeskSuccess",
          component: ZendeskSuccess,
          meta: { hideHeader: true },
        },
        {
          path: "oauth/error",
          name: "Error",
          component: ZendeskError,
          meta: { hideHeader: true },
        },
        {
          path: "install-widget",
          name: "InstallWidget",
          component: ZendeskInstallation,
          meta: { hideHeader: true },
        },

        {
          path: "widget-tutorial",
          name: "WidgetTutorial",
          component: ZendeskWidgetTutorial,
          meta: { hideHeader: true },
        },
      ],
    },

    {
      path: "*",
      beforeEnter() {
        location.href =
          process.env.VUE_APP_MODE == "development"
            ? "http://localhost:8080"
            : "https://helpdesk.getmacha.com";
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
  },
});
