<template>
  <div v-if="isTrial" class="banner-wrapper" :class="{ 'free-plan': isTrial }">
    <span>
      Thanks for trying Macha. You have
      <b
        >{{ this.trialDaysLeft }}
        {{ this.trialDaysLeft == 1 ? "day" : "days" }} remaining</b
      >. You can activate a
      <router-link to="/settings/subscriptions">plan here.</router-link></span
    >
  </div>

  <div v-else-if="!isSubscribed" class="banner-wrapper">
    <span
      >You don't have an active subscription. Activate your
      <router-link to="/settings/subscriptions">plan here.</router-link>
    </span>
  </div>
</template>
  
<script>
export default {
  name: "SubscriptionBanner",
  computed: {
    // Get the company
    company() {
      return this.$store.getters.company;
    },

    // Get the current subscription
    subscription() {
      return this.company?.products.aiAssistant.subscription;
    },

    // Check if it's a trisl
    isTrial() {
      if (this.trialDaysLeft > 0 && this.subscription.planType === "trial")
        return true;
      return false;
    },

    // Check if the customer is subscribed
    isSubscribed() {
      if (
        this.subscription.status === "active" &&
        this.subscription.planType != "trial"
      )
        return true;
      return false;
    },

    // Check if it's a free plan with answers left
    trialDaysLeft() {
      if (this.subscription.planType === "trial") {
        const { trial } = this.subscription;
        const trialStartDate = trial.startDate || this.company.createdAt;

        const endDate = this.moment(trialStartDate).add(trial.duration, "days");
        const today = this.moment().startOf("day");

        return endDate.diff(today, "days");
      }

      return 0;
    },
  },
  data() {
    return {
      freePlanLimit: 50,
    };
  },
};
</script>
  
<style scoped lang="scss">
.banner-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 98;
  width: 100%;
  padding: 0.5rem 0;
  background-color: $redColor;
  text-align: center;

  span {
    font-size: $smallerFontSize;
    color: $whiteColor;
  }

  a {
    color: $whiteColor;
    font-weight: $boldFontWeight;
    text-decoration: underline;
  }

  &.free-plan {
    background-color: $greenMessageBorderColor;

    span,
    a {
      color: darken($greenMessageTextColor, 10%);
    }
  }
}
</style>