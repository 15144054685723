import { render, staticRenderFns } from "./ViewPersona.vue?vue&type=template&id=95256c76&scoped=true"
import script from "./ViewPersona.vue?vue&type=script&lang=js"
export * from "./ViewPersona.vue?vue&type=script&lang=js"
import style0 from "./ViewPersona.vue?vue&type=style&index=0&id=95256c76&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95256c76",
  null
  
)

export default component.exports