export const LANGUAGES = {
  en: {
    widget: {
      SSP: {
        heading: "Hey there 👋",
        description: "Any questions? We're here to help",
        suggestedQuestion: {
          title: "Common Questions",
        },
        inputQuestion: {
          title: "Ask us a question",
          placeholder: "Type your message...",
          continueButton: "Continue Chat",
        },
        orderTracking: {
          title: "Track My Order",
          description: "Check the status of my order",
        },
        cancelOrder: {
          title: "Manage Orders",
          description: "Get order status, raise cancellation requests",
        },
      },
      global: {
        goBackButton: "Go Back",
      },
      orderManagement: {
        title: "Manage your orders",
        requestCancellationButton: "Request Cancellation",
        cancellationClosed: "Not eligible",
        submitButton: "Submit cancellation request",
        productsTitle: "Select products",
        cancelConfirmationTitle: "Reason for cancellation request",
        requestSubmitted: {
          title: "Your cancellation request has been submitted",
          description:
            "Our team will review your request and will reach out to you for further details",
        },
      },
      chat: {
        header: {
          userStatus: "Online - AI Expert",
        },
        liveChatNotification: {
          newMessage: "New live chat message",
        },
        messages: {
          sentLabel: "Sent at ",
          aiLabel: "Answered by AI",
        },
        input: {
          title: "Ask me something...",
        },
        aiAgent: {
          title: "Connected to AI support",
          description: "Human support is available as well",
        },
        buttons: {
          newChat: "Start New Chat",
          endChat: "👍 Yes, thank you",
          continueChat: "No, I need help",
          handoffChat: "👋 Talk to human",
          backToChat: "Continue Chat",
        },
        ticketDetails: {
          title: "Your ticket has been raised",
          ticketIdLabel: "Ticket ID",
          summaryLabel: "Summary",
          messageLabel: "Message",
          whatsapp: {
            title: "Chat on WhatsApp",
            description:
              "Get in touch with our agents for assistance, if they're online",
          },
          actions: {
            emailLabel: "We've sent a copy of this ticket to",
            newChatButton: "Start new chat with AI",
          },
        },
        customerForm: {
          title: "Before we start",
          description: "We'll respond to you on the details provided",
          secureData: "Your information is private, secure and encrypted",
          startButton: "Start Chat",
          disclaimer:
            "By providing the requested details, you acknowledge and agree to abide by the site's terms and conditions.",
          fields: {
            name: "Name",
            email: "E-mail*",
            number: "Phone Number",
          },
        },
        ticketRequestForm: {
          title: "Ticket details",
          description: "We'll reach out to you on the details you provide",
          fields: {
            name: "Name",
            email: "E-mail*",
            number: "Phone Number",
            messagePlaceholder: "Type your question...",
            messageTitle: "Your comments*",
          },
          buttons: {
            raiseTicket: "Raise Ticket",
            continueChat: "Continue Chatting",
          },
        },
        handoffForm: {
          title: "Contact details",
          description: "We need a few details incase we get disconnected",
          fields: {
            name: "Name",
            email: "E-mail*",
            number: "Phone Number",
          },
          buttons: {
            startChat: "Start Chat",
            continueChat: "Dismiss",
          },
        },
        ticketOption: {
          buttons: {
            handoffMessage: "👋 Talk to human",
            supportMessage: "👋 Speak to human support",
            raiseTicket: "Raise a ticket",
          },
        },
        error: {
          title: "Holy Glitch!",
          description:
            "Our servers hiccuped. Please restart the chat to continue.",
          buttons: { restartChat: "Restart Chat" },
        },
        resolvedChat: {
          completedLabel: "Chat Completed",
          buttons: {
            newChat: "Start new chat",
          },
        },
        resolutionHandler: {
          buttons: {
            successMessage: "👍 Yes, thank you",
            errorMessage: "No, I need help",
            ticketMessage: "No, I still want to raise a ticket",
          },
        },
        messageInput: {
          typingLabel:
            "👋 Looks like you're still typing. Hit send, so I can answer.",
          fields: {
            question: "Ask me something...",
          },
        },
        chatCompleted: "Chat Completed",
        orderTracking: {
          cardTitle: "Order details",
          fields: {
            name: "Order Number*",
            email: "E-mail",
            phone: "Enter your phone",
          },
          optionsLabel: "Select an option you used for your order",
          buttons: {
            email: "Use e-mail",
            phone: "Use phone",
            getOrder: "Get Order",
            continueChat: "Continue Chatting",
          },
        },
        orderDetails: {
          paymentLabel: "Payment",
          shipmentLabel: "Shipment",
          trackButton: "Track Order",
          pendingShipmentLabel: "Pending Shipment",
          refundedProductsLabel: "Refunded Products",
          logisticsPartnerLabel: "Logistics Partner",
          referencesProductsLabel: "Reference Products",
          referencesArticlesLabel: "Reference Articles",
          shipmentStatus: {
            label_printed: {
              title: "Label printed",
              description:
                "A label for the shipment was purchased and printed.",
            },
            label_purchased: {
              title: "Label purchased",
              description:
                "A label for the shipment was purchased, but not printed.",
            },
            attempted_delivery: {
              title: "Attempted delivery",
              description:
                "Delivery of the shipment was attempted, but unable to be completed.",
            },
            ready_for_pickup: {
              title: "Ready for pickup",
              description:
                "The shipment is ready for pickup at a shipping depot.",
            },
            confirmed: {
              title: "Confirmed",
              description:
                "The carrier is aware of the shipment, but hasn't received it yet.",
            },
            in_transit: {
              title: "In transit",
              description:
                "The shipment is being transported between shipping facilities on the way to its destination.",
            },
            out_for_delivery: {
              title: "Out for delivery",
              description:
                "The shipment is being delivered to its final destination.",
            },
            delivered: {
              title: "Delivered",
              description: "The shipment was succesfully delivered.",
            },
            failure: {
              title: "Failure",
              description:
                "Something went wrong when pulling tracking information for the shipment.",
            },
            refund: {
              title: "Refunds",
              description: "These items have been requested to be refunded",
            },
          },
          fulfillmentStatus: {
            processing: {
              title: "Processing Order",
              description:
                "Your order is being processed. We'll notify you once it's shipped",
            },
            pending: {
              title: "Shipment pending",
              description:
                "Shipping scheduled. Waiting for package to be picked up by logistics partner",
            },
            open: {
              title: "Shipment open",
              description:
                "The package has been acknowledged by logistics partner and is in processing.",
            },
            success: {
              title: "Order Confirmed",
              description: "You can track your order via the link.",
            },
            cancelled: {
              title: "Shipment cancelled",
              description: "The shipment was cancelled",
            },
            error: {
              title: "Shipment error",
              description: "There was an error with the shipment",
            },
            failure: {
              title: "Shipment failed",
              description:
                "The shipment failed to get processed by logisitics partner",
            },
            refund: {
              title: "Refunds",
              description: "These items have been requested to be refunded",
            },
          },
        },
        aiAnimation: {
          understanding: "Understanding question",
          writing: "Writing a response",
        },
        systemMessages: {
          IS_RESOLVED: "Did this resolve your question?",
          RAISE_TICKET: "Raise a ticket",
        },
      },
    },
  },
  nl: {
    widget: {
      SSP: {
        heading: "Hallo daar 👋",
        description: "Heb je vragen? We zijn hier om te helpen",
        suggestedQuestion: {
          title: "Veelgestelde vragen",
        },
        inputQuestion: {
          title: "Stel ons een vraag",
          placeholder: "Typ je bericht...",
          continueButton: "Ga verder met chatten",
        },
        orderTracking: {
          title: "Mijn bestelling volgen",
          description: "Controleer de status van mijn bestelling",
        },
        cancelOrder: {
          title: "Bestelling Annuleren",
          description: "Dien een annuleringsverzoek in",
        },
      },
      global: {
        goBackButton: "Go Back",
      },
      orderManagement: {
        title: "Manage Orders",
        requestCancellationButton: "Request Cancellation",
        cancellationClosed: "Not eligible",
        submitButton: "Submit cancellation request",
        productsTitle: "Select products",
        cancelConfirmationTitle: "Reason for cancellation request",
        requestSubmitted: {
          title: "Your cancellation request has been submitted",
          description:
            "Our team will review your request and will reach out to you for further details",
        },
      },
      chat: {
        header: {
          userStatus: "Online - AI Expert",
        },
        liveChatNotification: {
          newMessage: "Nieuw live chatbericht",
        },
        messages: {
          sentLabel: "Verzonden op ",
          aiLabel: "Beantwoord door AI",
        },
        input: {
          title: "Vraag me iets...",
        },
        aiAgent: {
          title: "Verbonden met AI-ondersteuning",
          description: "Menselijke ondersteuning is ook beschikbaar",
        },
        buttons: {
          newChat: "Start nieuwe chat",
          endChat: "👍 Ja, bedankt",
          continueChat: "Nee, ga verder",
          handoffChat: "👋 Praat met een mens",
          backToChat: "Ga verder met chatten",
        },
        ticketDetails: {
          title: "Uw ticket is ingediend",
          ticketIdLabel: "Ticket ID",
          summaryLabel: "Samenvatting",
          messageLabel: "Bericht",
          whatsapp: {
            title: "Chat op WhatsApp",
            description:
              "Neem contact op met onze medewerkers voor hulp als ze online zijn",
          },
          actions: {
            emailLabel: "We hebben een kopie van dit ticket gestuurd naar",
            newChatButton: "Start nieuwe chat met AI",
          },
        },
        customerForm: {
          title: "Voordat we beginnen",
          description: "We zullen op de opgegeven gegevens reageren",
          secureData: "Uw informatie is privé, veilig en versleuteld",
          startButton: "Start chat",
          disclaimer:
            "Door de gevraagde gegevens te verstrekken, erkent en stemt u in met het naleven van de voorwaarden van de site.",
          fields: {
            name: "Naam",
            email: "E-mail*",
            number: "Telefoonnummer",
          },
        },
        ticketRequestForm: {
          title: "Ticketdetails",
          description: "We nemen contact met u op via de verstrekte gegevens",
          fields: {
            name: "Naam",
            email: "E-mail*",
            number: "Telefoonnummer",
            messagePlaceholder: "Typ uw vraag...",
            messageTitle: "Uw opmerkingen*",
          },
          buttons: {
            raiseTicket: "Ticket indienen",
            continueChat: "Doorgaan met chatten",
          },
        },
        handoffForm: {
          title: "Contactgegevens",
          description:
            "We hebben een paar gegevens nodig voor het geval we worden verbroken",
          fields: {
            name: "Naam",
            email: "E-mail*",
            number: "Telefoonnummer",
          },
          buttons: {
            startChat: "Start chat",
            continueChat: "Afwijzen",
          },
        },
        ticketOption: {
          buttons: {
            handoffMessage: "👋 Praat met een mens",
            supportMessage: "👋 Praat met menselijke ondersteuning",
            raiseTicket: "Dien een ticket in",
          },
        },
        error: {
          title: "Heilige Glitch!",
          description:
            "Onze servers haperden. Start de chat opnieuw om door te gaan.",
          buttons: { restartChat: "Herstart de chat" },
        },
        resolvedChat: {
          completedLabel: "Chat voltooid",
          buttons: {
            newChat: "Start nieuwe chat",
          },
        },
        resolutionHandler: {
          buttons: {
            successMessage: "👍 Ja, bedankt",
            errorMessage: "Nee, ga verder",
            ticketMessage: "Nee, ik wil nog steeds een ticket indienen",
          },
        },
        messageInput: {
          typingLabel:
            "👋 Het lijkt erop dat je nog steeds typt. Druk op verzenden, zodat ik kan antwoorden.",
          fields: {
            question: "Vraag me iets...",
          },
        },
        chatCompleted: "Chat voltooid",
        orderTracking: {
          cardTitle: "Bestelgegevens",
          fields: {
            name: "Ordernummer*",
            email: "E-mail*",
            phone: "Voer uw telefoonnummer in",
          },
          optionsLabel:
            "Selecteer een optie die u heeft gebruikt voor uw bestelling",
          buttons: {
            email: "Gebruik e-mail",
            phone: "Gebruik telefoon",
            getOrder: "Ontvang bestelling",
            continueChat: "Doorgaan met chatten",
          },
        },
        orderDetails: {
          paymentLabel: "Betaling",
          shipmentLabel: "Verzending",
          trackButton: "Bestelling volgen",
          pendingShipmentLabel: "In afwachting van verzending",
          refundedProductsLabel: "Terugbetaalde producten",
          logisticsPartnerLabel: "Logistieke partner",
          referencesProductsLabel: "Referentieproducten",
          referencesArticlesLabel: "Referentie-artikelen",
          shipmentStatus: {
            label_printed: {
              title: "Label afgedrukt",
              description:
                "Een label voor de zending is aangeschaft en afgedrukt.",
            },
            label_purchased: {
              title: "Label aangeschaft",
              description:
                "Een label voor de zending is aangeschaft, maar niet afgedrukt.",
            },
            attempted_delivery: {
              title: "Poging tot levering",
              description:
                "Er is geprobeerd de zending te bezorgen, maar dit is niet gelukt.",
            },
            ready_for_pickup: {
              title: "Klaar voor ophalen",
              description:
                "De zending staat klaar voor ophalen bij een verzenddepot.",
            },
            confirmed: {
              title: "Bevestigd",
              description:
                "De vervoerder is op de hoogte van de zending, maar heeft deze nog niet ontvangen.",
            },
            in_transit: {
              title: "Onderweg",
              description:
                "De zending wordt vervoerd tussen verzendfaciliteiten op weg naar de bestemming.",
            },
            out_for_delivery: {
              title: "Onderweg voor bezorging",
              description:
                "De zending wordt bezorgd op weg naar de eindbestemming.",
            },
            delivered: {
              title: "Bezorgd",
              description: "De zending is succesvol bezorgd.",
            },
            failure: {
              title: "Fout",
              description:
                "Er is iets fout gegaan bij het ophalen van trackinginformatie voor de zending.",
            },
            refund: {
              title: "Terugbetalingen",
              description: "Voor deze items is om terugbetaling gevraagd.",
            },
          },
          fulfillmentStatus: {
            processing: {
              title: "Order wordt verwerkt",
              description:
                "Je bestelling wordt verwerkt. We laten je weten zodra het is verzonden.",
            },
            pending: {
              title: "Zending in afwachting",
              description:
                "Verzending gepland. Wachten tot het pakket wordt opgehaald door de logistieke partner.",
            },
            open: {
              title: "Zending open",
              description:
                "Het pakket is erkend door de logistieke partner en bevindt zich in verwerking.",
            },
            success: {
              title: "Bestelling bevestigd",
              description: "Je kunt je bestelling volgen via de link.",
            },
            cancelled: {
              title: "Zending geannuleerd",
              description: "De zending is geannuleerd.",
            },
            error: {
              title: "Fout bij zending",
              description: "Er is een fout opgetreden bij de zending.",
            },
            failure: {
              title: "Zending mislukt",
              description:
                "De zending is niet verwerkt door de logistieke partner.",
            },
            refund: {
              title: "Terugbetalingen",
              description: "Voor deze items is om terugbetaling gevraagd.",
            },
          },
        },
        aiAnimation: {
          understanding: "Begrijpende vraag",
          writing: "Een antwoord schrijven",
        },
        systemMessages: {
          IS_RESOLVED: "Is dit jouw vraag opgelost?",
          RAISE_TICKET: "Dien een ticket in",
        },
      },
    },
  },
  he: {
    widget: {
      SSP: {
        heading: "שלום 👋",
        description: "יש לך שאלות? אנחנו כאן כדי לעזור",
        suggestedQuestion: {
          title: "שאלות נפוצות",
        },
        inputQuestion: {
          title: "שאל לנו שאלה",
          placeholder: "הקלד את ההודעה שלך...",
          continueButton: "המשך לשוחח",
        },
        orderTracking: {
          title: "מעקב אחר הזמנה",
          description: "בדוק את מצב ההזמנה שלי",
        },
        cancelOrder: {
          title: "ביטול הזמנה",
          description: "הגש בקשת ביטול",
        },
      },
      global: {
        goBackButton: "Go Back",
      },
      orderManagement: {
        title: "Manage Orders",
        requestCancellationButton: "Request Cancellation",
        cancellationClosed: "Not eligible",
        submitButton: "Submit cancellation request",
        productsTitle: "Select products",
        cancelConfirmationTitle: "Reason for cancellation request",
        requestSubmitted: {
          title: "Your cancellation request has been submitted",
          description:
            "Our team will review your request and will reach out to you for further details",
        },
      },
      chat: {
        header: {
          userStatus: "מקוון - מומחה באמצעות AI",
        },
        liveChatNotification: {
          newMessage: "הודעת צ'אט חיה חדשה",
        },
        messages: {
          sentLabel: "נשלח ב-",
          aiLabel: "תשובה מצד AI",
        },
        input: {
          title: "שאל אותי משהו...",
        },
        aiAgent: {
          title: "מחובר לתמיכת AI",
          description: "תמיכה אנושית זמינה גם כן",
        },
        buttons: {
          newChat: "התחל צ'אט חדש",
          endChat: "👍 כן, תודה",
          continueChat: "לא, המשך לשוחח",
          handoffChat: "👋 דבר עם אדם",
          backToChat: "המשך לשוחח",
        },
        ticketDetails: {
          title: "הכרטיס שלך הוגש",
          ticketIdLabel: "מזהה כרטיס",
          summaryLabel: "סיכום",
          messageLabel: "הודעה",
          whatsapp: {
            title: "צ'אט ב-WhatsApp",
            description: "היכנס לקשר עם נציגינו לעזרה, אם הם מקוונים",
          },
          actions: {
            emailLabel: "שלחנו עותק של הכרטיס הזה ל",
            newChatButton: "התחל צ'אט חדש עם AI",
          },
        },
        customerForm: {
          title: "לפני שנתחיל",
          description: "נגיב לך על פי הפרטים שסיפקת",
          secureData: "המידע שלך הוא פרטי, מאובטח ומוצפן",
          startButton: "התחל צ'אט",
          disclaimer:
            "בספקך את הפרטים הנדרשים, אתה מכיר ומסכים להפעיל לפי תנאי השימוש של האתר.",
          fields: {
            name: "שם",
            email: "דואר אלקטרוני*",
            number: "מספר טלפון",
          },
        },
        ticketRequestForm: {
          title: "פרטי הכרטיס",
          description: "ניצור איתך קשר לפי הפרטים שתספק",
          fields: {
            name: "שם",
            email: "דואר אלקטרוני*",
            number: "מספר טלפון",
            messagePlaceholder: "הקלד את השאלה שלך...",
            messageTitle: "הערות שלך*",
          },
          buttons: {
            raiseTicket: "הגש כרטיס",
            continueChat: "המשך לשוחח",
          },
        },
        handoffForm: {
          title: "פרטי יצירת קשר",
          description: "אנו צריכים מספר פרטים אם יתנתק חיבור",
          fields: {
            name: "שם",
            email: "דואר אלקטרוני*",
            number: "מספר טלפון",
          },
          buttons: {
            startChat: "התחל צ'אט",
            continueChat: "דחה",
          },
        },
        ticketOption: {
          buttons: {
            handoffMessage: "👋 דבר עם אדם",
            supportMessage: "👋 דבר עם תמיכה אנושית",
            raiseTicket: "הגש כרטיס",
          },
        },
        error: {
          title: "תקלה קדושה!",
          description: "השרתים שלנו התקלקלו. אנא אתחל את הצ'אט כדי להמשיך.",
          buttons: { restartChat: "אתחל צ'אט" },
        },
        resolvedChat: {
          completedLabel: "צ'אט הושלם",
          buttons: {
            newChat: "התחל צ'אט חדש",
          },
        },
        resolutionHandler: {
          buttons: {
            successMessage: "👍 כן, תודה",
            errorMessage: "לא, המשך לשוחח",
            ticketMessage: "לא, אני עדיין רוצה להגיש כרטיס",
          },
        },
        messageInput: {
          typingLabel: "👋 נראה שאתה עדיין מקליד. לחץ על שלח כדי שאוכל לעזור.",
          fields: {
            question: "שאל אותי משהו...",
          },
        },
        chatCompleted: "צ'אט הושלם",
        orderTracking: {
          cardTitle: "פרטי הזמנה",
          fields: {
            name: "מספר הזמנה*",
            email: "דואר אלקטרוני",
            phone: "הזן את מספר הטלפון שלך",
          },
          optionsLabel: "בחר באפשרות שהשתמשת בה בהזמנתך",
          buttons: {
            email: "השתמש בדואר אלקטרוני",
            phone: "השתמש בטלפון",
            getOrder: "קבל הזמנה",
            continueChat: "המשך לשוחח",
          },
        },
        orderDetails: {
          paymentLabel: "תשלום",
          shipmentLabel: "משלוח",
          trackButton: "מעקב אחר הזמנה",
          pendingShipmentLabel: "משלוח ממתין",
          refundedProductsLabel: "מוצרים מזוכים",
          logisticsPartnerLabel: "שותף לוגיסטי",
          referencesProductsLabel: "מוצרים לפי קישור",
          referencesArticlesLabel: "מאמרים לפי קישור",
          shipmentStatus: {
            label_printed: {
              title: "התווית נדפסה",
              description: "נרכשה והודפסה תווית למשלוח.",
            },
            label_purchased: {
              title: "תווית נרכשה",
              description: "נרכשה תווית למשלוח, אך לא הודפסה.",
            },
            attempted_delivery: {
              title: "ניסיון למסירה",
              description: "ניסיון למסירת המשלוח, אך לא ניתן היה להשלימו.",
            },
            ready_for_pickup: {
              title: "מוכן לאיסוף",
              description: "המשלוח מוכן לאיסוף בסניף משלוחים.",
            },
            confirmed: {
              title: "מאושר",
              description: "המוביל מודע למשלוח, אך טרם קיבל אותו.",
            },
            in_transit: {
              title: "בדרך",
              description: "המשלוח בדרכו בין מתקני משלוח ליעדו.",
            },
            out_for_delivery: {
              title: "בדרך למסירה",
              description: "המשלוח בדרכו לנקודת המסירה הסופית.",
            },
            delivered: {
              title: "נמסר",
              description: "המשלוח נמסר בהצלחה.",
            },
            failure: {
              title: "תקלה",
              description: "משהו השתבש בעת גיוס מידע מעקב עבור המשלוח.",
            },
            refund: {
              title: "החזר כספי",
              description: "הוזמנו החזרים על פי בקשה",
            },
          },
          fulfillmentStatus: {
            processing: {
              title: "מעבד הזמנה",
              description: "הזמנתך בתהליך עיבוד. נודיע לך כשהיא תישלח",
            },
            pending: {
              title: "משלוח בהמתנה",
              description:
                "מתוכנן משלוח. ממתין לאיסוף החבילה על ידי שותף לוגיסטי",
            },
            open: {
              title: "משלוח פתוח",
              description: "החבילה הוכרזה על ידי שותף לוגיסטי ונמצאת בתהליך.",
            },
            success: {
              title: "הזמנה מאושרת",
              description: "ניתן למעקב אחר הזמנה באמצעות הקישור.",
            },
            cancelled: {
              title: "משלוח בוטל",
              description: "המשלוח בוטל",
            },
            error: {
              title: "שגיאת משלוח",
              description: "הייתה שגיאה במשלוח",
            },
            failure: {
              title: "המשלוח נכשל",
              description: "המשלוח נכשל בעיבוד על ידי שותף לוגיסטיקה",
            },
            refund: {
              title: "החזר כספי",
              description: "התבצעה בקשה להחזר על אלה הפריטים",
            },
          },
        },
        aiAnimation: {
          understanding: "הבנת שאלה",
          writing: "כתיבת תגובה",
        },
        systemMessages: {
          IS_RESOLVED: "האם זה פתר את השאלה שלך?",
          RAISE_TICKET: "הגש כרטיס",
        },
      },
    },
  },
};

export default {
  data() {
    return {
      languages: LANGUAGES,
    };
  },
};
