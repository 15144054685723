<template>
  <div class="data-table">
    <!--START: Documents List-->
    <ul class="grid-table">
      <li
        class="grid-row grid-header"
        :class="{ 'grid-header-shadow': showOptions.headerShadow }"
      >
        <span class="grid-column">Type</span>
        <span class="grid-column">Name</span>
        <span class="grid-column">Source URL</span>
        <span class="grid-column">Status</span>
        <span class="grid-column">Last Updated</span>
        <span class="grid-column">Update Frequency</span>
      </li>
    </ul>
    <ul
      class="grid-table grid-item-clickable"
      ref="table"
      @scroll="tableScroll"
    >
      <li
        v-for="(s, index) in sources"
        :key="index"
        class="grid-row"
        @click="gotoItem(s._id)"
      >
        <div class="grid-column">
          <span class="source-type">
            <img :src="getLogoImage(s.sourceType)" alt="Logo" />
            {{ getSourceType(s.sourceType) }}</span
          >
        </div>

        <div class="grid-column">
          <h3 class="name">{{ s.name }}</h3>
        </div>

        <div class="grid-column">
          <span class="url">{{ getSourceUrl(s) }}</span>
        </div>

        <div class="grid-column">
          <span class="status" :class="{ inactive: !s.isActive }">{{
            s.isActive ? "Active" : "Inactive"
          }}</span>
        </div>

        <div class="grid-column">
          <span class="updated-date">{{ getUpdatedDate(s.updatedAt) }}</span>
        </div>

        <div class="grid-column">
          <span class="updated-date">{{ s.updateFrequency }}</span>
        </div>

        <div class="grid-column grid-actions">
          <button
            class="btn btn-icon btn-delete"
            @click.stop="showDeleteModal(s._id, s.name)"
          >
            <unicon name="trash-alt"></unicon>
          </button>
          <button class="btn btn-icon btn-small">
            <span>View</span>
            <unicon name="angle-right-b"></unicon>
          </button>
        </div>
      </li>
    </ul>
    <!--END: Documents List-->

    <!--START: Documents Count-->
    <span class="grid-count"
      >Showing {{ sources.length }}
      {{ sources.length == 1 ? "source" : "sources" }}</span
    >
    <!--END: Documents Count-->

    <!--START: Confirmation Modal-->
    <ConfirmationModal
      :fields="confirmationFields"
      @primaryEvent="deleteItem"
      @secondaryEvent="closeModal"
    ></ConfirmationModal>

    <div
      class="bg-overlay light"
      :class="{ show: confirmationFields.show }"
      @click="closeModal"
    ></div>
    <!--END: Confirmation Modal-->
  </div>
</template>
          
<script>
// Importing Components
import ConfirmationModal from "@/components/modals/ConfirmationModal";

// Import functions
import { getUpdatedDate } from "@/utils/datetime.utils";

export default {
  name: "SourcesTable",
  props: {
    sources: Array,
  },
  data() {
    return {
      showOptions: {
        headerShadow: false,
      },
      deleteId: null,
      confirmationFields: {
        show: false,
        title: "",
        type: "delete",
        modalType: "fixed",
        message:
          "This action cannot be undone. All documents for this source will be deleted.",
        iconType: "exclamation",
        confirmAction: true,
        confirmValue: null,
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
    };
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  components: {
    ConfirmationModal,
  },
  methods: {
    getUpdatedDate,

    // Event on table scroll
    tableScroll() {
      const scrollTop = this.$refs.table.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Get source name
    getSourceType(sourceType) {
      let source = null;
      switch (sourceType) {
        case "shopify-products":
          source = "Shopify Products";
          break;
        case "zendesk-hc":
        case "zendesk-hc-api":
          source = "Zendesk Helpcenter";
          break;
        case "zendesk-macro":
          source = "Zendesk Macros";
          break;
        case "zendesk-tickets":
          source = "Zendesk Tickets";
          break;
        case "notion":
          source = "Notion";
          break;
        case "external-website":
          source = "External Website";
          break;
      }

      return source;
    },

    // Get logo of source type
    getLogoImage(sourceType) {
      let icon = null;
      switch (sourceType) {
        case "shopify-products":
          icon = "shopify.jpg";
          break;
        case "zendesk-hc":
        case "zendesk-macro":
        case "zendesk-hc-api":
        case "zendesk-tickets":
          icon = "zendesk.jpg";
          break;
        case "notion":
          icon = "notion.jpg";
          break;
        case "external-website":
          icon = "external-website.jpg";
          break;
      }

      let iconURL = (this.imageSrc = `/assets/images/logos/${icon}`);
      return iconURL;
    },

    // Get source URL
    getSourceUrl(source) {
      const zendeskSources = [
        "zendesk-macro",
        "zendesk-hc-api",
        "zendesk-tickets",
      ];
      if (zendeskSources.includes(source.sourceType)) {
        return this.company.integrations?.zendesk?.credentials?.domainName;
      } else if (source.sourceType === "notion") {
        return "Notion documents";
      } else return source.url;
    },

    // Goto view a particular source
    gotoItem(itemId) {
      this.$emit("sourceSelected", itemId);
    },

    // Show the source modal
    showDeleteModal(itemId, itemTitle) {
      this.deleteId = itemId;
      this.confirmationFields.confirmValue = itemTitle;
      this.confirmationFields.show = true;
      this.confirmationFields.title = `Delete ${itemTitle}?`;
    },

    // Delete the source
    async deleteItem() {
      this.$emit("deleteSource", this.deleteId);
      this.closeModal();
    },

    // Close all the modals
    closeModal() {
      this.confirmationFields.show = false;
    },
  },
};
</script>
          
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.data-table {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  margin-bottom: 0.5rem;
}

.grid-table.grid-item-clickable {
  flex: 1;
  overflow-y: scroll;
}

.grid-column {
  &:nth-child(1) {
    width: 13rem;
  }

  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6) {
    width: 12.5%;
    text-align: center;
  }

  &:nth-child(2) {
    width: 17.5%;
  }

  &:nth-child(4) {
    margin: 0 1rem;
    text-align: center;
  }

  &:nth-child(2),
  &:nth-child(3) {
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.source-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;
  font-size: $smallerFontSize;

  img {
    width: 1.75rem;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
  }
}

.name,
.url,
.updated-date {
  color: $greyColor;
  font-size: $smallerFontSize;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
}

.name {
  font-size: $smallFontSize;
  color: $blackColor;
  font-weight: $mediumFontWeight;
}

.url {
  text-transform: none;
}

.status {
  position: relative;
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  color: $greenMessageTextColor;
  background-color: lighten($greenMessageColor, 0%);
  border: 1px solid $greenMessageBorderColor;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  text-transform: capitalize;

  &.inactive {
    color: $redMessageTextColor;
    background-color: lighten($redMessageColor, 5%);
    border: 1px solid $redMessageBorderColor;
  }
}
</style>