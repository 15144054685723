<template>
  <header id="main-header">
    <img src="@/assets/images/logo.png" class="logo" alt="Macha Logo" />

    <!--START: Main Menu-->
    <menu>
      <span v-for="menu in menu" :key="menu.id">
        <router-link
          v-if="!menu.product || company.products[menu.product]?.hasAccess"
          :to="menu.link"
          class="menu-item"
        >
          <span>{{ menu.title }}</span>
          <label v-if="menu.isBeta" class="beta-label">beta</label>
        </router-link>
      </span>
    </menu>
    <!--START: Main Menu-->

    <!--START: Menu Right -->
    <div class="menu-right">
      <div v-if="selectedCompany" class="workspace-selection-wrapper">
        <!--START: Selected Store-->
        <div
          class="selected-workspace workspace-item"
          @click="toggleCompaniesMenu"
        >
          <!--START: Logo-->
          <div class="workspace-logo">
            <img
              v-if="selectedCompany.logoURL != undefined"
              :src="selectedCompany.logoURL"
              :alt="selectedCompany.name"
            />
            <ProfileAvatar
              v-else
              :fullName="selectedCompany.name"
            ></ProfileAvatar>
            <unicon v-if="user.companies.length > 1" name="angle-down"></unicon>
          </div>
          <!--END: Logo-->

          <!--START: Workspace Info-->
          <div class="workspace-info">
            <h3>{{ selectedCompany.name }}</h3>
          </div>
          <!--END: Workspace Info-->
        </div>
        <!--END: Selected Store-->
        <!--START: Workspace selection-->
        <div
          v-if="showCompaniesMenu && user.companies.length > 1"
          class="workspaces-wrapper"
          v-click-outside="toggleCompaniesMenu"
        >
          <div v-for="company in user.companies" :key="company.id">
            <div
              v-if="selectedCompany._id !== company.companyId"
              class="workspace-item"
              @click="toggleStore(company._id)"
            >
              <!--START: Logo-->
              <div class="workspace-logo">
                <img
                  v-if="company.logoURL != undefined"
                  :src="company.logoURL"
                  :alt="company.name"
                />
                <ProfileAvatar
                  v-else
                  :fullName="company.name"
                  size="small"
                ></ProfileAvatar>
              </div>
              <!--END: Logo-->

              <!--START: Workspace Info-->
              <div class="workspace-info">
                <h3>{{ company.name }}</h3>
              </div>
              <!--END: Workspace Info-->
            </div>
          </div>
        </div>
        <!--END: Workspace selection-->
      </div>

      <span class="menu-item task-list-item" @click="showTaskList">
        <unicon name="bolt"></unicon>
        <span>Getting Started</span>
      </span>

      <router-link :to="`/settings`" class="menu-item">
        <unicon name="setting"></unicon>
        <span>Settings</span>
      </router-link>

      <!--START: User Button-->
      <div class="btn-user-wrapper">
        <div class="menu-item" @click="toggleUserMenu">
          <unicon name="user"></unicon>
          <span>Account</span>
        </div>
        <div
          v-if="showUserMenu"
          class="user-menu"
          v-click-outside="toggleUserMenu"
        >
          <router-link to="/logout" class="menu-item">
            <span>Logout</span>
          </router-link>
        </div>
      </div>
      <!--END: User Button-->
    </div>
    <!--END: Menu Right -->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </header>
</template>

<script>
// Importing components
import ProfileAvatar from "@/components/misc/ProfileAvatar";
import LineLoader from "@/components/loaders/LineLoader";

//Importing libraries
import vClickOutside from "v-click-outside";

export default {
  name: "Header",
  data() {
    return {
      showUserMenu: false,
      showAppsMenu: false,
      showCompaniesMenu: false,
      showOptions: {
        lineLoader: false,
      },
      menu: [
        {
          title: "AI Assistant",
          link: "/ai-assistant",
          restrictAccess: false,
        },
        {
          title: "AI Agent",
          link: "/ai-agent",
          isBeta: true,
          restrictAccess: false,
          product: "aiAgent",
        },
        {
          title: "Knowledge Base",
          link: "/knowledge-base",
          restrictAccess: false,
        },
        {
          title: "Analytics",
          link: "/analytics",
          restrictAccess: false,
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user.user;
    },
    company() {
      return this.$store.getters.company;
    },
    selectedCompany() {
      let selectedCompany = this.user?.companies.find(
        (c) => c._id === this.company._id
      );

      return selectedCompany;
    },

    restrictAccess() {
      if (this.user?.role !== "admin") return true;
      return false;
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: { ProfileAvatar, LineLoader },
  methods: {
    toggleStore(companyId) {
      const data = {
        email: this.user?.email,
        companyId,
      };
      this.$emit("toggleCompany", data);
    },

    toggleAppsMenu() {
      this.showAppsMenu = !this.showAppsMenu;
    },

    toggleUserMenu() {
      this.showUserMenu = !this.showUserMenu;
    },

    toggleCompaniesMenu() {
      this.showCompaniesMenu = !this.showCompaniesMenu;
    },

    showTaskList() {
      this.$emit("showTaskList");
    },

    shopURL(url) {
      if (url) return url.replace(/^https?:\/\//, "").replace(/\/$/, "");
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
header {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0.75rem calc(2rem);
  width: calc(100% - 4rem);
  z-index: 15;
  background-color: $headerBackgroundColor;
  border-bottom: 1px solid $headerBorderColor;
  backdrop-filter: blur(15px);
}

.logo {
  display: block;
  width: 6rem;
  transform: translateY(-0.15rem);
}

menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1.5rem;
}

.menu-item {
  @include transition;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $headerMenuItemColor;
  font-size: $smallerFontSize;
  padding: 0.35rem 0.75rem;
  border-radius: 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;

  /deep/ svg {
    fill: $headerMenuItemColor;
    width: 1rem;
    margin-right: 0.5rem;
  }

  &:hover {
    color: $greyColor;
    text-decoration: none;

    /deep/ svg {
      fill: $greyColor;
    }
  }

  &.router-link-active {
    color: $whiteColor;
    background-color: $secondaryColor;

    /deep/ svg {
      fill: $whiteColor;
    }
  }

  &.task-list-item {
    .unicon {
      transform: translateY(2px);
    }
  }
}

.menu-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  .router-link-active {
    font-weight: $mediumFontWeight;

    /deep/ svg {
    }
  }
}

.btn-apps-wrapper,
.btn-user-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  .menu-item {
    margin: 0;
  }

  .apps-menu,
  .user-menu {
    background-color: $whiteColor;
    box-shadow: 0 0 0.7rem -0.1rem $boxShadowColor;
    position: absolute;
    padding: 0.5rem;
    border-radius: 0.75rem;
    top: calc(100% + 0.25rem);
    white-space: nowrap;
    right: 0;
    z-index: 100;

    .menu-item {
      margin: 0;

      &:hover {
        background: darken($whiteColor, 5%);
      }
    }
  }
}

.btn-apps-wrapper {
  > .menu-item {
    border: 1px solid lighten($buttonBorderColor, 15%);
    border-radius: 0.5rem;
    margin-left: 1rem;

    /deep/ svg {
      margin-right: 0;
    }

    &:hover {
      border: 1px solid lighten($buttonBorderColor, 5%);
      background: darken($whiteColor, 2.5%);

      /deep/ svg {
        fill: $purpleColor;
      }
    }
  }
}

.account-integrated {
  display: inline-block;
  width: max-content;
  background-color: $greenMessageColor;
  border: 1px solid $greenMessageBorderColor;
  color: $greenMessageTextColor;
  font-weight: $mediumFontWeight;
  font-size: $smallerFontSize;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;

  .unicon svg {
    display: inline-block;
    vertical-align: middle;
    fill: $greenMessageTextColor;
    height: auto;
    width: 0.75rem;
    margin-right: 0.5rem;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.selected-workspace {
  position: relative;
  margin-bottom: 0;
  padding: 0.2rem;
  border-radius: 0.5rem;
  background-color: $lightWhiteColor;
  border: 1px solid darken($lightWhiteColor, 5%);
  margin-bottom: 0;
  min-height: 1.75rem;

  /deep/ .unicon {
    position: absolute;
    top: 50%;
    right: 0.35rem;
    transform: translateY(-50%);
    svg {
      height: auto;
      width: 1rem;
      margin-top: 0.25rem;
    }
  }
}

.workspace-item {
  @include transition;
  display: flex;
  flex-direction: row;
  align-items: center;

  .workspace-logo {
    margin-right: 0.5rem;

    img {
      display: block;
      width: 2rem;
      border-radius: 0.5rem;
      border: 1px solid darken($whiteColor, 10%);
    }
  }

  .workspace-info {
    flex: 1;
    text-align: left;
    max-width: 10rem;
    margin-right: 0.5rem;

    h3 {
      font-size: $smallerFontSize;
      margin-bottom: -0.25rem;
      margin-right: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      display: block;
      color: $greyColor;
      font-size: $smallestFontSize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 1rem;

      &.no-store {
        opacity: $lightOpacity;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: lighten($purpleColor, 40%);
    border: 1px solid lighten($purpleColor, 15%);
  }
}

.workspace-selection-wrapper {
  position: relative;

  .workspaces-wrapper {
    position: absolute;
    background-color: $whiteColor;
    box-shadow: 0 0 0.7rem -0.1rem $boxShadowColor;
    border-radius: 0.75rem;
    top: calc(100% + 0.25rem);
    left: 0;
    max-height: 35vh;
    width: max-content;
    overflow: scroll;

    .workspace-item {
      border: none;
      padding: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }

      .workspace-logo {
        img {
          width: 1.75rem;
        }
      }
    }
  }
}

/deep/ .line-loader {
  left: 0;
  bottom: -5px;
}

.beta-label {
  color: darken($greenColor, 35%);
  background-color: $greenColor;
  font-weight: $mediumFontWeight;
  font-size: $smallestFontSize;
  margin-left: 0.5rem;
  padding: 0 5px;
  border-radius: 0.25rem;
}
</style>