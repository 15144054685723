<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button
        class="btn btn-small btn-primary"
        :disabled="showOptions.disableButton || showOptions.saveInProgress"
        @click="updateAiAssistant"
      >
        Save
      </button>
    </div>
    <!--END: Header Wrapper-->

    <!--START: Loader-->
    <PageLoader
      v-if="lodash.isEmpty(aiAssistant)"
      :showLoader="true"
    ></PageLoader>
    <!--END: Loader-->

    <!--START: Wrapper-->
    <div v-else ref="mainContent" class="main-content" @scroll="contentScroll">
      <SettingsForm
        v-for="field in fields"
        :key="field._id"
        :fields="field.fields"
        :content="field.content"
        @fieldChanged="fieldChanged"
      ></SettingsForm>
    </div>
    <!--END: Wrapper-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
                
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";
import SettingsForm from "@/components/form/SettingsForm";

// Importing Services
import "external-svg-loader";
import { AiAssistantServices } from "@/services";
const { AiAssistantService } = AiAssistantServices;

export default {
  name: "AiAssistantConfiguration",
  data() {
    return {
      lodash: _,
      languages: [
        "Albanian",
        "Arabic",
        "Armenian",
        "Awadhi",
        "Azerbaijani",
        "Bashkir",
        "Basque",
        "Belarusian",
        "Bengali",
        "Bhojpuri",
        "Bosnian",
        "Brazilian Portuguese",
        "Bulgarian",
        "Cantonese (Yue)",
        "Catalan",
        "Chhattisgarhi",
        "Chinese",
        "Croatian",
        "Czech",
        "Danish",
        "Dogri",
        "Dutch",
        "English",
        "Estonian",
        "Faroese",
        "Finnish",
        "French",
        "Galician",
        "Georgian",
        "German",
        "Greek",
        "Gujarati",
        "Haryanvi",
        "Hindi",
        "Hungarian",
        "Indonesian",
        "Irish",
        "Italian",
        "Japanese",
        "Javanese",
        "Kannada",
        "Kashmiri",
        "Kazakh",
        "Konkani",
        "Korean",
        "Kyrgyz",
        "Latvian",
        "Lithuanian",
        "Macedonian",
        "Maithili",
        "Malay",
        "Maltese",
        "Mandarin",
        "Mandarin Chinese",
        "Marathi",
        "Marwari",
        "Min Nan",
        "Moldovan",
        "Mongolian",
        "Montenegrin",
        "Nepali",
        "Norwegian",
        "Oriya",
        "Pashto",
        "Persian (Farsi)",
        "Polish",
        "Portuguese",
        "Punjabi",
        "Rajasthani",
        "Romanian",
        "Russian",
        "Sanskrit",
        "Santali",
        "Serbian",
        "Sindhi",
        "Sinhala",
        "Slovak",
        "Slovene",
        "Slovenian",
        "Ukrainian",
        "Urdu",
        "Uzbek",
        "Vietnamese",
        "Welsh",
        "Wu",
      ],
      showOptions: {
        headerShadow: false,
        lineLoader: false,
        disableButton: true,
      },
      regularHeader: {
        title: "Configuration",
        description: "Settings for the AI assistant widget on Zendesk",
      },
      fields: {
        company: {
          content: {
            title: "Company details",
            description:
              "Details of the compamny that the AI assistant represents",
          },
          fields: {
            company: {
              type: "group",
              nestFields: true,
              fields: {
                name: {
                  type: "text",
                  title: "Name*",
                  required: true,
                  hasError: false,
                  placeholder: "Add the company name",
                  value: "",
                },
                description: {
                  type: "textarea",
                  placeholder: "Describe the company",
                  required: false,
                  hasError: false,
                  title: "Description",
                  value: "",
                },
              },
            },
          },
        },
        features: {
          content: {
            title: "Widget features",
            description: "Determine which AI assistant features to use",
          },
          fields: {
            aiAnswers: {
              type: "group",
              nestFields: true,
              fields: {
                isActive: {
                  type: "toggle",
                  title: "Use AI answers using help center",
                  additionalClass: "large-toggle",
                  value: false,
                },
              },
            },
            resources: {
              type: "group",
              nestFields: true,
              fields: {
                isActive: {
                  type: "toggle",
                  title: "Show relevant articles and macros",
                  additionalClass: "large-toggle",
                  value: false,
                },
              },
            },
            pastTickets: {
              type: "group",
              nestFields: true,
              fields: {
                isActive: {
                  type: "toggle",
                  title: "Use related past tickets to create answers",
                  additionalClass: "large-toggle",
                  value: false,
                },
              },
            },
            analysis: {
              type: "group",
              nestFields: true,
              fields: {
                language: {
                  type: "dropdown",
                  placeholder: "Select language",
                  title: "Language of the analysis and summary",
                  required: true,
                  hasError: false,
                  fields: [],
                  value: [],
                },
              },
            },
            translation: {
              type: "group",
              title: "Translation settings",
              nestFields: true,
              fields: {
                isActive: {
                  type: "toggle",
                  title: "Allow translations",
                  additionalClass: "large-toggle",
                  value: false,
                },
                defaultLanguage: {
                  type: "dropdown",
                  placeholder: "Select language",
                  title: "Default language for translations",
                  required: true,
                  hasError: false,
                  fields: [],
                  value: [],
                },
              },
            },
          },
        },
        zendeskSettings: {
          content: {
            title: "Zendesk Metadata",
            description:
              "Decide what Zendesk ticket data you want the AI to access to create answers",
          },
          fields: {
            addInternalNotes: {
              type: "toggle",
              title: "Add internal notes",
              additionalClass: "large-toggle",
              value: false,
            },
          },
        },
      },
      aiAssistant: {},
      initData: {},
      dataChanged: false,
      status: {
        show: false,
        status: "success",
        title: "AI assistant configurationn saved",
      },
    };
  },
  props: {},
  watch: {},
  components: {
    RegularHeader,
    NotificationMessage,
    LineLoader,
    PageLoader,
    SettingsForm,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  async created() {
    await this.getAiAssistant();
  },
  methods: {
    // Get AI assistant
    async getAiAssistant() {
      const response = await AiAssistantService.GetAiAssistant();
      if (!response.error) {
        this.aiAssistant = response.data;
        this.populateFields();
      }
    },

    // Update the AI assistant signature
    async updateAiAssistant() {
      this.showOptions.lineLoader = true;
      const data = {
        ...this.fields.company.fields,
        ...this.fields.features.fields,
        ...this.fields.zendeskSettings.fields,
      };
      var isFormValid = this.validateForm(data);

      if (isFormValid) {
        const payload = this.constructPayload();
        const response = await AiAssistantService.UpdateAiAssistant(payload);
        this.aiAssistant = response.data;
        this.populateFields();

        if (!response.hasError) {
          this.showStatusMessage(this.status, 2500, response.message);
        }
      }

      this.showOptions.lineLoader = false;
    },

    // Populate the fields
    populateFields() {
      if (!_.isEmpty(this.aiAssistant)) {
        const { company, zendeskSettings, features } = this.fields;

        // Company settings
        company.fields.company.fields.name.value =
          this.aiAssistant.company?.name;
        company.fields.company.fields.description.value =
          this.aiAssistant.company?.description;

        // Zendesk settings
        zendeskSettings.fields.addInternalNotes.value =
          this.aiAssistant.zendeskSettings.addInternalNotes;

        // Populate languages
        features.fields.analysis.fields.language.fields = _.cloneDeep([]);
        features.fields.translation.fields.defaultLanguage.fields = _.cloneDeep(
          []
        );
        this.languages.forEach((l) => {
          const option = { name: l, value: l };
          features.fields.analysis.fields.language.fields.push(option);
          features.fields.translation.fields.defaultLanguage.fields.push(
            option
          );
        });

        features.fields.aiAnswers.fields.isActive.value =
          this.aiAssistant.features.aiAnswers.isActive;
        features.fields.resources.fields.isActive.value =
          this.aiAssistant.features.resources.isActive;
        features.fields.pastTickets.fields.isActive.value =
          this.aiAssistant.features.pastTickets.isActive;
        features.fields.analysis.fields.language.value = [
          this.aiAssistant.features.analysis.language,
          this.aiAssistant.features.analysis.language,
        ];
        features.fields.translation.fields.isActive.value =
          this.aiAssistant.features.translation.isActive;
        features.fields.translation.fields.defaultLanguage.value = [
          this.aiAssistant.features.translation.defaultLanguage,
          this.aiAssistant.features.translation.defaultLanguage,
        ];
      }

      this.initFormData();
    },

    // Construct the payload
    constructPayload() {
      const payload = {
        ...this.parseData(this.fields.company.fields),
        features: this.parseData(this.fields.features.fields),
        zendeskSettings: this.parseData(this.fields.zendeskSettings.fields),
      };
      return payload;
    },

    // Initialise the form data for comparisons
    initFormData() {
      const data = this.fields;

      this.disableSaveButton(true);
      this.initData = _.cloneDeep(data);
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Update rule when fields change
    fieldChanged() {
      const data = this.fields;
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Event on scroll
    contentScroll() {
      const scrollTop = this.$refs.mainContent.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },
  },
};
</script>
                
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 1.5rem;
  margin-bottom: 0 !important;
  border-bottom: 1px solid darken($rowBorderColor, 2.5%);

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.main-content {
  flex: 1;
  overflow-y: scroll;
  margin-bottom: 3rem;
  padding-top: 2rem;
}

.zero-state {
  margin-top: 2.5rem;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}
</style>