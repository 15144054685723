<template>
  <div class="all-documents-table">
    <!--START: Documents List-->
    <ul class="grid-table">
      <li
        class="grid-row grid-header"
        :class="{ 'grid-header-shadow': showOptions.headerShadow }"
      >
        <span class="grid-column">Title</span>
        <span class="grid-column">Response Type</span>
        <span class="grid-column">Status</span>
      </li>
    </ul>
    <ul
      class="grid-table grid-item-clickable"
      ref="table"
      @scroll="tableScroll"
    >
      <li
        v-for="(i, index) in intents"
        :key="index"
        class="grid-row"
        @click="gotoItem(i)"
      >
        <div class="grid-column">
          <h3 class="title">{{ i.title }}</h3>
        </div>

        <div class="grid-column">
          <span class="response-type">{{
            getResponseType(i.responseType)
          }}</span>
        </div>

        <div class="grid-column">
          <span class="status" :class="{ inactive: !i.isActive }">{{
            i.isActive ? "Active" : "Inactive"
          }}</span>
        </div>

        <div class="grid-column grid-actions">
          <button
            class="btn btn-icon btn-delete"
            @click.stop="showDeleteModal(i._id, i.title)"
          >
            <unicon name="trash-alt"></unicon>
          </button>
          <button class="btn btn-icon">
            <unicon name="angle-right-b"></unicon>
          </button>
        </div>
      </li>
    </ul>
    <!--END: Documents List-->

    <!--START: Documents Count-->
    <span class="grid-count"
      >Showing {{ intents.length }}
      {{ intents.length == 1 ? "intent" : "intents" }}</span
    >
    <!--END: Documents Count-->

    <!--START: Confirmation Modal-->
    <ConfirmationModal
      :fields="confirmationFields"
      @primaryEvent="deleteItem"
      @secondaryEvent="closeModal"
    ></ConfirmationModal>

    <div
      class="bg-overlay light"
      :class="{ show: confirmationFields.show }"
      @click="closeModal"
    ></div>
    <!--END: Confirmation Modal-->
  </div>
</template>
          
<script>
// Importing Components
import ConfirmationModal from "@/components/modals/ConfirmationModal";

export default {
  name: "IntentsTable",
  props: {
    intents: Array,
    customReplies: Array,
  },
  data() {
    return {
      showOptions: {
        lineLoader: false,
        headerShadow: false,
      },
      deleteId: null,
      confirmationFields: {
        show: false,
        title: "",
        type: "delete",
        modalType: "fixed",
        message: "This action cannot be undone. The intent will be deleted.",
        iconType: "exclamation",
        confirmAction: true,
        confirmValue: null,
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
    };
  },
  computed: {},
  components: {
    ConfirmationModal,
  },
  methods: {
    // Get the custom reply for the intent
    getCustomReply(intent) {
      const selectedReply = this.customReplies.find(
        (m) => m._id === intent.customReplyId
      );

      return selectedReply ? selectedReply.name : "-";
    },

    // Event on table scroll
    tableScroll() {
      const scrollTop = this.$refs.table.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Get response type
    getResponseType(response) {
      const responseTypes = [
        { value: "draft", title: "Create draft" },
        { value: "auto-reply", title: "Auto reply" },
        { value: "default", title: "Use topic response" },
      ];

      const selectedResponse = responseTypes.find((t) => t.value === response);
      return selectedResponse.title;
    },

    // Goto view a particular document
    gotoItem(item) {
      this.$emit("intentSelected", item);
    },

    // Show the delete modal
    showDeleteModal(itemId, itemTitle) {
      this.deleteId = itemId;
      this.confirmationFields.confirmValue = itemTitle;
      this.confirmationFields.show = true;
      this.confirmationFields.title = `Delete "${itemTitle}"?`;
    },

    // Delete the document
    async deleteItem() {
      this.showOptions.lineLoader = true;

      this.$emit("deleteIntent", this.deleteId);
      this.closeModal();
    },

    // Close all the modals
    closeModal() {
      this.confirmationFields.show = false;
    },
  },
};
</script>
          
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.all-documents-table {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  margin-bottom: 0.5rem;
}

.grid-table.grid-item-clickable {
  flex: 1;
  overflow-y: scroll;
}
.grid-column {
  &:nth-child(1) {
    width: calc(50% - 7rem - 2rem);
  }
  &:nth-child(2) {
    text-align: left;
    width: 40%;
  }

  &:nth-child(3) {
    margin: 0 1rem;
    text-align: center;
    width: 15%;
  }
}

.custom-reply {
  color: $greyColor;
  font-size: $smallerFontSize;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
}

.title {
  font-size: $normalFontSize;
  font-weight: $mediumFontWeight;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.status {
  display: inline-block;
  position: relative;
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  color: $greenMessageTextColor;
  background-color: lighten($greenMessageColor, 0%);
  border: 1px solid $greenMessageBorderColor;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  text-transform: capitalize;

  &.inactive {
    color: $redMessageTextColor;
    background-color: lighten($redMessageColor, 5%);
    border: 1px solid $redMessageBorderColor;
  }
}

.source,
.type,
.response-type {
  font-size: $smallerFontSize;
  text-transform: capitalize;
  color: $greyColor;
}

.btn-view {
  padding: 0;

  /deep/ svg {
    width: 1.5rem;
    height: auto;
  }

  &:hover {
    background: transparent;
    /deep/ svg {
      fill: $greyColor;
    }
  }
}

/deep/ .line-loader {
  position: relative !important;
  border-radius: 0.5rem;
}

.table-actions-wrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 0.5rem;

  .document-count {
    flex: 1;
    font-weight: $mediumFontWeight;
    color: $paragraphColor;
    font-size: $smallFontSize;
    opacity: $lightOpacity;
  }

  .search-wrapper {
    position: relative;
    margin-right: 2rem;

    .unicon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1ch;
      z-index: 2;

      /deep/ svg {
        fill: $greyColor;
        opacity: $lightOpacity;
        width: 1rem;
      }
    }

    /deep/ {
      .field-main-item {
        max-width: 18rem;
      }
      input {
        padding-left: 2rem;
      }
    }
  }

  /deep/ {
    .field-main-item {
      margin-bottom: 0;

      .field-radio-wrapper {
        margin-bottom: 0;
      }
    }
  }
}

.pre-loader-wrapper /deep/ {
  .buffer-hero,
  .buffer-title {
    height: 1rem;
    margin: 0;
  }

  .buffer-line,
  .buffer-category {
    display: none;
  }
}
</style>