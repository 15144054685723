import Vue from "vue";
import VueMeta from "vue-meta";
import App from "@/App.vue";
import store from "@/store";
import VueCookies from "vue-cookies";

// Importing the main routes for the App
import routes from "@/routes";

// Importing Master CSS
import "@/assets/styles/_reset.css";
import "@/assets/styles/_global.scss";
import "@/assets/styles/_framework.scss";

// Importing Mixin Functions
import GlobalFunctions from "@/mixins/Functions";
Vue.mixin(GlobalFunctions);

import GlobalVariables from "@/mixins/Variables";
Vue.mixin(GlobalVariables);

// Adding Vue Headful for custom headers
import vueHeadful from "vue-headful";
Vue.component("vue-headful", vueHeadful);

// Adding VueMeta for custom headers
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

// Use cookies for analytics
Vue.use(VueCookies);

// Importing Font Awesome icons
import Unicon from "vue-unicons/dist/vue-unicons-vue2.umd";
import {
  uniAngleRight,
  uniAngleRightB,
  uniTrashAlt,
  uniLock,
  uniFacebookF,
  uniInstagram,
  uniWhatsapp,
  uniTwitter,
  uniYoutube,
  uniTimes,
  uniPlay,
  uniCheck,
  uniDumbbell,
  uniAngleLeft,
  uniAngleUp,
  uniHeart,
  uniSnapchatGhost,
  uniClock,
  uniEnvelope,
  uniPaperclip,
  uniVideo,
  uniBolt,
  uniBoltAlt,
  uniUser,
  uniPathfinder,
  uniImport,
  uniDesktop,
  uniUsersAlt,
  uniLocationArrow,
  uniCalendarAlt,
  uniAngleDown,
  uniApps,
  uniPhone,
  uniPen,
  uniShoppingCart,
  uniExternalLinkAlt,
  uniMessage,
  uniThumbsUp,
  uniThumbsDown,
  uniCopy,
  uniRedo,
  uniPlus,
  uniEye,
  uniChartLine,
  uniSetting,
  uniCopyAlt,
  uniBrain,
  uniFavorite,
  uniSearch,
  uniLink,
  uniTruck,
  uniEnvelopeDownload,
  uniTimesCircle,
  uniSortAmountDown,
  uniSitemap,
  uniLayers,
  uniCommentAlt,
  uniFileAlt,
  uniVectorSquare,
  uniCropAltRotateRight,
  uniBell,
  uniRepeat,
  uniImagePlus,
  uniExclamationTriangle,
  uniListUl,
  uniSpinnerAlt,
  uniCheckCircle,
  uniTicket,
  uniExport,
  uniUpload,
  uniAnalytics,
  uniCheckCircleSolid,
  uniExclamationCircle,
  uniHeadSide,
  uniSync,
  uniEllipsisH,
  uniUniversity,
  uniPlug,
  uniExchange,
  uniAlignAlt,
  uniTagAlt,
  uniCancel,
  uniInbox,
  uniPuzzlePiece,
  uniFilter,
  uniEditAlt,
  uniChannel,
} from "vue-unicons/dist/icons";
Unicon.add([
  uniTrashAlt,
  uniAngleRight,
  uniAngleRightB,
  uniAngleLeft,
  uniAngleUp,
  uniAngleDown,
  uniLock,
  uniChartLine,
  uniFacebookF,
  uniInstagram,
  uniWhatsapp,
  uniTwitter,
  uniYoutube,
  uniTimes,
  uniTimesCircle,
  uniPlay,
  uniCheck,
  uniDumbbell,
  uniHeart,
  uniSnapchatGhost,
  uniClock,
  uniEnvelope,
  uniInbox,
  uniEnvelopeDownload,
  uniPaperclip,
  uniVideo,
  uniBolt,
  uniBoltAlt,
  uniUser,
  uniPathfinder,
  uniImport,
  uniDesktop,
  uniUsersAlt,
  uniLocationArrow,
  uniCalendarAlt,
  uniApps,
  uniClock,
  uniAngleLeft,
  uniPhone,
  uniPen,
  uniShoppingCart,
  uniUsersAlt,
  uniExternalLinkAlt,
  uniMessage,
  uniThumbsUp,
  uniThumbsDown,
  uniCopy,
  uniRedo,
  uniPlus,
  uniEye,
  uniSetting,
  uniCopyAlt,
  uniBrain,
  uniFavorite,
  uniSearch,
  uniLink,
  uniTruck,
  uniSortAmountDown,
  uniSitemap,
  uniLayers,
  uniCommentAlt,
  uniFileAlt,
  uniVectorSquare,
  uniCropAltRotateRight,
  uniBell,
  uniRepeat,
  uniImagePlus,
  uniExclamationTriangle,
  uniExclamationCircle,
  uniListUl,
  uniSpinnerAlt,
  uniCheckCircle,
  uniTicket,
  uniExport,
  uniUpload,
  uniAnalytics,
  uniCheckCircleSolid,
  uniHeadSide,
  uniSync,
  uniEllipsisH,
  uniUniversity,
  uniPlug,
  uniExchange,
  uniAlignAlt,
  uniTagAlt,
  uniCancel,
  uniApps,
  uniPuzzlePiece,
  uniFilter,
  uniEditAlt,
  uniChannel
]);
Vue.use(Unicon);

Vue.config.productionTip = false;

const router = () => {
  return routes;
};

// Importing moment.js for time based functions
import moment from "moment-timezone";
Vue.prototype.moment = moment;

new Vue({
  store,
  router: router(),
  render: (h) => h(App),
}).$mount("#app");
