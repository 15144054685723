<template>
  <div class="plan-wrapper">
    <!--START: Current Plan-->
    <div class="card-wrapper">
      <div class="info-wrapper">
        <h3 class="section-title">Current Plan</h3>
        <p>Here are the details and usage of your trial</p>
      </div>

      <!--START: Active plan-->
      <div class="trial-plan">
        <div class="info-wrapper">
          <h3 class="section-title">
            Trial Plan<span v-if="trialDaysLeft > 0">Active</span>
          </h3>
          <p>Try the Macha AI Assistant for Zendesk app on your instance</p>
        </div>

        <div class="usage-wrapper">
          <div class="usage-details">
            <div class="term-details">
              <span>{{ trialStartDate.format("D MMMM YYYY") }}</span>
              to
              <span>{{ trialEndDate.format("D MMMM YYYY") }}</span>
            </div>
            <div v-if="trialDaysLeft >= 0" class="answers-count">
              <span>{{ trialDaysLeft }}</span> of
              {{ subscription.trial.duration }}
              {{ subscription.trial.duration === 1 ? "day" : "days" }} remaining
            </div>
            <div v-else class="answers-count">Trial expired</div>
          </div>
          <div class="usage-bar">
            <span
              class="current-usage"
              :style="trialProgressWidth"
              :class="usageClass"
            ></span>
          </div>
        </div>
      </div>
      <!--END: Active plan-->
    </div>
    <!--END: Current Plan-->

    <!--START: Available Plans-->
    <Plans @subscribePlan="subscribePlan"></Plans>
    <!--START: Available PLans-->
  </div>
</template>
      
<script>
// Import components
import Plans from "./Plans.vue";

export default {
  name: "TrialPlan",
  props: {
    subscription: Object,
  },
  components: { Plans },
  data() {
    return {};
  },
  computed: {
    // Trial starting date
    trialStartDate() {
      return this.moment(this.subscription.trial.startDate);
    },

    // Trial starting date
    trialEndDate() {
      return this.moment(this.subscription.trial.startDate).add(
        "days",
        this.subscription.trial.duration
      );
    },

    // Remaining trial days
    trialDaysLeft() {
      return this.trialEndDate.diff(this.moment(), "days");
    },

    // Send the current usage style
    trialProgressWidth() {
      return `width: ${
        ((this.subscription.trial.duration - this.trialDaysLeft + 1) /
          this.subscription.trial.duration) *
        100
      }%`;
    },

    // Determine the class based on the usage
    usageClass() {
      const currentUsage =
        ((this.subscription.trial.duration - this.trialDaysLeft) /
          this.subscription.trial.duration) *
        100;

      if (currentUsage >= 100) return "exceeded";
      else if (currentUsage < 50) return "low";
      else if (currentUsage > 50 && currentUsage < 85) return "medium";
      else if (currentUsage > 85) return "low";

      return "low";
    },
  },
  created() {},
  methods: {
    // Subscribe to a plan
    subscribePlan(planId) {
      this.$emit("subscribePlan", planId);
    },
  },
};
</script>
      
<style scoped lang="scss">
.card-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .info-wrapper {
    flex: 1;
  }
}

.info-wrapper {
  margin-bottom: 1rem;

  .section-title {
    font-family: $titleFont;
    font-size: $largerFontSize;
    font-weight: $boldFontWeight;

    span {
      display: inline-block;
      font-size: $smallestFontSize;
      background-color: $emeraldColor;
      color: lighten($emeraldColor, 50%);
      border: 1px solid lighten($emeraldColor, 20%);
      border-radius: 1.5rem;
      font-weight: $normalFontWeight;
      padding: 0.05rem 0.5rem;
      margin-left: 0.5rem;
      transform: translateY(-3px);
    }
  }

  p {
    font-size: $smallFontSize;
    opacity: $lightOpacity;
  }

  .btn {
    margin-top: 1rem;
  }
}

.trial-plan {
  border: 1px solid $inputBorderColor;
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  width: 35rem;

  .details-title {
    display: block;
    color: $greyColor;
    font-size: $smallFontSize;
    margin-bottom: 0.5rem;
  }

  .plan-details {
    .plan-name {
      font-size: 2.75rem;
      color: $purpleColor;
      font-weight: 600;
    }
  }

  .usage-wrapper {
    margin-top: 1rem;

    .usage-details {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: $smallerFontSize;
      color: lighten($greyColor, 10%);
      margin-bottom: 0.5rem;

      .term-details {
        flex: 1;
      }

      .answers-count {
        text-align: right;
      }

      span {
        color: $greyColor;
        font-weight: $boldFontWeight;
        font-size: $normalFontSize;
      }

      .term-details {
        flex: 1;

        span {
          font-size: $smallFontSize;
          font-weight: 600;
        }
      }
    }

    .usage-bar {
      position: relative;
      overflow: hidden;
      background: #f2f0f9;
      height: 1rem;
      width: 100%;
      border-radius: 0.5rem;

      .current-usage {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;

        &.low {
          background-color: $emeraldColor;
        }

        &.medium {
          background-color: $purpleColor;
        }

        &.high {
          background-color: $orangeColor;
        }

        &.exceeded {
          background-color: $redColor;
        }
      }
    }
  }
}
</style>