<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div class="header-wrapper">
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->

      <button class="btn btn-small btn-grey" @click="addPersona">
        Add AI Persona
      </button>
    </div>
    <!--END: Header Wrapper-->

    <!--START: Zero State -->
    <ZeroState
      v-if="showOptions.zeroState"
      class="zero-state"
      :imageURL="zeroStateData.imageURL"
      :title="zeroStateData.title"
      :message="zeroStateData.message"
      :buttonTitle="zeroStateData.buttonTitle"
      @eventHandler="addPersona"
    ></ZeroState>
    <!--END: Zero State -->

    <!--START: Personas Table-->
    <PersonasTable
      v-else-if="personas"
      :personas="personas"
      @personaSelected="showPersonaModal"
      @deletePersona="deletePersona"
    ></PersonasTable>
    <!--END: Personas Table-->

    <!--START: Loader-->
    <PageLoader
      v-if="!showOptions.initPersonas"
      :showLoader="true"
    ></PageLoader>
    <!--END: Loader-->

    <!--START: View Document-->
    <ViewPersona
      v-if="showOptions.viewPersona"
      :show="showOptions.viewPersona"
      :personaId="selectedPersonaId"
      :personas="personas"
      @personaAdded="personaAdded"
      @closeModal="closeModal"
    ></ViewPersona>
    <div
      class="bg-overlay light"
      :class="{ show: showOptions.viewPersona }"
      @click="closeModal"
    ></div>
    <!--END: View Document-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
      @closeModal="closeModal"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
          
<script>
//Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import ZeroState from "@/components/misc/ZeroState";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";

import PersonasTable from "@/components/dashboard/tables/PersonasTable";
import ViewPersona from "@/components/dashboard/knowledgeBase/ViewPersona";

// Importing Services
import { AiAssistantServices } from "@/services";
const { PersonaService } = AiAssistantServices;
import "external-svg-loader";

export default {
  name: "AIPersonas",
  data() {
    return {
      showOptions: {
        lineLoader: false,
        initPersonas: false,
        zeroState: false,
        viewPersona: false,
      },
      regularHeader: {
        title: "Personas",
        description:
          "Create personas for the AI to craft answers in different tones and styles",
      },
      zeroStateData: {
        imageURL: "services.jpg",
        title: "No Personas Found",
        message: "Add an AI persona to reflect your brand tone and voice",
        buttonTitle: "Add AI Persona",
      },
      status: {
        show: false,
        status: "success",
        title: "",
      },
      selectedPersona: "",
      personas: [],
      gettingPersonas: false,
    };
  },
  props: {},
  watch: {},
  components: {
    RegularHeader,
    ZeroState,
    NotificationMessage,
    LineLoader,
    PageLoader,
    PersonasTable,
    ViewPersona,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  async created() {
    await this.getPersonas();
  },

  methods: {
    // Delete persona
    async deletePersona(personaId) {
      const data = { personaId };
      await PersonaService.DeletePersona(data);

      // Remove from the table
      const index = this.personas.findIndex((p) => p._id == personaId);
      this.personas.splice(index, 1);

      this.status.title = "Persona deleted";
      this.showStatusMessage(this.status, 2500);

      if (this.personas.length === 0) this.showOptions.zeroState = true;
    },

    // When a persona has been added or updated
    personaAdded(persona) {
      this.selectedPersonaId = persona._id;

      let tempPersonas = _.cloneDeep(this.personas);
      const index = this.personas.findIndex((p) => p._id == persona._id);

      if (index == -1) tempPersonas.push(persona);
      else tempPersonas[index] = persona;

      this.personas.splice(0);
      this.personas = _.cloneDeep(tempPersonas);
      this.showOptions.zeroState = false;

      this.getPersonas();
    },

    // Get all the personas
    async getPersonas() {
      this.personas.splice(0);
      this.gettingPersonas = true;
      const response = await PersonaService.GetAllPersonas();
      this.personas.push(...response.data);

      if (response.data == 0) this.showOptions.zeroState = true;

      this.gettingPersonas = false;
      this.showOptions.initPersonas = true;
    },

    // Show the selected document details
    showPersonaModal(personaId) {
      this.selectedPersonaId = personaId;
      this.showOptions.viewPersona = true;
    },

    addPersona() {
      this.selectedPersonaId = "";
      this.showOptions.viewPersona = true;
    },

    // Close all the modals
    closeModal() {
      this.status.show = false;
      this.showOptions.viewPersona = false;
    },
  },
};
</script>
          
<style scoped lang="scss">
.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1.5rem;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.zero-state {
  margin-top: 2.5rem;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}
</style>