require("dotenv").config();
import axios from "axios";

const apiURL = `${process.env.VUE_APP_API_URL}/subscription`;

class SubscriptionService {
  // Service to add new offering
  static GetSubscriptionHostedPage(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(
                `${apiURL}/${
                  data.subscriptionType === "new" ? "create" : "change"
                }`,
                data,
                {
                  withCredentials: true,
                }
              )
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to get a portal session
  static GetPortalSession(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/create-portal-session`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to get active subscription
  static GetActiveSubscription(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Service to get subscription agents for AI assistant
  static GetBillableAgents() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .get(`${apiURL}/billable-agents`, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  return error.response.data;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }
}

export default SubscriptionService;
