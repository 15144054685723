<template>
  <div>
    <TrialPlan
      v-if="showOptions.trialPlan"
      :subscription="subscription"
      @subscribePlan="subscribePlan"
    ></TrialPlan>
    <MeteredPlan
      v-if="showOptions.meteredPlan"
      :subscription="subscription"
      :chargebeeSubscription="chargebeeSubscription"
      @manageSubscription="manageSubscription"
      @subscribePlan="subscribePlan"
    ></MeteredPlan>
    <FixedPlan
      v-if="showOptions.fixedPlan"
      :subscription="subscription"
      :chargebeeSubscription="chargebeeSubscription"
      @manageSubscription="manageSubscription"
      @subscribePlan="subscribePlan"
    ></FixedPlan>
  </div>
</template>
      
<script>
// Import components
import TrialPlan from "./TrialPlan.vue";
import FixedPlan from "./FixedPlan.vue";
import MeteredPlan from "./MeteredPlan.vue";

export default {
  name: "AiAssistantSubscriptions",
  props: {
    subscription: Object,
    chargebeeSubscription: Object,
  },
  data() {
    return {
      showOptions: {
        trialPlan: false,
        fixedPlan: false,
        meteredPlan: false,
      },
    };
  },
  components: {
    TrialPlan,
    FixedPlan,
    MeteredPlan,
  },
  computed: {},
  created() {
    this.initSubscription();
  },
  methods: {
    // Initialise the company subscription
    initSubscription() {
      const { planType, subscriptionType } = this.subscription;

      if (planType === "trial") {
        this.showOptions.trialPlan = true;
      } else {
        if (subscriptionType === "fixed") {
          this.showOptions.fixedPlan = true;
        } else if (subscriptionType === "metered") {
          this.showOptions.meteredPlan = true;
        }
      }
    },

    // Subscribe to plan
    subscribePlan(planId, subscriptionType = "new") {
      this.$emit("subscribePlan", planId, subscriptionType);
    },

    // Manage subscription
    manageSubscription() {
      this.$emit("manageSubscription");
    },
  },
};
</script>
      
<style scoped lang="scss">
</style>