import { render, staticRenderFns } from "./AiAssistantTicket.vue?vue&type=template&id=36ea62af&scoped=true"
import script from "./AiAssistantTicket.vue?vue&type=script&lang=js"
export * from "./AiAssistantTicket.vue?vue&type=script&lang=js"
import style0 from "./AiAssistantTicket.vue?vue&type=style&index=0&id=36ea62af&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36ea62af",
  null
  
)

export default component.exports