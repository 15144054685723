<template>
  <div class="banner-wrapper">
    <span>
      Your reviews mean alot! Support us by
      <a
        href="https://www.zendesk.com/marketplace/apps/support/986669/chatgpt-ai-agent-assistant-by-macha/"
        target="_blank"
        >leaving us a review</a
      >
      and
      <a
        href="mailto:hello@getmacha.com?subject=Extend my trial by 7 days&body=Hey, We've left a review for Macha on Zendesk and would like our trial extension. Thanks!"
        target="_blank"
        >extend your trial by 7 days</a
      >
    </span>
  </div>
</template>
    
<script>
export default {
  name: "TrialBanner",
  computed: {
    // Get the company
    company() {
      return this.$store.getters.company;
    },
  },
  data() {
    return {};
  },
};
</script>
    
<style scoped lang="scss">
.banner-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 98;
  width: 100%;
  padding: 0.5rem 0;
  background-color: $purpleColor;
  text-align: center;

  span {
    font-size: $smallerFontSize;
    color: $whiteColor;
  }

  a {
    color: $whiteColor;
    font-weight: $boldFontWeight;
    text-decoration: underline;
  }

  &.free-plan {
    background-color: $greenMessageBorderColor;

    span,
    a {
      color: darken($greenMessageTextColor, 10%);
    }
  }
}
</style>