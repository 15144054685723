<template>
  <!--START: Ticket-->
  <div class="ticket-wrapper">
    <!--START: Ticket Header-->
    <div
      class="ticket-header"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <div class="subject-wrapper">
        <h3 v-if="ticket.subject" class="subject">{{ ticket.subject }}</h3>
        <h3 v-else class="subject no-subject">No subject</h3>
        <span class="answer-count">{{ answerCount }} answers generated</span>
      </div>
      <div class="details-wrapper">
        <div class="ticket-information">
          <span class="customer-name"
            >From <b>{{ getCustomerName(ticket).name }}</b></span
          >
          <a
            class="ticket-id"
            :href="`https://${zendeskDomain}.zendesk.com/agent/tickets/${ticket.ticketId}`"
            target="_blank"
            ><unicon name="link"></unicon>{{ ticket.ticketId }}</a
          >
          <span class="ticket-channel">{{
            zendeskTicket.via?.channel ? zendeskTicket.via?.channel : "-"
          }}</span>
        </div>
        {{ ticket.updatedAt }}
        <span class="update-time"
          >Last updated: <b>{{ getLastMessageTime(ticket.updatedAt) }}</b></span
        >
      </div>
    </div>
    <!--START: END Header-->

    <!--START: Ticket Body-->
    <div class="ticket-body">
      <!--START: Answers-->
      <div ref="ticketWindow" class="comments-list" @scroll="ticketScroll">
        <div v-for="c in ticketComments" :key="c._id" class="comment-item">
          <div class="left-col">
            <!--START: Customer Question-->
            <div
              v-if="!lodash.isEmpty(zendeskTicket) && c.lastCommentId"
              class="ticket-comments"
            >
              <div class="ticket-comments-title">
                <h3>Message being responded to</h3>
                <span>{{
                  moment(
                    getLastZendeskComment(c.lastCommentId).created_at
                  ).format("Do MMMM, hh:MM A")
                }}</span>
              </div>
              <div
                class="comment"
                v-html="getLastZendeskComment(c.lastCommentId).html_body"
              ></div>
            </div>
            <!--END: Customer Question-->

            <!--START: Answers-->
            <div
              v-for="(a, index) in c.answers"
              :key="a._id"
              class="answer-item"
            >
              <span class="answer-index">{{ c.answers.length - index }}</span>

              <!--START: Details-->
              <div class="answer-details">
                <span class="agent-name">
                  {{ a.agent.name }}
                </span>
                <div class="answer-type">{{ getAnswerType(a.answerType) }}</div>
                <span class="timestamp">
                  {{ moment(a.createdAt).format("Do MMMM, hh:MM A") }}
                </span>
              </div>
              <!--END: Details-->

              <!--START: Answer-->
              <div class="answer-block">
                <div
                  class="answer card"
                  :class="{
                    'added-to-editor': a.answerInserted || a.answerCopied,
                  }"
                  v-html="a.answer"
                ></div>
                <span
                  v-if="a.answerInserted || a.answerCopied"
                  class="editor-added"
                  >{{ getAnswerUsedStatus(a) }}</span
                >
                <div v-if="a.generationFailed" class="failed-generation">
                  <svg
                    :data-src="require('@/assets/images/doodles/error.svg')"
                    height="auto"
                    width="45px"
                    class="error-icon"
                  ></svg>
                  <span> Failed to generate answer </span>
                </div>

                <!--START: Prompt options-->
                <div
                  v-if="a.answerType == 'agent-answer'"
                  class="instruction-wrapper"
                  :class="{ selected: selectedInstructions.includes(index) }"
                  @click="toggleView(index)"
                >
                  <div class="btn-wrapper">
                    <span>View Instructions</span>
                    <unicon name="angle-down"></unicon>
                  </div>
                  <div class="instruction-block">
                    <div class="left-col">
                      <div class="instruction-item">
                        <label>Instructions</label>
                        <p v-html="a.answerOptions.instruction"></p>
                      </div>
                      <div class="instruction-options">
                        <div class="instruction-item">
                          <label>Persona</label>
                          <p>
                            {{
                              a.answerOptions.personaId
                                ? getPersona(a.answerOptions.personaId)
                                : "No persona"
                            }}
                          </p>
                        </div>
                        <div class="instruction-item">
                          <label>Language</label>
                          <p v-html="a.answerOptions.language"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="a.answerType == 'refine-answer'"
                  class="instruction-wrapper"
                  :class="{ selected: selectedInstructions.includes(index) }"
                  @click="toggleView(index)"
                >
                  <div class="btn-wrapper">
                    <span>View Instructions</span>
                    <unicon name="angle-down"></unicon>
                  </div>
                  <div class="instruction-block">
                    <div class="left-col">
                      <div class="instruction-item">
                        <label>Refinement Type</label>
                        <p>
                          Make response more {{ a.answerOptions.refineOption }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!--END: Prompt options-->

                <!--START: Reference ticket-->

                <div
                  v-if="!lodash.isEmpty(a.referenceTicket)"
                  class="reference-ticket"
                >
                  <label>Reference Ticket</label>
                  <a
                    :href="`https://${zendeskDomain}.zendesk.com/agent/tickets/${a.referenceTicket?.ticketId}`"
                    target="_blank"
                  >
                    <unicon name="link"></unicon
                    ><em>{{ a.referenceTicket?.ticketId }}</em>
                    <span>{{ a.referenceTicket?.subject }}</span>
                  </a>
                </div>
                <!--END: Reference ticket-->
              </div>
              <!--END: Answer-->
            </div>
            <!--END: Answers-->

            <!--START: Zero State -->
            <ZeroState
              v-if="c.answers == 0"
              class="zero-state"
              :imageURL="noAnswers.imageURL"
              :title="noAnswers.title"
              :message="noAnswers.message"
            ></ZeroState>
            <!--END: Zero State -->
          </div>

          <div class="right-col">
            <!--START: Analysis-->
            <div v-if="c.analysis" class="analysis-wrapper">
              <div class="analysis-item">
                <label>Summary</label>
                <div class="summary" v-html="c.analysis.summary"></div>
              </div>

              <div class="analysis-item analysis-row">
                <label>Sentiment</label>
                <span :class="`sentiment ${c.analysis.sentiment}`">{{
                  c.analysis.sentiment
                }}</span>
              </div>

              <div class="analysis-item analysis-row">
                <label>Category</label>
                <span class="category">{{ c.analysis.category }}</span>
              </div>

              <div class="analysis-item issues">
                <label>Issues</label>
                <span
                  v-for="issue in c.analysis?.issues?.split('**')"
                  :key="issue"
                  >{{ issue }}
                </span>
              </div>

              <div class="analysis-item actions">
                <label>Actions Requested</label>
                <span
                  v-for="issue in c.analysis?.actionsRequested?.split('**')"
                  :key="issue"
                  >{{ issue }}
                </span>
              </div>
            </div>
            <ZeroState
              v-else
              class="zero-state"
              :imageURL="noAnalysis.imageURL"
              :title="noAnalysis.title"
              :message="noAnalysis.message"
            ></ZeroState>
            <!--END: Analysis-->
          </div>
        </div>
      </div>
      <!--END: Answers-->
    </div>
    <!--END: Ticket Body-->
  </div>
  <!--END: Ticket-->
</template>

<script>
// Importing Services
import _ from "lodash";
import "external-svg-loader";
import { getLastMessageTime } from "@/utils/ticket.utils";

// Importing Services
import { AiAssistantServices } from "@/services";
const { PersonaService } = AiAssistantServices;

// Import Components
import ZeroState from "@/components/misc/ZeroState";

export default {
  name: "SelectedTicket",
  data() {
    return {
      lodash: _,
      showOptions: {
        headerShadow: false,
      },
      selectedInstructions: [],
      personas: [],
      noAnswers: {
        imageURL: "services.jpg",
        title: "No Answers",
        message: "No answers have been generated",
      },
      noAnalysis: {
        imageURL: "services.jpg",
        title: "No AI Analysis",
        message: "The AI hasn't analyzed the ticket",
      },
    };
  },
  props: {
    ticket: { type: Object, required: true },
    zendeskTicket: { type: Object, required: true },
  },
  watch: {},
  components: {
    ZeroState,
  },
  computed: {
    ticketComments() {
      const comments = _.cloneDeep(this.ticket.comments);
      return comments.reverse();
    },

    answerCount() {
      let count = 0;
      this.ticket.comments.forEach((c) => {
        count += c.answers.length;
      });

      return count;
    },

    company() {
      return this.$store.getters.company;
    },

    zendeskDomain() {
      return this.company.integrations?.zendesk?.credentials?.domainName;
    },
  },
  async mounted() {},

  methods: {
    // Get the last message time
    getLastMessageTime,

    // Get the customer name or email
    getCustomerName(ticket) {
      let name = "Customer";
      let email = null;
      if (ticket.customer && ticket.customer.name) {
        name = ticket.customer.name;
        email = ticket.customer.email;
      } else if (ticket.customer && ticket.customer.email)
        name = ticket.customer.email;

      return { name, email };
    },

    // Get all the personas
    async getPersonas() {
      this.personas.splice(0);
      const response = await PersonaService.GetAllPersonas();
      this.personas = response.data;
    },

    // Get persona
    getPersona(personaId) {
      const selectedPersona = this.personas.find((p) => p._id === personaId);
      return selectedPersona?.name;
    },

    // Get the answer type
    getAnswerType(answerType) {
      if (answerType === "ai-answer") return "AI answer";
      else if (answerType === "ticket-answer") return "Answer from ticket";
      else if (answerType === "refine-answer") return "Answer Refined";

      return "Answer with instruction";
    },

    // Toggle the views for the isntructions
    toggleView(index) {
      const selectedIndex = this.selectedInstructions.indexOf(index);
      if (selectedIndex == -1) this.selectedInstructions.push(index);
      else this.selectedInstructions.splice(index, 1);
    },

    // Get the last Zendesk comment used to run the task
    getLastZendeskComment(lastCommentId) {
      if (lastCommentId && !_.isEmpty(this.zendeskTicket)) {
        const lastComment = this.zendeskTicket.comments.find(
          (c) => c.id.toString() === lastCommentId
        );
        return lastComment;
      }

      return {};
    },

    // Answer used status
    getAnswerUsedStatus(answer) {
      let status = "Answer ";
      if (answer.answerInserted && answer.answerCopied)
        status += "inserted & copied";
      else if (answer.answerInserted) status += "inserted";
      else if (answer.answerCopied) status += "copied";

      return status;
    },

    // Event on ticket scroll
    ticketScroll() {
      const scrollTop = this.$refs.ticketWindow.scrollTop;

      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },
  },
};
</script>
    
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.ticket-wrapper {
  width: 100%;

  .ticket-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: #fbfbfd;
  }
}

.ticket-header {
  padding: 1rem 2rem;
  border-bottom: 1px solid #edeefa;

  .subject-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 1.5rem;

    .subject {
      flex: 1;
      font-size: $mediumFontSize;
      margin-bottom: 0;

      &.no-subject {
        opacity: 0.5;
      }
    }

    .answer-count {
      display: block;
      border-radius: 0.25rem;
      color: $whiteColor;
      background-color: $purpleColor;
      font-size: 12px;
      line-height: 1;
      text-align: center;
      padding: 0.25rem 0.5rem;
    }
  }

  .details-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 1.5rem;
    margin-top: 0.25rem;

    .ticket-information {
      flex: 1;
    }

    .ticket-link {
      color: $greyColor;
      font-size: $smallerFontSize;

      .unicon /deep/ svg {
        fill: $greyColor;
        width: 12px;
        height: auto;
        margin: 0 5px 0 0;
        transform: translateY(2px);
      }
    }

    .customer-name {
      font-size: $smallerFontSize;
      color: $greyColor;

      b {
        font-weight: $mediumFontWeight;
      }
    }

    .update-time {
      flex: 1;
      text-align: right;
      font-size: $smallestFontSize;
      color: $greyColor;

      b {
        font-weight: $mediumFontWeight;
      }
    }
  }
}

.comments-list {
  padding: 2rem 3rem 4rem;
  height: calc(100vh - 5rem - 4.15rem - 6rem);
  width: 100%;
  overflow-y: scroll;
}

.comment-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 4rem;

  > .left-col {
    width: 60%;
    margin-right: 3rem;
  }
  > .right-col {
    width: calc(40% - 3rem);
  }
}

.answer-item {
  position: relative;
  margin-left: 1.5rem;
  margin-bottom: 4rem;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: calc(-3rem + 0.25rem + 6px);
    top: 0;
    width: 1px;
    height: calc(100% + 3rem);
    border-left: 1px dashed darken($headerBorderColor, 3.5%);
  }

  &:last-child {
    margin-bottom: 0;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: calc(-3rem + 0.25rem + 6px);
      top: 0;
      width: 1px;
      height: calc(100% + 0rem);
      border-left: 1px dashed darken($headerBorderColor, 3.5%);
    }
  }

  .answer-index {
    position: absolute;
    font-size: 10px;
    width: 10px;
    text-align: center;
    top: 0;
    left: -3rem;
    color: darken($whiteColor, 45%);
    background: darken($whiteColor, 7.5%);
    border: 1px solid darken($whiteColor, 10.5%);
    padding: 0.25rem;
    border-radius: 0.5rem;
  }

  .answer-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .agent-name {
      flex: 1;
      color: $greyColor;
      font-size: $smallerFontSize;
      font-weight: $mediumFontWeight;
      margin-right: 0.5rem;
    }

    .answer-type {
      font-size: 0.65rem;
      font-weight: $mediumFontWeight;
      background-color: $greenMessageColor;
      color: $greenMessageTextColor;
      border: 1px solid $greenMessageBorderColor;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
    }

    .timestamp {
      color: $greyColor;
      font-size: $smallestFontSize;
      opacity: $lightOpacity;
    }
  }

  .answer-block {
    position: relative;
    margin-top: 1rem;

    .editor-added {
      background-color: $purpleColor;
      position: absolute;
      color: $whiteColor;
      font-size: $smallestFontSize;
      padding: 0.25rem 1rem;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      top: 0%;
      right: 1rem;
    }

    &:hover {
      .answer {
        color: $blackColor;
      }
    }

    .answer {
      position: relative;
      color: $greyColor;
      font-size: $smallerFontSize;
      line-height: 1.4;

      &.added-to-editor {
        border: 2px solid $purpleColor;
      }

      /deep/ {
        * {
          font-size: $smallerFontSize;
        }

        ol,
        ul {
          list-style: inherit;
        }
      }
    }
  }
}

.instruction-wrapper {
  margin-top: 1.5rem;
  background: darken($whiteColor, 5%);
  border: 1px solid darken($whiteColor, 7.5%);
  border-radius: 0.75rem;
  padding: 0.35rem 0.5rem;
  transition: all 0.3s;
  cursor: pointer;

  .btn-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span {
      font-size: $smallestFontSize;
      color: lighten($darkBlackColor, 30%);
    }

    .unicon /deep/ svg {
      height: auto;
      width: 1.25rem;
      fill: lighten($darkBlackColor, 30%);
      transform: translateY(2px);
      margin-left: 0.5rem;
      opacity: $lightOpacity;
    }
  }

  .instruction-block {
    display: none;
    padding: 1rem;

    .instruction-item {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }

      label {
        display: block;
        color: darken($whiteColor, 10%);
        font-size: $smallestFontSize;
        font-weight: 500;
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid lighten($darkBlackColor, 15%);
      }
      p,
      span {
        color: lighten($darkBlackColor, 40%);
        font-size: $smallestFontSize;
        white-space: pre-wrap;
      }

      p {
        color: darken($whiteColor, 20%);
        margin-left: 0.5rem;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .kb-instruction {
        position: relative;
        margin-left: 1rem;
        margin-bottom: 0.5rem;

        &::before {
          content: "";
          position: absolute;
          display: block;
          height: 5px;
          width: 5px;
          border-radius: 50%;
          background-color: #22ce61;
          top: 5px;
          left: -0.75rem;
        }
      }
    }

    .instruction-options {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 2.5rem;

      .instruction-item {
        flex: 1;
        margin-bottom: 0;
      }
    }
  }

  &:hover,
  &.selected {
    background: lighten($darkBlackColor, 5%);
    border: 1px solid #595959;

    .btn-wrapper {
      span {
        color: darken($whiteColor, 30%);
      }

      .unicon /deep/ svg {
        fill: darken($whiteColor, 30%);
      }
    }
  }

  &.selected {
    .btn-wrapper {
      display: none;
    }

    .instruction-block {
      display: block;
    }
  }
}

.reference-ticket {
  margin-top: 2.5rem;
  background: darken($whiteColor, 5%);
  border: 1px solid darken($whiteColor, 7.5%);
  border-radius: 0.75rem;
  padding: 0.35rem 1rem;

  label {
    font-size: $smallestFontSize;
    color: lighten($darkBlackColor, 30%);
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $smallerFontSize;
    font-weight: $mediumFontWeight;
    color: $blackColor;
    margin-top: 0.25rem;

    :deep(svg) {
      height: auto;
      width: 0.75rem;
      opacity: 0.5;
    }

    em {
      margin: 0 0.5rem;
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &:hover {
      color: $purpleColor;
    }
  }
}

.analysis-wrapper {
  .analysis-item {
    margin-bottom: 1rem;

    &.analysis-row {
      display: flex;
      flex-direction: row;
      align-items: center;

      label {
        margin-bottom: 0;
        width: 40%;
      }
    }
  }

  label {
    display: block;
    font-size: $smallerFontSize;
    color: $greyColor;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .summary {
    color: $greyColor;
    font-size: $smallestFontSize;
    line-height: 1.6;
    margin-bottom: 2rem;

    /deep/ span {
      background: #ffdc6f;
      padding: 0 3px;
      border-radius: 5px;
      color: #1d1600;
    }
  }

  .actions,
  .issues,
  .category {
    color: $greyColor;
    font-size: $smallerFontSize;
  }

  .category {
    text-transform: capitalize;
  }

  .actions,
  .issues {
    margin-top: 2rem;

    span {
      position: relative;
      display: block;
      margin-left: 1.5rem;

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: -1rem;
        top: 7px;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background: $purpleColor;
      }
    }
  }

  .sentiment {
    font-size: $smallestFontSize;
    padding: 3px 8px;
    border-radius: 0.5rem;
    font-weight: 500;
    text-transform: capitalize;

    &.negative,
    &.sad,
    &.angry,
    &.annoyed,
    &.disappointed,
    &.urgent {
      color: #cb2828;
      background-color: #ffdede;
      border: 1px solid #ff9393;
    }

    &.neutral,
    &.non-urgent {
      color: #8f6901;
      background-color: #fff8e4;
      border: 1px solid #ffd869;
    }

    &.happy,
    &.excited,
    &.positive {
      color: #37b51c;
      background-color: #eeffeb;
      border: 1px solid #a5d09c;
    }
  }
}

.zero-state {
  margin-top: 3rem;

  /deep/ {
    h3 {
      font-size: $normalFontSize;
    }
    p {
      font-size: $smallerFontSize;
    }
  }
}

.failed-generation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: -0.5rem 0;

  span {
    margin-left: 0.5rem;
    font-size: $smallFontSize;
    color: $greyColor;
  }
}

.show-shadow {
  position: relative;
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
  z-index: 1;
}

.ticket-comments-title {
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    font-size: $normalFontSize;
    font-weight: $mediumFontWeight;
    margin-bottom: 0;
    flex: 1;
  }
  span {
    font-size: $smallestFontSize;
    color: $greyColor;
  }
}

.comment {
  font-size: $smallFontSize;
  color: #50506e;
  line-height: 1.4;
  padding: 1.5rem;
  border: 1px solid $purpleColor;
  background: #f6f6ff;
  border-radius: 1rem;
  margin: 0.5rem 0 2rem;

  /deep/ {
    * {
      font-size: 0.85rem !important;
    }
    img {
      max-width: 60%;
    }
  }
}

.ticket-channel {
  font-size: $smallestFontSize;
  margin-left: 0.5rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  background-color: darken($whiteColor, 15%);
  color: darken($whiteColor, 65%);
}

.ticket-id {
  margin-left: 1rem;
  font-size: $smallestFontSize;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  background-color: $darkBlackColor;
  color: $whiteColor;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: $blueColor;
  }

  /deep/ .unicon svg {
    height: auto;
    width: 0.65rem;
    fill: $whiteColor;
    margin-right: 0.15rem;
    margin-left: -0.15rem;
  }
}
</style>