<template>
  <div
    v-if="chargebeeSubscription.subscription_items?.length > 0"
    class="plan-wrapper"
  >
    <!--START: Current Plan-->
    <div class="card-wrapper">
      <div class="info-wrapper">
        <h3 class="section-title">Current Plan</h3>
        <p>Here are the details and usage of your trial</p>
      </div>

      <!--START: Active plan-->
      <div class="active-plan">
        <div class="info-wrapper">
          <h3 class="section-title">
            Business Plan<span :class="subscription.status">{{
              subscription.status === "active" ? "Active" : "Cancelled"
            }}</span>
          </h3>
          <p>Unlimited answers for all your agents on Zendesk</p>
          <button class="btn btn-small btn-border" @click="manageSubscription">
            <span>Billing & Management</span>
          </button>
        </div>
        <div class="plan-pricing">
          <div class="plan-price">
            <span class="plan-currency">{{ planCurrency }}</span>
            <span class="plan-amount">{{ planTotalAmount }}</span>
            <span class="plan-duration">{{ planDuration }}</span>
          </div>
          <div class="term-details">
            <span>{{
              moment
                .unix(chargebeeSubscription.current_term_start)
                .format("D MMMM YYYY")
            }}</span>
            to
            <span>{{
              moment
                .unix(chargebeeSubscription.current_term_end)
                .format("D MMMM YYYY")
            }}</span>
          </div>
          <div class="plan-unit-pricing">
            {{ planCurrency }}
            {{ planUnitPrice }}
            {{ planUnit }}
            {{ planDuration }}
            for
            <em>{{ planUnitQuantity }} agents</em>
          </div>
        </div>
      </div>
      <!--END: Active plan-->
    </div>
    <!--END: Current Plan-->

    <div class="agents-wrapper">
      <div class="info-wrapper">
        <h3 class="section-title">
          Billable Agents<em class="agent-count"
            >({{ billableAgents.length }})</em
          >
        </h3>
        <p>
          Here's a list of the billable agents for the current billing cycle
        </p>
      </div>
      <ul class="grid-table agents-list">
        <li class="grid-row grid-header">
          <span class="grid-column">Index</span>
          <span class="grid-column">Name</span>
          <span class="grid-column">Email</span>
          <span class="grid-column">Role</span>
        </li>
        <li
          v-for="(agent, index) in billableAgents"
          :key="agent.id"
          class="agent-item grid-row"
        >
          <span class="grid-column index">{{ index + 1 }}</span>
          <span class="grid-column name">{{ agent.name }}</span>
          <span class="grid-column email">{{ agent.email }}</span>
          <span class="grid-column">
            <span class="role" :class="agent.role">{{ agent.role }}</span>
          </span>
        </li>
      </ul>
    </div>
  </div>

  <!--START: Loader-->
  <PageLoader v-else :showLoader="true"></PageLoader>
  <!--END: Loader-->
</template>
      
<script>
// Import libraries
import _ from "lodash";

// Importing components
import PageLoader from "@/components/loaders/PageLoader";

export default {
  name: "TrialPlan",
  props: {
    subscription: Object,
    chargebeeSubscription: Object,
  },
  components: {
    PageLoader,
  },
  data() {
    return {
      agents: [],
    };
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },

    // Get the plan currency
    planCurrency() {
      return this.getCurrency(this.chargebeeSubscription.currency_code);
    },

    // Total billing amount
    planTotalAmount() {
      const subscription = this.chargebeeSubscription.subscription_items[0];
      return subscription.amount / 100;
    },

    // Get the plan frequency
    planDuration() {
      const frequency = this.chargebeeSubscription.billing_period_unit;
      return `/ ${frequency}`;
    },

    // Get the plan unit price
    planUnitPrice() {
      const subscription = this.chargebeeSubscription.subscription_items[0];
      return subscription.unit_price / 100;
    },

    // Get the plan unit price
    planUnit() {
      return "/ agent";
    },

    // Get the plan unit quantity
    planUnitQuantity() {
      const subscription = this.chargebeeSubscription.subscription_items[0];
      return subscription.quantity;
    },

    // Show the billable agents
    billableAgents() {
      const startDate = this.chargebeeSubscription.current_term_start;
      const endDate = this.chargebeeSubscription.current_term_end;

      const billableMonth =
        this.company.products.aiAssistant.subscription.billableAgents.find(
          (billingCycle) => {
            const billingStartDate = this.moment(billingCycle.startDate).unix();
            const billingEndDate = this.moment(billingCycle.endDate).unix();

            return startDate === billingStartDate && endDate === billingEndDate;
          }
        );

      let agents = [];
      if (billableMonth) {
        agents = _.orderBy(billableMonth?.agents, ["name"], ["asc"]);
      }

      return agents;
    },
  },
  created() {},
  methods: {
    // Manage subscription
    manageSubscription() {
      this.$emit("manageSubscription");
    },
  },
};
</script>
      
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.card-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .info-wrapper {
    flex: 1;
  }
}

.info-wrapper {
  margin-bottom: 1rem;

  .section-title {
    font-family: $titleFont;
    font-size: $largerFontSize;
    font-weight: $boldFontWeight;

    span {
      display: inline-block;
      font-size: $smallestFontSize;
      background-color: $emeraldColor;
      color: lighten($emeraldColor, 50%);
      border: 1px solid lighten($emeraldColor, 20%);
      border-radius: 1.5rem;
      font-weight: $normalFontWeight;
      padding: 0.05rem 0.5rem;
      margin-left: 0.5rem;
      transform: translateY(-3px);

      &.cancelled {
        background-color: $redColor;
        border: 1px solid lighten($redColor, 20%);
        color: $whiteColor;
      }
    }
  }

  p {
    font-size: $smallFontSize;
    opacity: $lightOpacity;
  }

  .btn {
    margin-top: 1rem;
  }
}

.active-plan {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: 1px solid $inputBorderColor;
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  width: 35rem;

  .info-wrapper {
    margin-bottom: 0;
  }

  .details-title {
    display: block;
    color: $greyColor;
    font-size: $smallFontSize;
    margin-bottom: 0.5rem;
  }

  .plan-details {
    .plan-name {
      font-size: 2.75rem;
      color: $purpleColor;
      font-weight: 600;
    }
  }

  .term-details {
    color: $paragraphColor;
    font-size: $smallerFontSize;

    span {
      font-size: $smallFontSize;
      font-weight: 600;
    }
  }
}

.plan-pricing {
  text-align: right;
  margin-left: 3rem;
  margin-top: -0.5rem;
}

.plan-currency {
  font-weight: 600;
  color: $purpleColor;
  font-size: $largerFontSize;
}

.plan-amount {
  font-weight: 600;
  color: $purpleColor;
  font-size: $extraLargeFontSize;
}

.plan-duration {
  font-weight: $mediumFontWeight;
  color: $darkBlackColor;
  margin-left: 0.25rem;
}

.plan-unit-pricing {
  font-size: $smallFontSize;
  font-weight: $mediumFontWeight;
  color: lighten($darkBlackColor, 35%);
  margin-top: 1rem;

  em {
    color: $darkBlackColor;
    font-weight: $boldFontWeight;
  }
}

.grid-table {
  .grid-column:nth-child(1) {
    width: 5rem;
  }

  .grid-column:nth-child(2) {
    width: calc(40% - 5rem);
  }

  .grid-column:nth-child(3) {
    width: 30%;
  }

  .grid-column:nth-child(4) {
    width: 30%;
    text-align: right;
  }
}

.agent-item {
  span {
    font-size: $smallFontSize;
  }

  .index,
  .email {
    color: $greyColor;
  }

  .role {
    padding: 0.25rem 0.75rem;
    border-radius: 0.5rem;
    font-size: $smallestFontSize;
    text-transform: capitalize;
    font-weight: $mediumFontWeight;
    background-color: lighten($emeraldColor, 40%);
    border: 1px solid lighten($emeraldColor, 20%);
    color: darken($emeraldColor, 25%);

    &.admin {
      background-color: lighten($yellowColor, 30%);
      border: 1px solid lighten($yellowColor, 10%);
      color: darken($yellowColor, 35%);
    }
  }
}

.agent-count {
  font-size: $mediumFontSize;
  color: $purpleColor;
  margin-left: 0.5rem;
}
</style>